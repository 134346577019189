import LinkButton, { LinkButtonProps } from '@app/src/components/LinkButton'
import { insertObjIf } from '@app/src/utils/helpersTs'
import { Box, Tooltip } from '@mui/material'
import React, { ReactNode } from 'react'
import LoadingButton, { LoadingButtonProps } from './LoadingButton'

export type ActionButton = {
  label: ReactNode
  tooltip?: string
} & (LoadingButtonProps | LinkButtonProps)

export interface ActionButtonsProps {
  classes?: string
  buttons: Array<ActionButton>
  fullWidthButtons?: boolean
  variants?: LoadingButtonProps['variant']
  sizes?: LoadingButtonProps['size']
}

const isLinkButton = (button: LoadingButtonProps | LinkButtonProps): button is LinkButtonProps => {
  return Boolean((button as LinkButtonProps).to)
}

const ActionButtons: React.FC<ActionButtonsProps> = props => {
  return (
    <>
      {props.buttons?.map(button => {
        const key = button.variant + (typeof button.label === 'string' ? button.label : '')
        if (isLinkButton(button)) {
          return (
            <Tooltip title={button.tooltip} key={key}>
              <Box sx={insertObjIf(Boolean(props.fullWidthButtons), { width: '100%' })}>
                <LinkButton
                  fullWidth={props.fullWidthButtons}
                  size={props.sizes}
                  variant={props.variants}
                  {...button}
                  className={props.classes}
                >
                  {button.label}
                </LinkButton>
              </Box>
            </Tooltip>
          )
        }

        return (
          <Tooltip title={button.tooltip} key={key}>
            <Box sx={insertObjIf(Boolean(props.fullWidthButtons), { width: '100%' })}>
              <LoadingButton
                fullWidth={props.fullWidthButtons}
                size={props.sizes}
                variant={props.variants}
                {...button}
                className={props.classes}
              >
                {button.label}
              </LoadingButton>
            </Box>
          </Tooltip>
        )
      })}
    </>
  )
}

export default ActionButtons
