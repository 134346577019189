import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import LinkButton from '@app/src/components/LinkButton'
import { BaseAssessmentViews } from '@app/src/pages/ResourceCollection/Collections/BaseAssessment/BaseAssessmentScene'
import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import paths from '@app/src/wf-constants/paths'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Paper, Skeleton, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AssessmentExploreViews } from './AssessmentExplore/AssessmentsExploreScene'
import AssessmentOverviewGraph from './AssessmentOverviewGraph'

type AssessmentOverviewGraphWithHeaderProps = {
  assessmentTemplate: AssessmentTemplate
  userFilters?: FilterGroup[]
}

const AssessmentOverviewGraphWithHeader: React.FC<AssessmentOverviewGraphWithHeaderProps> = ({
  assessmentTemplate,
  userFilters,
}) => {
  const { formatMessage } = useIntl()

  const {
    facets: [periodFacets = []],
    isFetched: isPeriodFacetsFetched,
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'periods'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      ...(userFilters ?? []),
      { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
    ],
    options: { enabled: Boolean(userFilters?.length), staleTime: 60000 },
  })

  const {
    facets: [providerFacets],
    isFetched: isProviderFacetsFetched,
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'providers'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'providerId', isEnum: true }],
    filter: [
      ...(userFilters ?? []),
      { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
    ],
    options: { enabled: Boolean(userFilters?.length), staleTime: 60000 },
  })

  const latestPeriod = useMemo(() => {
    return periodFacets.map(facet => facet.label)?.sort((a, b) => comparePeriods(b, a))[0]
  }, [periodFacets])

  const providersCount = providerFacets?.length

  if (!isPeriodFacetsFetched || !isProviderFacetsFetched) {
    return (
      <Stack bgcolor="grey.200" borderRadius={1} p={4} gap={4} minHeight={700} height="100%" flexGrow={1}>
        <Typography variant="h2">{assessmentTemplate.name}</Typography>

        <Stack flexGrow={1} alignItems="flex-end" justifyContent="stretch" width="100%">
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack bgcolor={!periodFacets.length ? 'grey.200' : 'common.white'} borderRadius={1} p={4} gap={3} height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h2" pr={3}>
          {assessmentTemplate.name}
        </Typography>

        {Boolean(providersCount) && (
          <LinkButton
            variant="text"
            endIcon={<OpenInNewIcon />}
            to={
              assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment
                ? generatePath(paths.baseAssessment, { view: BaseAssessmentViews.Overview })
                : generatePath(paths.exploreAssessment, {
                    view: AssessmentExploreViews.Overview,
                    assessmentTemplateId: assessmentTemplate.id,
                    activePeriod: latestPeriod.replace(' ', '-'),
                  })
            }
            sx={{ flexShrink: 0 }}
          >
            {formatMessage({ id: 'assessments.overviewGraph.exploreResult' })}
          </LinkButton>
        )}
      </Stack>

      {Boolean(providersCount) && (
        <Stack>
          <Typography>{formatMessage({ id: 'assessments.suppliersAssessed' })}</Typography>
          <Typography variant="subtitle1">{providersCount}</Typography>
        </Stack>
      )}

      <Paper
        elevation={0}
        variant={providersCount ? 'outlined' : undefined}
        sx={({ palette }) =>
          providersCount ? { padding: 2, outline: `1px solid ${palette.grey[300]}`, borderRadius: 2 } : {}
        }
      >
        <AssessmentOverviewGraph assessmentTemplate={assessmentTemplate} userFilters={userFilters} />
      </Paper>
    </Stack>
  )
}

export default AssessmentOverviewGraphWithHeader
