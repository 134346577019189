import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import ShareWithWfNetworkListItem from '@app/src/pages/Questionnaire/Provider/Actions/ShareWithNetworkListItem'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { SimpleOrganization } from '@app/src/types/organizations'
import { ReportAccessModel, Request } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { Alert, alpha, Box, ButtonBase, Checkbox, Chip, FormControlLabel, Link, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerViewShareWithAllInfo from './DrawerViewShareWithAllInfo'
import DrawerViewSubmitQuestionnaire, { DrawerViewSubmitQuestionnaireProps } from './DrawerViewSubmitQuestionnaire'

type DrawerViewSubmitShareQuestionnaireProps = {
  request?: Request
  currentAccess?: ReportAccessModel
} & Pick<DrawerViewSubmitQuestionnaireProps, 'onSubmit'> &
  Omit<DrawerViewProps, 'title' | 'subtitle'>

const DrawerViewSubmitShareQuestionnaire: React.FC<DrawerViewSubmitShareQuestionnaireProps> = ({
  request,
  currentAccess,
  onSubmit,
  ...props
}) => {
  const { openDrawer } = useDrawer()
  const { formatMessage } = useIntl()
  const [accessorOrganizationIds, setAccessorOrganizationIds] = useState<number[]>([])
  const [isAllAccess, setIsAllAccess] = useState<boolean>(Boolean(currentAccess?.isAllAccess))
  const { providerInquiries } = useReport()

  const { data: organizations, isFetching: isFetchingAccessors } = useFetchResource<SimpleOrganization[]>({
    key: [FetchKey.AccessorOrganization],
    endpoint: endpoints.accessors,
  })

  const isWorldfavorStandard = request?.creatorOrganizationId === WF_ORGANIZATION_ID
  const organizationIdsWithAccess = currentAccess?.organizationIds ?? []
  const requestingOrganizationIds = providerInquiries.map(i => i.creatorOrganizationId)

  const organizationsToDisplay = useMemo(
    () =>
      organizations
        ?.filter(organization =>
          isWorldfavorStandard
            ? true
            : organizationIdsWithAccess.includes(organization.id) ||
              requestingOrganizationIds.includes(organization.id),
        )
        ?.map(o => ({
          ...o,
          isRequested: isWorldfavorStandard
            ? requestingOrganizationIds.includes(o.id)
            : organizationIdsWithAccess.includes(o.id) || requestingOrganizationIds.includes(o.id),
          isDisabled: isAllAccess || organizationIdsWithAccess.includes(o.id),
        }))
        .sort((org1, org2) => {
          if (org1.isDisabled && !org2.isDisabled) return 1
          else if (!org1.isDisabled && org2.isDisabled) return -1

          if (org1.isRequested && !org2.isRequested) return -1
          else if (!org1.isRequested && org2.isRequested) return 1

          return 0
        }),
    [organizations, requestingOrganizationIds, isAllAccess, organizationIdsWithAccess],
  )

  const handleSelect = (checkedAccessorId: number) => {
    const newIds = accessorOrganizationIds.includes(checkedAccessorId)
      ? accessorOrganizationIds.filter(id => id !== checkedAccessorId)
      : [...accessorOrganizationIds, checkedAccessorId]
    setAccessorOrganizationIds(newIds)
  }

  useEffect(() => {
    if (isFetchingAccessors) return

    if (isAllAccess) {
      setAccessorOrganizationIds(organizations?.map(o => o.id) ?? [])
    } else {
      setAccessorOrganizationIds(organizationIdsWithAccess)
    }
  }, [isAllAccess, isFetchingAccessors])

  return (
    <DrawerView
      title={formatMessage({ id: 'reporting.submitValidation' })}
      subTitle={request?.title}
      {...props}
      buttons={[
        {
          label: isAllAccess
            ? formatMessage({ id: 'general.continue' })
            : formatMessage({ id: 'reporting.sharing.shareWithCompanies' }, { count: accessorOrganizationIds.length }),
          variant: 'contained',
          onClick: () =>
            openDrawer(
              <DrawerViewSubmitQuestionnaire
                isAllAccess={currentAccess?.isAllAccess}
                onSubmit={onSubmit}
                sharingProps={{
                  requestId: request?.id ?? 0,
                  organizationIdsToShareWith: accessorOrganizationIds,
                  isAllAccess,
                }}
              />,
            ),
        },
      ]}
    >
      <Stack px={2}>
        <Typography paragraph>{formatMessage({ id: 'reporting.sharing.submitSharingInfo' })}</Typography>

        {isWorldfavorStandard && (
          <Box border={`1px solid ${alpha('#000', 0.12)}`} borderRadius={2} px={2}>
            <ShareWithWfNetworkListItem
              isChecked={isAllAccess}
              onClick={() => setIsAllAccess(prev => !prev)}
              secondaryText={
                !isAllAccess && (
                  <Link
                    component={ButtonBase}
                    variant="caption"
                    color="textSecondary"
                    onClick={() => openDrawer(<DrawerViewShareWithAllInfo subTitle={request.title} />)}
                  >
                    {formatMessage({ id: 'general.learnMore' })}
                  </Link>
                )
              }
            />
            {isAllAccess && (
              <FormControlLabel
                control={<Checkbox checked={isAllAccess} onChange={(_, checked): void => setIsAllAccess(checked)} />}
                labelPlacement="end"
                label={formatMessage({ id: 'reporting.sharing.worldfavorNetworkCheckbox' })}
                sx={{ alignItems: 'flex-start' }}
              />
            )}
          </Box>
        )}

        <Box mt={3}>
          <Typography color="textSecondary">{formatMessage({ id: 'reporting.sharing.shareWith' })}</Typography>

          {organizationsToDisplay?.map(organization => (
            <Box key={organization.id}>
              <FormControlLabel
                disabled={organization.isDisabled}
                control={
                  <Checkbox
                    color="primary"
                    checked={accessorOrganizationIds.includes(organization.id)}
                    onChange={() => handleSelect(organization.id)}
                    disabled={organization.isDisabled}
                  />
                }
                label={
                  <Box display="flex">
                    <Typography variant="body1">{organization.name}</Typography>
                    {organization.isRequested && (
                      <Chip
                        disabled={organization.isDisabled}
                        size="small"
                        color="error"
                        label={formatMessage({ id: 'reporting.sharing.sharingRequested' })}
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Box>
                }
              />
            </Box>
          ))}
        </Box>
      </Stack>

      <Alert severity="info" variant="filled" sx={{ mt: 3, borderRadius: 0 }}>
        {formatMessage({ id: 'reporting.sharing.cannotUnshareInfo' })}
      </Alert>
    </DrawerView>
  )
}

export default DrawerViewSubmitShareQuestionnaire
