import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import LevelBar from '@app/src/components/LevelBar'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import Assessment from '@app/src/types/assessment'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType, Response } from '@app/src/types/resourceExplorer'
import { Box, Chip, CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Operators } from '../../ResourceCollection/Filters/useFilters'
import AssessmentSectionRating from './AssessmentSectionRating'
import BaselineAssessmentDetailedReview from './BaselineAssessmentDetailedReview'

interface BaselineAssessmentCompanyPageProps {
  provider: Provider
  assessment: Assessment
}

const allowedFilters = ['assessmentTemplate.id', 'periodName', 'assessmentTemplateId']

const BaselineAssessmentCompanyPage: React.FC<BaselineAssessmentCompanyPageProps> = ({ provider, assessment }) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)

  const periodFilterValue = useMemo(() => {
    return userFilters?.find(group => group.name === 'periodName')?.filters[0]?.value?.toString()
  }, [userFilters])

  const { assessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    includeAssessment: false,
    includeQuestionnaire: false,
    assessmentType: AssessmentType.BaselineAssessment,
  })

  const {
    items: [response],
    isLoading: responseIsLoading,
  } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload: {
      filter: [
        {
          name: 'request.subscriptions.target.id',
          filters: [
            {
              value: provider?.id,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'request.questionnaireTemplateId',
          filters: [
            {
              value: assessmentQuestionnaireLink?.questionnaireTemplateId,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'isLatestSubmitted',
          filters: [
            {
              value: true,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'request.periodName',
          filters: [
            {
              value: periodFilterValue,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: ['items.requestItem.template.RequestCollection', 'items.files'],
    },
    options: {
      enabled: Boolean(assessmentQuestionnaireLink && periodFilterValue),
      staleTime: 60000,
    },
  })

  if (responseIsLoading) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    )
  }

  if (!responseIsLoading && response && assessment) {
    return (
      <>
        <Paper elevation={0} sx={{ px: 3, py: 2, mr: 5 }}>
          <Stack spacing={2}>
            <Box>
              <Box display="flex" alignItems="center" mt={2}>
                <Typography variant="h2" mr={2}>
                  {formatMessage({ id: 'baseAssessment.totalLevel' }, { value: assessment.totalLevel })}
                </Typography>
                <LevelBar value={assessment.totalLevel} size="large" />
              </Box>
              <Typography mr={2} mt={1} variant="subtitle1">
                {formatMessage({ id: `baseAssessment.levelDefinitions.level${assessment.totalLevel}Title` })}
              </Typography>
              <Typography mr={2}>
                {formatMessage({ id: `baseAssessment.levelDefinitions.level${assessment.totalLevel}HelperText` })}
              </Typography>
              <Box mt={1}>
                <Chip
                  size="small"
                  label={formatMessage(
                    { id: `baseAssessment.providerPage.subIndustry` },
                    { subIndustry: assessment.provider.organization?.subIndustryNaceCode?.description ?? '' },
                  )}
                  sx={{ mr: 1, mt: 1 }}
                />
                <Chip
                  size="small"
                  label={formatMessage(
                    { id: 'baseAssessment.providerPage.revenueRange' },
                    {
                      revenue: assessment.provider.organization?.revenueRange
                        ? formatMessage({
                            id: `claim.companyRevenue.${assessment.provider.organization?.revenueRange}`,
                          })
                        : '-',
                    },
                  )}
                  sx={{ mr: 1, mt: 1 }}
                />
                <Chip
                  size="small"
                  label={formatMessage(
                    { id: 'baseAssessment.providerPage.employeeRange' },
                    {
                      revenue: assessment.provider.organization?.companySizeRange
                        ? formatMessage({
                            id: `claim.companySize.${assessment.provider.organization?.companySizeRange}`,
                          })
                        : '-',
                    },
                  )}
                  sx={{ mr: 1, mt: 1 }}
                />
              </Box>
            </Box>
            <Stack direction="row" flexGrow={1} width="100%" maxWidth={700} justifyContent="space-between">
              <AssessmentSectionRating
                sectionName={formatMessage({ id: 'baseAssessment.section1' })}
                level={assessment.section1Level}
              />
              <Divider flexItem orientation="vertical" />
              <AssessmentSectionRating
                sectionName={formatMessage({ id: 'baseAssessment.section2' })}
                level={assessment.section2Level}
              />
              <Divider flexItem orientation="vertical" />
              <AssessmentSectionRating
                sectionName={formatMessage({ id: 'baseAssessment.section3' })}
                level={assessment.section3Level}
              />
              <Divider flexItem orientation="vertical" />
              <AssessmentSectionRating
                sectionName={formatMessage({ id: 'baseAssessment.section4' })}
                level={assessment.section4Level}
              />
              <Divider flexItem orientation="vertical" />
              <AssessmentSectionRating
                sectionName={formatMessage({ id: 'baseAssessment.section5' })}
                level={assessment.section5Level}
              />
            </Stack>
          </Stack>
        </Paper>
        <BaselineAssessmentDetailedReview latestResponse={response} assessment={assessment} />
      </>
    )
  }
}

export default BaselineAssessmentCompanyPage
