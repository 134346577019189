import { palette } from '@app/src/theme/palette'
import shadows from '@mui/material/styles/shadows'

const MuiTooltip = {
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.background.default,
      color: palette.communication.contrastText,
      padding: 16,
      borderRadius: 8,
      '& a': {
        color: palette.communication.contrastText,
        textDecoration: 'underline',
      },
      boxShadow: shadows[10],
    },
    arrow: {
      color: palette.background.default,
    },
  },
}

export default MuiTooltip
