import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import CompaniesStatisticsFilterContextProvider from '@app/src/context/CompaniesStatisticsFilterContextProvider'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import CompaniesByReportingProgress from '../CompaniesByReportingProgress'
import CompaniesByRequestDeadline from '../CompaniesByRequestDeadline'
import CompaniesCard from '../CompaniesCard'
import DiscoverButton from '../DiscoverButton'
import GoalTrackerSection from '../GoalTracker/GoalTrackerSection'
import PendingRequestsCard from '../PendingRequestsCard'
import ResponsesCard from '../ResponsesCard'
import CompanyStatistics from './CompaniesStatistics/CompaniesStatistics'
import CountryRiskSection from './CountryRisks/CountryRiskSection'
import FinalRiskAssessmentCard from './FinalRiskAssessmentCard'

const SourcingDashboard = () => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const hasFullDashboardAccess = hasPermission(Permissions.FULL_DASHBOARD_ACCESS)
  const { trackEvent } = useAmplitude()

  const {
    facets: [providersCount],
    isLoading: providersCountLoading,
  } = useFetchFacets({
    key: FetchKey.ProviderCount,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'id' }],
    filter: [],
  })

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Dashboard.Overview.ViewedPage,
    })
  }, [])

  return (
    <Box mt={2} display="flex" flexDirection="column" flexGrow={1}>
      <Helmet>
        <title>{formatMessage({ id: 'navbar.dashboard' })}</title>
      </Helmet>
      <Box px={4} py={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h1">{formatMessage({ id: 'navbar.dashboard' })}</Typography>
          <DiscoverButton />
        </Box>
      </Box>
      <Box bgcolor="grey.100" px={4} py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={hasFullDashboardAccess ? 3 : 4}>
            <CompaniesCard totalCompanies={providersCount?.length} isLoading={providersCountLoading} />
          </Grid>
          <Grid item xs={12} sm={6} lg={hasFullDashboardAccess ? 3 : 4}>
            <PendingRequestsCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={hasFullDashboardAccess ? 3 : 4}>
            <ResponsesCard />
          </Grid>
          {hasFullDashboardAccess && (
            <Grid item xs={12} sm={6} lg={3}>
              <FinalRiskAssessmentCard totalCompanies={providersCount?.length} isLoading={providersCountLoading} />
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <CompaniesByRequestDeadline />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CompaniesByReportingProgress />
          </Grid>
          {hasFullDashboardAccess && (
            <Grid item xs={12}>
              <GoalTrackerSection />
            </Grid>
          )}
          <Grid item xs={12}>
            <CountryRiskSection />
          </Grid>
          <Grid item xs={12}>
            <CompaniesStatisticsFilterContextProvider>
              <CompanyStatistics />
            </CompaniesStatisticsFilterContextProvider>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SourcingDashboard
