import { useAccount } from '@app/src/context/AccountContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType } from '@app/src/types/resourceExplorer'
import { getCurrentTimestamp } from '@app/src/utils'
import { StyleSheet } from '@react-pdf/renderer'
import * as Sentry from '@sentry/react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useState } from 'react'
import { useIntl } from 'react-intl'

const styles = StyleSheet.create({
  logo: {
    width: 10,
    height: 10,
    marginBottom: 6,
    marginLeft: 3,
    marginTop: 5,
  },
  page: {
    padding: 12.7,
    pageMarginBottom: -14,
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'helvetica',
  },
  subheader: {
    fontSize: 12,
    fontWeight: 'normal',
    fontFamily: 'helvetica',
  },
})

const uiElements = [
  '#exportPdfButton',
  '#learnMoreButton',
  '#downloadFileButton',
  '[name="periodName"]',
  'read-more-link',
]

const logo = '/img/brand/worldfavor_Logo_Black.png'

const prepareDocumentForExport = (clonedDoc: Document, assessmentTemplateType: string | undefined) => {
  // Hide UI elements
  uiElements.forEach(selector => {
    const elements = clonedDoc.querySelectorAll(selector)
    elements.forEach(element => {
      if (element instanceof HTMLElement) {
        element.style.display = 'none'
      }
    })
  })

  // Expand collapsed content
  clonedDoc.querySelectorAll('.collapsed-content').forEach(content => {
    if (content instanceof HTMLElement) {
      content.style.maxHeight = 'none'
      content.style.overflow = 'visible'
    }
  })

  // Handle standard assessment chips
  if (assessmentTemplateType === AssessmentType.StandardAssessment) {
    clonedDoc.querySelectorAll('.MuiChip-root').forEach(chip => ((chip as HTMLElement).style.display = 'inline-block'))
  }
}

// Helper function to convert an image URL to Base64 (if necessary)
const imgToBase64 = (url: string) => {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  })
}

const waitForContentToExpand = () => {
  return new Promise(resolve => {
    setTimeout(resolve, 500)
  })
}

const useBaselineAssessmentToPDF = (
  elementRef: React.RefObject<HTMLDivElement>,
  provider: Provider | undefined,
  assessmentTemplateName: string | undefined,
  assessmentTemplateType: string | undefined,
  setMaxRows: (maxRowsForLongAnswers: number) => void,
) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const [isLoading, setIsLoading] = useState(false)
  const timestamp = getCurrentTimestamp()
  const { showErrorNotification } = useErrorNotification()
  const fileName = `${assessmentTemplateName}_${provider?.name}_${timestamp}.pdf`

  const generatePDF = async () => {
    const element = elementRef.current
    if (!element) {
      Sentry.captureException(new Error('Element ref not found!'), {
        tags: { event: 'Error exporting to PDF' },
        user: { id: String(account?.user?.id) },
        extra: { providerId: String(provider?.id) },
      })
      showErrorNotification({
        requestError: null,
        specificFallbackErrorMessage: formatMessage({ id: 'baseAssessment.exportToPdfError' }),
      })
      return
    }

    try {
      setIsLoading(true)
      setMaxRows(50)
      await waitForContentToExpand()

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        logging: true,
        onclone: clonedDoc => prepareDocumentForExport(clonedDoc, assessmentTemplateType),
      })

      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF('p', 'mm', 'a4')
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()

      const contentTopMargin = styles.logo.height + styles.logo.marginBottom
      const contentWidth = pdfWidth - 2 * styles.page.padding

      const imgProps = pdf.getImageProperties(imgData)
      const imgHeight = (imgProps.height * contentWidth) / imgProps.width

      let heightLeft = imgHeight
      let position = contentTopMargin

      const header = assessmentTemplateName ?? formatMessage({ id: 'baseAssessment.baselineAssessment' })

      const subheader = provider?.name ?? ''

      const headerXPosition = (pdfWidth - pdf.getTextWidth(header)) / 2
      const headerYPosition = styles.logo.marginTop + styles.logo.height / 3
      const subheaderYPosition = headerYPosition + 6

      // Ensure that your logo is converted to Base64 and then used
      const logoBase64 = await imgToBase64(logo)

      pdf.addImage(
        logoBase64,
        'PNG',
        styles.logo.marginLeft + styles.page.padding,
        styles.logo.marginTop,
        styles.logo.width,
        styles.logo.height,
      )

      pdf.setFont(styles.header.fontFamily, styles.header.fontWeight)
      pdf.setFontSize(styles.header.fontSize)
      pdf.text(header, headerXPosition, headerYPosition)

      pdf.setFont(styles.header.fontFamily, styles.subheader.fontWeight)
      pdf.setFontSize(styles.subheader.fontSize)
      pdf.text(subheader, pdfWidth / 2, subheaderYPosition, { align: 'center' })

      pdf.addImage(imgData, 'PNG', styles.page.padding, position, contentWidth, imgHeight)
      heightLeft -= pdfHeight - contentTopMargin - styles.page.pageMarginBottom

      while (heightLeft > 0) {
        pdf.addPage()
        position = heightLeft - imgHeight + contentTopMargin
        pdf.addImage(imgData, 'PNG', styles.page.padding, position, contentWidth, imgHeight)
        heightLeft -= pdfHeight - contentTopMargin - styles.page.pageMarginBottom
      }

      pdf.save(fileName)
    } catch (error) {
      Sentry.captureException(error)
      showErrorNotification({
        requestError: null,
        specificFallbackErrorMessage: formatMessage({ id: 'baseAssessment.exportToPdfError' }),
      })
    } finally {
      setMaxRows(3)
      setIsLoading(false)
    }
  }

  return { generatePDF, isLoading }
}

export default useBaselineAssessmentToPDF
