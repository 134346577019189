import DateCell from '@app/src/components/Table/Cells/DateCell'
import UserCell from '@app/src/components/Table/Cells/UserCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ProviderActivity, ProviderActivityType } from '@app/src/types/organizations'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ProviderRiskAssessmentRowProps {
  row: ProviderActivity
}

export const ProviderRiskAssessmentRow: React.FC<ProviderRiskAssessmentRowProps> = ({ row: activity }) => {
  const { formatMessage } = useIntl()

  const getCategoryKey = (activityType: ProviderActivityType): string => {
    switch (activityType) {
      case ProviderActivityType.CHANGE_APPROVAL_STATUS:
        return 'providerApprovalStatusValues'
      case ProviderActivityType.CHANGE_RISK_ASSESSMENT:
        return 'finalRiskRatingValues'
      default:
        return activityType
    }
  }

  const getActivityText = (): string => {
    if (activity.activityType === ProviderActivityType.CHANGE_BASELINE_ASSESSMENT_LEVEL) {
      return formatMessage(
        {
          id: `schemas.provider.activity.${
            activity.from ? 'changedBaselineAssessmentLevel' : 'initialBaselineAssessmentLevel'
          }`,
        },
        {
          fromLevel: activity.from,
          toLevel: activity.to,
        },
      )
    } else if (activity.activityType === ProviderActivityType.CHANGE_AUTOMATED_ASSESSMENT_PERCENTAGE) {
      const [fromInterval, fromPercentageStr] = activity.from?.split('|') ?? []
      const [toInterval, toPercentageStr] = activity.to?.split('|') ?? []

      return formatMessage(
        {
          id: `schemas.provider.activity.${
            activity.from ? 'changedAutomatedAssessmentPercentage' : 'initialAutomatedAssessmentPercentage'
          }`,
        },
        {
          assessmentName: activity?.comments?.[0]?.text ?? '',
          intervalType: fromInterval,
          percentage: Number(fromPercentageStr) || 0,
          newIntervalType: toInterval,
          newPercentage: Number(toPercentageStr) || 0,
        },
      )
    }

    return formatMessage(
      { id: 'schemas.provider.activity.actionTitleRiskAssessment' },
      {
        action: 'Updated',
        updatedFrom: formatMessage({
          id: `schemas.provider.${getCategoryKey(activity.activityType)}.${activity.from}`,
        }),
        updatedTo: formatMessage({
          id: `schemas.provider.${getCategoryKey(activity.activityType)}.${activity.to}`,
        }),
        actionType: formatMessage({ id: `schemas.provider.providerActivityType.${activity.activityType}` }),
      },
    )
  }

  const getComment = () => {
    if (activity.activityType === ProviderActivityType.CHANGE_BASELINE_ASSESSMENT_LEVEL) {
      return formatMessage({
        id: 'schemas.provider.activity.baselineAssessmentComment',
      })
    } else if (activity.activityType === ProviderActivityType.CHANGE_AUTOMATED_ASSESSMENT_PERCENTAGE) {
      return formatMessage(
        {
          id: 'schemas.provider.activity.automatedAssessmentComment',
        },
        {
          assessmentName: activity?.comments?.[0]?.text ?? '',
        },
      )
    }

    return activity?.comments?.[0]?.text ?? ''
  }
  return (
    <>
      <TableRow>
        <DateCell value={activity.createdAt} />
        <UserCell user={activity.creatorUser} />
        <ValueCell value={getActivityText()} />
        <ValueCell value={getComment()} />
      </TableRow>
    </>
  )
}

export default ProviderRiskAssessmentRow
