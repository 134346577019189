import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import FlagIndicator from '@app/src/components/Flags/FlagIndicator'
import RenderFileDownload from '@app/src/components/RenderFileDownload'
import { ReportFlagType } from '@app/src/types/flags'
import { QuestionType } from '@app/src/types/reporting'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { insertObjIf } from '@app/src/utils/helpersTs'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import { DateRangeOutlined } from '@mui/icons-material'
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import TextTruncate from 'react-text-truncate'
import { useDrawer } from '../../Drawer/DrawerContext'
import DrawerViewAnswerDetails from '../../Drawer/Views/DrawerViewAnswerDetails'
import ParentCellOrFragment from './ParentCellOrFragment'

export type AnswerWithFlagCellProps = {
  item: ResponseItem
  responderName?: string
  maxRows?: number
  disableCell?: boolean
}

const getAnswerBasedOnQuestionType = (questionType: number, item: ResponseItem) => {
  switch (questionType) {
    case QuestionType.Options:
      return item.selectedOptions?.join(', ')
    case QuestionType.Text:
      return item.answer
    case QuestionType.Number:
      return new Intl.NumberFormat().format(Number(item.answer)).replaceAll(',', ' ')
  }
}

export const QuestionItemAnswer: React.FC<AnswerWithFlagCellProps> = ({ item, maxRows, responderName }) => {
  const { formatMessage } = useIntl()
  const { formatValueToDate } = useGetFormattedDate()
  const { palette, typography } = useTheme()
  const { openDrawer } = useDrawer()

  const questionType = item.requestItem.questionTypeId

  if (item.cannotAnswer) {
    return item.estimatedAnswerAt ? (
      <Stack>
        <Typography variant="body1">{formatMessage({ id: 'reporting.planOnHaving' })}</Typography>
        <Box display="flex" alignItems="center" color={({ palette }) => palette.text.secondary}>
          <DateRangeOutlined fontSize="inherit" color="inherit" />
          <Typography variant="caption" ml={0.5} color="inherit">
            {formatValueToDate({ value: item?.estimatedAnswerAt ?? '' })}
          </Typography>
        </Box>
      </Stack>
    ) : (
      <Typography variant="body1" flexGrow={1} component="span">
        {`Skipped - ${formatMessage({ id: `reporting.answerStatus.${item.answerStatus}` })}`}
      </Typography>
    )
  }

  if (questionType === QuestionType.File) {
    return (
      <RenderFileDownload
        fileName={item.uploadReferences?.[0]?.originalFileName}
        id={item.uploadReferences?.[0]?.id ?? 0}
        expiresAt={item.expiresAt}
      />
    )
  }

  const answer = getAnswerBasedOnQuestionType(questionType, item)

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" flexGrow={1} component="span">
        <TextTruncate
          line={maxRows}
          text={answer?.toString() ?? '-'}
          textTruncateChild={
            <span
              role="button"
              onClick={() =>
                openDrawer(
                  <DrawerViewAnswerDetails
                    companyName={responderName ?? item.creatorOrganization?.name ?? '-'}
                    question={item.requestItem.description}
                    answer={answer?.toString() ?? '-'}
                  />,
                  { showAboveDialogs: true },
                )
              }
              style={{
                color: palette.common.black,
                fontWeight: typography.button.fontWeight,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {formatMessage({ id: 'general.readMore' })}
            </span>
          }
        />
      </Typography>
      {item.requestItem?.unit?.symbol && (
        <Typography variant="caption" ml={1}>
          {item.requestItem.unit.symbol}
        </Typography>
      )}
    </Box>
  )
}

const AnswerWithFlagCell: React.FC<AnswerWithFlagCellProps> = ({ item, maxRows = 2, disableCell, responderName }) => {
  const isWorldfavorRule = item.flagRule?.creatorOrganizationId === WF_ORGANIZATION_ID
  const isRedFlag = item?.flag === ReportFlagType.Red

  return (
    <ParentCellOrFragment
      disableCell={disableCell}
      sx={{
        height: 0,
        py: 1,
        px: isRedFlag ? 1 : 0,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={({ palette }) => ({
          height: '100%',
          width: '100%',
          py: 1,
          px: 2,
          borderRadius: 1,
          ...insertObjIf(isRedFlag && isWorldfavorRule, { backgroundColor: palette.communication.light }),
          ...insertObjIf(isRedFlag && !isWorldfavorRule, { backgroundColor: alpha(palette.error.main, 0.12) }),
        })}
      >
        <QuestionItemAnswer item={item} maxRows={maxRows} responderName={responderName} />
        {item.flag === ReportFlagType.Red && item.flagRule && (
          <FlagIndicator flagRules={[item.flagRule]} sx={{ ml: 1 }} />
        )}
      </Box>
    </ParentCellOrFragment>
  )
}

export default AnswerWithFlagCell
