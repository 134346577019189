import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacetsQueries } from '@app/src/api/fetchHooks'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import EmptyState from '@app/src/components/EmptyState'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useAssessmentQuestionnaireInquiriesCount from '@app/src/hooks/useAssessmentQuestionnaireInquiriesCount'
import useAssessmentOverviewChartOptions, {
  AssessmentOverviewGraphDataPoint,
} from '@app/src/pages/Assessments/useAssessmentOverviewChartOptions'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { BarChartOutlined, ChatOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import ChartTypeSelector, { ChartType } from '../ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { FilterGroup, Operators } from '../ResourceCollection/Filters/useFilters'
import AssessmentDialog from './AssessmentDialog'
import AssessmentGraphCard from './AssessmentGraphCard'

type AssessmentOverviewGraphProps = {
  assessmentTemplate: AssessmentTemplate
  userFilters?: FilterGroup[]
}

const AssessmentOverviewGraph: React.FC<AssessmentOverviewGraphProps> = ({ assessmentTemplate, userFilters = [] }) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)

  const { formatMessage } = useIntl()
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Horizontal)
  const [assessmentDialogOpen, openAssessmentDialog, closeAssessmentDialog] = useDialogState()
  const [dialogData, setDialogData] = useState<{
    assessmentLevel: number | undefined
    periodName: string | undefined
  }>()
  const isBaselineAssessment = assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment
  const providerTypeNamePlural = useCurrentProviderType({ isPlural: true })
  const [isCreateRequestDialogOpen, openCreateRequestDialog, closeCreateRequestDialog] = useDialogState()
  const filters: FilterGroup[] = [
    { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
  ]
  const maxLevel = assessmentTemplate.levels ?? 5
  const has5Levels = assessmentTemplate.levels !== 3 // null or 5 shows 5 levels to be backward compatible

  const activePeriods = useMemo(() => {
    const periodNameFilterValue = userFilters.find(x => x.name === 'periodName')?.filters[0]?.value
    const filteredPeriods = periodNameFilterValue instanceof Array ? periodNameFilterValue : [periodNameFilterValue]
    return filteredPeriods as string[]
  }, [userFilters])

  const availableChartTypes = useMemo(() => {
    if (activePeriods.length > 1 && selectedChartType === ChartType.Pie) {
      setSelectedChartType(ChartType.Horizontal)
    }

    return activePeriods.length === 1
      ? [ChartType.Vertical, ChartType.Horizontal, ChartType.Pie]
      : [ChartType.Vertical, ChartType.Horizontal]
  }, [activePeriods])

  const orderedPeriods = useMemo(() => activePeriods?.sort((a, b) => comparePeriods(a, b)), [activePeriods])
  const joinedPeriods = orderedPeriods.join(', ')

  const levelFacetResultsPerPeriod = useFetchFacetsQueries(
    orderedPeriods.filter(Boolean).map(periodName => ({
      key: [FetchKey.AssessmentFacets, 'levels'],
      endpoint: endpoints.assessmentFacet,
      facetsParam: [{ name: 'totalLevel', isEnum: true }],
      filter: [
        ...filters,
        ...userFilters,
        { name: 'periodName', filters: [{ value: periodName, operator: Operators.EqualTo }] },
      ],
      options: {
        enabled: Boolean(orderedPeriods.length),
        staleTime: 60000,
      },
    })),
  )

  const isLevelsFacetFetched =
    levelFacetResultsPerPeriod.length && levelFacetResultsPerPeriod.every(({ isFetched }) => isFetched)

  const { inquiriesCount, isLoadingInquiriesCount, questionnaireTemplates } =
    useAssessmentQuestionnaireInquiriesCount(assessmentTemplate)

  const toLevelChartArray = useCallback(
    (levelFacets: Array<FacetItem>, periodName: string): Array<AssessmentOverviewGraphDataPoint> =>
      Array.from(Array(maxLevel).keys()).map(i => {
        const level = i + 1
        const levelFaceItem = levelFacets?.find(f => f.value === level)
        return {
          value: Number(levelFaceItem?.count ?? 0),
          level: level,
          groupName: periodName,
          providerIds: [],
          periodName: periodName,
        }
      }),
    [maxLevel],
  )

  const levelChartObj = useMemo(() => {
    if (!levelFacetResultsPerPeriod.length) return []
    return orderedPeriods.flatMap((periodName, index) =>
      toLevelChartArray(levelFacetResultsPerPeriod[index].facets[0], periodName),
    )
  }, [assessmentTemplate, orderedPeriods, levelFacetResultsPerPeriod])

  const options = useAssessmentOverviewChartOptions(
    levelChartObj,
    selectedChartType,
    has5Levels,
    isBaselineAssessment,
    orderedPeriods.length === 1 ? undefined : orderedPeriods,
  )

  const events = useMemo(
    () => ({
      click: ({
        data,
        event,
      }: {
        data: AssessmentOverviewGraphDataPoint
        event: { target: { parent: { type: string } } }
      }) => {
        const clickedPeriodLabel = event.target.parent.type === 'text'

        if (!clickedPeriodLabel && !data.value) {
          return
        }

        const noDataInPeriod =
          clickedPeriodLabel && levelChartObj.filter(x => x.periodName === data.periodName).every(x => !x.value)

        if (noDataInPeriod) {
          return
        }

        setDialogData({ assessmentLevel: clickedPeriodLabel ? undefined : data.level, periodName: data.periodName })
        openAssessmentDialog()
      },
    }),
    [],
  )

  const handleCloseDialog = () => {
    closeAssessmentDialog()
    setTimeout(() => {
      setDialogData(undefined)
    }, 250)
  }

  const isLoading = activePeriods?.length ? !isLevelsFacetFetched || isLoadingInquiriesCount : false

  if (isLoading) {
    return (
      <Stack minHeight={433} height="100%" flexGrow={1}>
        <Stack flexGrow={1} alignItems="center" justifyContent="center" width="100%">
          <CircularProgress />
        </Stack>
      </Stack>
    )
  }

  if (levelFacetResultsPerPeriod.every(({ facets }) => !facets[0]?.length)) {
    return (
      <Stack bgcolor="grey.200" borderRadius={1} p={4} gap={4} minHeight={470} height="100%" flexGrow={1}>
        <Stack alignItems="center" justifyContent="center" flexGrow={1} p={3}>
          {inquiriesCount ? (
            <EmptyState
              iconComponent={ChatOutlined}
              title={formatMessage(
                { id: 'assessments.overviewGraph.noResponsesEmptyState.title' },
                { periods: joinedPeriods },
              )}
              description={formatMessage(
                { id: 'assessments.overviewGraph.noResponsesEmptyState.description' },
                { providerTypePlural: providerTypeNamePlural },
              )}
            />
          ) : (
            <>
              <EmptyState
                iconComponent={BarChartOutlined}
                title={formatMessage(
                  { id: 'assessments.overviewGraph.noInquiriesEmptyState.title' },
                  { periods: joinedPeriods },
                )}
                description={formatMessage({ id: 'assessments.overviewGraph.noInquiriesEmptyState.description' })}
              >
                <Button variant="outlined" onClick={openCreateRequestDialog}>
                  {formatMessage({ id: 'assessments.overviewGraph.noInquiriesEmptyState.action' })}
                </Button>
              </EmptyState>
              <CreationModalProgressContextProvider>
                <CreateNewRequestModal
                  open={isCreateRequestDialogOpen}
                  onClose={closeCreateRequestDialog}
                  reportType={ReportType.WF}
                  disableInitialBack
                  selectedQuestionnaireTemplates={questionnaireTemplates.filter(x => x.isStandardReport)}
                />
              </CreationModalProgressContextProvider>
            </>
          )}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <Stack justifyContent="flex-end" flexGrow={1}>
        <AssessmentGraphCard
          onClickTitle={openAssessmentDialog}
          eChartsRef={eChartsRef}
          periods={orderedPeriods}
          assessmentTemplateName={assessmentTemplate.name}
          chartSelector={
            <ChartTypeSelector
              selectedChartType={selectedChartType}
              setSelectedChartType={setSelectedChartType}
              allowedChartTypes={availableChartTypes}
            />
          }
        >
          <Box height={384} display="flex" flexDirection="column">
            <ReactEChartsCore
              onEvents={events}
              option={options}
              echarts={echarts}
              style={{ minWidth: '100%', flexGrow: 1 }}
              ref={eChartsRef}
              notMerge
            />
          </Box>
        </AssessmentGraphCard>
      </Stack>

      <AssessmentDialog
        isOpen={assessmentDialogOpen}
        closeModal={handleCloseDialog}
        assessmentTemplate={assessmentTemplate}
        periods={dialogData?.periodName ? [dialogData.periodName] : orderedPeriods}
        activeLevel={dialogData?.assessmentLevel}
        filters={[...filters, ...userFilters]}
      />
    </Stack>
  )
}

export default AssessmentOverviewGraph
