import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import DateDisplay from '@app/src/components/DateDisplay'
import Assessment from '@app/src/types/assessment'
import { AssessmentTemplateWithClassificationRules, ResponseItem } from '@app/src/types/resourceExplorer'
import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import AssessmentResponseItemRow from '../../ResourceCollection/Collections/Assessments/AssessmentResponseItemRow'

interface AutomatedAssessmentDetailedReviewProps {
  responseItems: ResponseItem[]
  assessment: Assessment
  maxRowsForLongAnswers: number
}

const staleTime = 60000

const AutomatedAssessmentDetailedReview = ({
  responseItems,
  assessment,
  maxRowsForLongAnswers,
}: AutomatedAssessmentDetailedReviewProps) => {
  const { formatMessage } = useIntl()

  const { data: assessmentTemplateWithClassificationRules } =
    useFetchResource<AssessmentTemplateWithClassificationRules>({
      key: [FetchKey.AssessmentTemplateWithClassificationRules, assessment.assessmentTemplate.id],
      endpoint: endpoints.assessmentTemplateWithClassificationRules(assessment.assessmentTemplate.id),
      options: {
        staleTime: staleTime,
      },
    })

  const questions = assessmentTemplateWithClassificationRules?.questions ?? []

  const currentResponseItems = responseItems.filter(responseItem =>
    questions.some(question => responseItem.requestItem.template?.id === question.id),
  )

  const currentAssessmentRules = assessment.assessmentTemplate?.rules?.filter(rule =>
    questions.some(question => rule.questionId === question.id),
  )

  return (
    <Paper elevation={0}>
      <Stack spacing={2} p={2} my={2} bgcolor="grey.50" borderRadius={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{formatMessage({ id: 'baseAssessment.providerPage.answers' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {formatMessage({ id: 'baseAssessment.providerPage.resultsAssessedOn' })}{' '}
            <DateDisplay value={assessment?.updatedAt ?? assessment.createdAt} />
          </Typography>
        </Box>

        <Stack spacing={2}>
          {currentResponseItems.map(responseItem => (
            <AssessmentResponseItemRow
              key={responseItem.id}
              responseItem={responseItem}
              assessmentRules={currentAssessmentRules?.filter(
                rule => rule.questionId === responseItem.requestItem.template?.id,
              )}
              maxRowsForLongAnswers={maxRowsForLongAnswers}
            />
          ))}
        </Stack>
      </Stack>
    </Paper>
  )
}

export default AutomatedAssessmentDetailedReview
