import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import {
  AssessmentRuleType,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
} from '@app/src/types/resourceExplorer'
import { darken, useTheme } from '@mui/material'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { getSxByRank } from '@app/src/pages/QuestionEditor/AnswerClassification/ClassificationRow'
import { RANK_TYPE_ORDER } from '@app/src/components/Table/Cells/AnswerWithClassificationCell'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { useIntl } from 'react-intl'
import { useCallback, useMemo } from 'react'

export interface AssessmentInsightsQuestionChartDataPoint {
  value: number | undefined
  name: string
  classificationRuleName: string
  classificationRule: QuestionAnswerClassificationRule
}

const answerClassificationTranslationKeys = {
  [AssessmentRuleType.AnswerStatus]: (ruleValue: string | undefined) =>
    `templateBuilder.answerClassifications.classificationsValues.${ruleValue}`,
  [AssessmentRuleType.SelectedValue]: 'templateBuilder.answerClassifications.optionValue',
  [AssessmentRuleType.NumberEqualTo]: 'templateBuilder.answerClassifications.numberValue',
  [AssessmentRuleType.NumberGreaterThan]: 'templateBuilder.answerClassifications.numberValue',
  [AssessmentRuleType.NumberLowerThan]: 'templateBuilder.answerClassifications.numberValue',
  [AssessmentRuleType.FileUploaded]: undefined,
  [AssessmentRuleType.CannotAnswer]: undefined,
}

const useAssessmentInsightsQuestionChartOptions = (
  answerClassificationAssessmentFacets: FacetItem[],
  questionAnswerClassificationRules: QuestionAnswerClassificationRule[] | undefined,
  chartType: ChartType,
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>,
) => {
  const { palette, typography } = useTheme()
  const { formatMessage } = useIntl()
  const widthOfChart = useEchartsContainerWidth(echartsRef) ?? 600
  const isHorizontalChart = chartType === ChartType.Horizontal

  const getAnswerClassificationRuleName = useCallback(
    (ruleType: AssessmentRuleType, ruleValue: string | undefined) => {
      const translationKey =
        ruleType === AssessmentRuleType.AnswerStatus
          ? answerClassificationTranslationKeys[ruleType](ruleValue)
          : answerClassificationTranslationKeys[ruleType]

      return translationKey ? formatMessage({ id: translationKey }, { ruleType, value: ruleValue }) : ''
    },
    [formatMessage, answerClassificationTranslationKeys],
  )

  const { classificationRulesWithFacetCount, classificationRuleNames, rankTypeColors, dataSeries } = useMemo(() => {
    const classificationRulesWithFacetCount =
      questionAnswerClassificationRules
        ?.map(rule => ({
          ...rule,
          count: answerClassificationAssessmentFacets.find(x => x.value === rule.id)?.count ?? 0,
        }))
        .sort((a, b) => RANK_TYPE_ORDER.indexOf(a.rank) - RANK_TYPE_ORDER.indexOf(b.rank)) ?? []

    if (classificationRulesWithFacetCount.some(x => x.ruleType !== AssessmentRuleType.AnswerStatus && x.count > 0)) {
      const answeredRuleIndex = classificationRulesWithFacetCount.findIndex(
        x => x.ruleType === AssessmentRuleType.AnswerStatus && x.value === 'Answered' && !x.count,
      )
      if (answeredRuleIndex !== -1) {
        classificationRulesWithFacetCount.splice(answeredRuleIndex, 1)
      }
    }

    const classificationRuleNames =
      classificationRulesWithFacetCount?.map(x => getAnswerClassificationRuleName(x.ruleType, x.value)) ?? []
    const rankTypes = Array.from(new Set(classificationRulesWithFacetCount?.map(x => x.rank))) as QuestionAnswerRank[]
    const rankTypeColors = rankTypes.map(rank => getSxByRank(palette, rank).bgcolor)

    const dataSeries = rankTypes.map(rankType => ({
      name: formatMessage({
        id: `templateBuilder.answerClassifications.classificationsRanks.${rankType}`,
      }),
      data: classificationRulesWithFacetCount?.map(rule => (rule.rank === rankType ? rule.count : undefined)) ?? [],
    }))

    return { classificationRulesWithFacetCount, classificationRuleNames, rankTypeColors, dataSeries }
  }, [questionAnswerClassificationRules, answerClassificationAssessmentFacets])

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: 'Count',
      position: 'bottom',
      nameLocation: 'middle',
      nameTextStyle: {
        padding: 14,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
      minInterval: 1,
      axisLabel: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [typography],
  )

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      show: !isHorizontalChart,
      data: classificationRuleNames,
      inverse: isHorizontalChart,
      axisLabel: {
        interval: 0,
        overflow: 'truncate',
        width: (widthOfChart - 100) / classificationRuleNames.length,
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [isHorizontalChart, classificationRuleNames, widthOfChart, typography],
  )

  return {
    xAxis: isHorizontalChart ? valueAxis : categoryAxis,
    yAxis: isHorizontalChart ? categoryAxis : valueAxis,
    grid: {
      left: isHorizontalChart ? 10 : 50,
      right: 41,
      top: isHorizontalChart ? 20 : 50,
      bottom: isHorizontalChart ? 80 : 75,
    },
    tooltip: {
      trigger: 'item',
      axisPointer: { type: 'none' },
      formatter: (params: { marker: string; data: AssessmentInsightsQuestionChartDataPoint }) => {
        const data = params.data
        return `${data.classificationRuleName}<br />${params.marker} ${data.name}&nbsp;&nbsp;&nbsp;&nbsp;<strong>${data.value}</strong>`
      },
    },
    legend: {
      data: dataSeries.map(series => ({ name: series.name, icon: 'circle' })),
      bottom: 0,
      left: 0,
      selectedMode: false,
      textStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    },
    color: rankTypeColors,
    series: dataSeries.map((series, seriesIndex) => ({
      type: 'bar',
      stack: 'chart',
      barCategoryGap: isHorizontalChart ? '50%' : undefined,
      name: series.name,
      emphasis: { itemStyle: { color: darken(rankTypeColors[seriesIndex] ?? '', 0.1) } },
      itemStyle:
        series.name === QuestionAnswerRank.Neutral
          ? {
              decal: {
                color: palette?.grey[300],
                backgroundColor: 'white',
                dashArrayX: [1, 0],
                dashArrayY: [1, 3],
                symbolSize: 2,
                rotation: (3 * Math.PI) / 4,
              },
            }
          : undefined,
      data: [
        ...series.data.map(
          (d: number | undefined, dataIndex: number): AssessmentInsightsQuestionChartDataPoint => ({
            value: d,
            name: series.name,
            classificationRuleName: classificationRuleNames[dataIndex],
            classificationRule: classificationRulesWithFacetCount[dataIndex],
          }),
        ),
      ],
      bottom: 50,
      label: isHorizontalChart
        ? {
            show: true,
            position: [0, -16],
            align: 'left',
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
            textBorderColor: 'white',
            textBorderWidth: 3,
            formatter: (params: { data: AssessmentInsightsQuestionChartDataPoint }) =>
              params.data.classificationRuleName,
          }
        : {
            show: true,
            position: 'top',
            distance: 10,
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
            formatter: (params: { value: number }) => params.value || '',
          },
    })),
  }
}

export default useAssessmentInsightsQuestionChartOptions
