import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import TruncatedText from '@app/src/components/Ui/TruncatedText'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import SimpleRiskChip from '@app/src/pages/ResourceCollection/Collections/Assessments/SimpleRiskChip'
import Assessment from '@app/src/types/assessment'
import { SortOrder } from '@app/src/types/filter'
import { Provider, RiskTableView } from '@app/src/types/organizations'
import { OrganizationRiskSetting, ResponseItem, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { sortAlphabetically } from '@app/src/utils/helpers'
import { Box, CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Operators } from '../../ResourceCollection/Filters/useFilters'
import AssessmentMethodologyPieChart from '../AssessmentExplore/AssessmentMethodologyPieChart'
import AutomatedAssessmentDetailedReview from './AutomatedAssessmentDetailedReview'

interface AutomatedAssessmentCompanyPageProps {
  provider: Provider
  assessment: Assessment
  maxRowsForLongAnswers: number
}

const staleTime = 60000
const allowedFilters = ['assessmentTemplate.id', 'periodName', 'assessmentTemplateId']

const AutomatedAssessmentCompanyPage: React.FC<AutomatedAssessmentCompanyPageProps> = ({
  provider,
  assessment,
  maxRowsForLongAnswers,
}) => {
  const { formatMessage } = useIntl()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const periodFilterValue = useMemo(() => {
    return userFilters?.find(group => group.name === 'periodName')?.filters[0]?.value?.toString()
  }, [userFilters])

  const countryFlag = `https://flagcdn.com/w320/${provider.country.countryCode.toLowerCase()}.png`

  const assessmentTemplateId = useMemo(() => {
    const value = userFilters?.find(group => group.name === 'assessmentTemplate.id')?.filters[0]?.value
    return Number(value)
  }, [userFilters])

  const { assessmentQuestionnaireLinks } = useAssessmentQuestionnaireLink({
    includeAssessment: false,
    assessmentTemplateId: Number(assessmentTemplateId),
    includeQuestionnaire: false,
    enabled: Boolean(assessmentTemplateId),
  })

  const questionnaireTemplateIds = [...new Set(assessmentQuestionnaireLinks.map(item => item.questionnaireTemplateId))]
  const totalLevels = assessment?.assessmentTemplate?.levels ?? 5

  const getLevelDescription = (level: number) => {
    switch (level) {
      case 1:
        return assessment.assessmentTemplate.level1Description
      case 2:
        return assessment.assessmentTemplate.level2Description
      case 3:
        return assessment.assessmentTemplate.level3Description
      case 4:
        return assessment.assessmentTemplate.level4Description
      case 5:
        return assessment.assessmentTemplate.level5Description
    }
  }

  const { items: responseItems, isLoading: responseItemIsLoading } = useFetchCollectionWithPost<ResponseItem>({
    endpoint: endpoints.responseItemsCollection,
    key: FetchKey.ResponseItemsCollection,
    payload: {
      filter: [
        {
          name: 'response.isLatestSubmitted',
          filters: [{ operator: Operators.EqualTo, value: true }],
        },
        {
          name: 'response.request.periodName',
          filters: [{ operator: Operators.EqualTo, value: periodFilterValue }],
        },
        {
          name: 'response.request.subscriptions.target.id',
          filters: [{ operator: Operators.EqualTo, value: provider.id }],
        },
        {
          name: 'response.request.questionnaireTemplateId',
          filters: [{ operator: Operators.In, value: questionnaireTemplateIds }],
        },
      ],
      include: [
        'response.request',
        'requestItem.unit.symbol',
        'requestItem.template',
        'questionAnswerClassificationResults.questionAnswerClassificationRule',
      ],
    },
    options: {
      enabled: Boolean(periodFilterValue && questionnaireTemplateIds.length),
      staleTime: staleTime,
    },
  })
  const { items: riskScreeningItems, isLoading: riskIsLoading } = useFetchCollectionWithPost<RiskTableView>({
    key: [FetchKey.RiskScreening],
    endpoint: endpoints.riskScreeningAllRisks,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [
            {
              value: provider.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: [
        'country.risks.riskType',
        'organization.organizationsCountriesExposure',
        'organization.organizationsCountriesExposure.country',
      ],
      sort: {
        order: SortOrder.ASCENDING,
        target: 'name',
      },
      pagination: {
        pageNumber: 1,
        itemsPerPage: 9999,
      },
    },
    options: {
      staleTime: staleTime,
    },
  })

  const { data: relatedRiskSettings } = useFetchResource<OrganizationRiskSetting[]>({
    key: [FetchKey.AssessmentTemplateRiskIndices, assessmentTemplateId],
    endpoint: endpoints.assessmentTemplateRiskIndices(assessmentTemplateId),
    options: {
      enabled: Boolean(assessmentTemplateId),
    },
  })

  const risks = useMemo(() => {
    const countryRisks = riskScreeningItems[0]?.parentObject?.country?.risks || []
    const risksSortedAlphabetically = sortAlphabetically(
      relatedRiskSettings?.map(riskSetting => ({
        name: riskSetting.riskType.name,
        riskType: riskSetting.riskType,
        isActivated: riskSetting.isActivated,
        status: countryRisks?.find(risk => risk?.riskTypeId === riskSetting?.riskType.id)?.status,
      })),
      'name',
    ) as { riskType: RiskType; isActivated: boolean; status: RiskStatus }[]

    return [
      ...risksSortedAlphabetically.filter(risk => risk.isActivated),
      ...risksSortedAlphabetically.filter(risk => !risk.isActivated),
    ]
  }, [relatedRiskSettings, riskScreeningItems])

  if (responseItemIsLoading || riskIsLoading) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Stack>
    )
  }

  if (!responseItemIsLoading && !riskIsLoading && responseItems && assessment) {
    return (
      <>
        <Paper elevation={0} sx={{ p: 3, pt: 3.5 }}>
          <Box display="flex" alignItems="center">
            <Typography fontSize="30px" fontWeight="bold">
              {assessment.percentage}
            </Typography>
            <Box pt="7px" pl="2px">
              {formatMessage({ id: 'companyProfile.percentage' })}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" pt={3} ml="-5px">
            <AssessmentMethodologyPieChart
              assessmentTemplate={assessment.assessmentTemplate}
              level={assessment.totalLevel ?? 3}
            />
            <Typography variant="h6">{assessment.intervalType}</Typography>
            <Typography variant="body2" pl={1.5} pt="1px">
              {formatMessage({
                id: `companyProfile.levels.${totalLevels}.level${assessment.totalLevel}Percentage`,
              })}
            </Typography>
          </Box>
          <Typography pt={1}>{getLevelDescription(assessment.totalLevel ?? 0)}</Typography>
          <Stack direction="row" pt={3} pb={1} spacing={3}>
            <Box width="200px">
              <Typography variant="subtitle2" mb={0.5}>
                {formatMessage({ id: 'schemas.organization.location' })}
              </Typography>
              <Box display="flex" alignItems="" maxWidth="250px" pt="2px">
                <img src={countryFlag} alt={provider.country.name} style={{ width: '1.5em', height: '1em' }} />
                <Typography ml={1} sx={{ flexGrow: '1', lineHeight: '2.5rem' }}>
                  {provider.country.name}
                </Typography>
              </Box>
            </Box>
            {risks.map(({ riskType, isActivated, status }) => {
              return (
                <>
                  <Divider flexItem orientation="vertical" />
                  <Box key={`risk-${riskType.id}`} pb={0.5} minWidth="60px">
                    <TruncatedText
                      variant="subtitle2"
                      color={isActivated ? 'text.primary' : 'text.disabled'}
                      mb={0.5}
                      text={formatMessage({ id: `schemas.risk.riskIndexes.${riskType.source}.label` })}
                      line={1}
                    />
                    {isActivated && status ? (
                      <SimpleRiskChip riskStatus={status} />
                    ) : isActivated && !status ? (
                      <TruncatedText
                        color="text.disabled"
                        fontSize="small"
                        text={formatMessage({ id: 'companyProfile.noData' })}
                        line={1}
                      ></TruncatedText>
                    ) : (
                      <TruncatedText
                        color="text.disabled"
                        fontSize="small"
                        text={formatMessage({ id: 'companyProfile.inactiveIndex' })}
                        line={1}
                      ></TruncatedText>
                    )}
                  </Box>
                </>
              )
            })}
          </Stack>
          <AutomatedAssessmentDetailedReview
            responseItems={responseItems}
            assessment={assessment}
            maxRowsForLongAnswers={maxRowsForLongAnswers}
          />
        </Paper>
      </>
    )
  }
}

export default AutomatedAssessmentCompanyPage
