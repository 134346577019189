import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAddPicker from '@app/src/components/Drawer/Views/DrawerViewAddPicker'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import LinkButton from '@app/src/components/LinkButton'
import RouteLeavingGuard from '@app/src/components/RouteLeavingGuard'
import ControlledTextFieldWithFormatting from '@app/src/components/TextFieldWithFormatting/ControlledTextFieldWithFormatting'
import TextField from '@app/src/components/Ui/TextField'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '@app/src/pages/Configurations/SustainabilityLibraryScene'
import EditQuestionCategories from '@app/src/pages/QuestionEditor/EditQuestionCategories'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import theme from '@app/src/theme'
import { SortOrder } from '@app/src/types/filter'
import { QUESTION_TYPE_OPTIONS, QuestionType } from '@app/src/types/reporting'
import { Question, QuestionCategorization, RequestCollectionPicker, Unit } from '@app/src/types/resourceExplorer'
import { WF_ORGANIZATION_ID } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { ESRS_CATEGORIES, REGULAR_QUESTION_CATEGORIES } from '@app/src/wf-constants/questionCategories'
import { ArrowBack } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { generatePath, useHistory, useParams } from 'react-router'
import AdvancedFeatures from './AdvancedFeatures'
import EditQuestionAnswerClassification from './AnswerClassification'
import {
  mapToAnswerClassificationDataFormData,
  mapToClassificationSubmitData,
  mapToNumberClassificationDataFormData,
  mapToOptionClassificationDataFormData,
  QuestionAnswerClassificationRuleFormData,
  QuestionNumberClassificationRuleFormData,
  QuestionOptionClassificationRuleFormData,
} from './AnswerClassification/utils'
import EditTag from './EditTag'

interface EditQuestionParams {
  questionId?: string
}

export interface EditQuestionFormData extends Omit<Question, 'categorizations' | 'questionAnswerClassificationRules'> {
  categoryOptions?: (number[] | null)[]
  questionAnswerClassificationRules?: QuestionAnswerClassificationRuleFormData
  questionNumberClassificationRules?: QuestionNumberClassificationRuleFormData
  questionOptionClassificationRules?: QuestionOptionClassificationRuleFormData
}

export interface SubmitData extends Omit<Question, 'categorizations'> {
  categoryOptionIds: number[]
}

export const mapToSubmitData = (formData: EditQuestionFormData, isWorldfavorOrganization: boolean): SubmitData => {
  const categoryOptionIds = formData.categoryOptions?.flatMap(c => (c ? c : [])) ?? []
  const mappedQuestionClassificationRules = mapToClassificationSubmitData(isWorldfavorOrganization, formData)

  const { categoryOptions, questionNumberClassificationRules, questionOptionClassificationRules, ...submitData } = {
    ...formData,
    categoryOptionIds,
    questionAnswerClassificationRules: mappedQuestionClassificationRules,
  }

  return submitData
}

export const mapToCategoryFormData = (
  questionCategorizations: QuestionCategorization[],
): EditQuestionFormData['categoryOptions'] => {
  const formCategories = [...REGULAR_QUESTION_CATEGORIES, ...ESRS_CATEGORIES]
  return formCategories.map(category => {
    const foundCategorization = questionCategorizations?.find(
      categorization => categorization.categoryName.toLowerCase() === category.name.toLowerCase(),
    )
    return foundCategorization?.values?.map(v => v.id) ?? []
  })
}

const EditQuestion = (): JSX.Element => {
  const { questionId } = useParams<EditQuestionParams>()
  const { openDrawer } = useDrawer()
  const { account } = useAccount()
  const formMethods = useForm<EditQuestionFormData>({ mode: 'onChange', shouldUnregister: false })
  const {
    clearErrors,
    errors,
    reset,
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = formMethods
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(true)
  const confirm = useConfirm()
  const history = useHistory()
  const { mutateAsync: saveAsync, isLoading: isSubmitting } = useCreateResource<Question, SubmitData>()
  const { hasPermission } = usePermissions()

  const { data: item, isFetching } = useFetchResourceWithPost<Question>({
    endpoint: endpoints.questionById(questionId),
    key: [FetchKey.Questions, questionId],
    options: {
      enabled: Boolean(questionId),
    },
    body: ['options.category', 'questionAnswerClassificationRules'],
  })

  const isStandardQuestion = item?.isStandard ?? false

  const questionTypeId = watch(`questionTypeId`)
  const isStandard: boolean = watch(`isStandard`) ?? Boolean(item?.isStandard)
  const isWorldfavorOrganization = account?.organization?.id === WF_ORGANIZATION_ID
  const shouldSeeAdvancedSection = hasPermission(Permissions.WF_EMAIL_ACCESS)

  const questionPreviewPath = generatePath(paths.sustainabilityLibrary, {
    configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questions,
    configurationsSubPage: questionId,
  })

  useEffect(() => {
    clearErrors()
    if (questionTypeId !== QuestionType.Number) {
      setValue(`unitId`, null)
    }
    if (questionTypeId !== QuestionType.Options) {
      setValue(`requestCollectionId`, null)
      setValue(`allowMultiChoice`, null)
    }
  }, [questionTypeId])

  useEffect(() => {
    if (!isFetching && item) {
      reset({
        questionTypeId: item.questionTypeId,
        unitId: item.unitId,
        requestCollectionId: item.requestCollectionId,
        allowMultiChoice: item.allowMultiChoice,
        questionAnswerClassificationRules: mapToAnswerClassificationDataFormData(
          item.questionAnswerClassificationRules,
        ),
        questionNumberClassificationRules: mapToNumberClassificationDataFormData(
          item.questionAnswerClassificationRules,
        ),
        questionOptionClassificationRules: mapToOptionClassificationDataFormData(
          item.questionAnswerClassificationRules,
        ),
        categoryOptions: mapToCategoryFormData(item.categorizations),
      })
    }
  }, [item, isFetching])

  if (isFetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  const assignPicker = (pickerId: number) => {
    setValue('requestCollectionId', pickerId)
  }

  const onSubmit = async (values: EditQuestionFormData) => {
    if (item) {
      values.id = item.id
    }
    setIsUnsavedChanges(false)
    await saveAsync(
      {
        url: endpoints.saveQuestion,
        body: mapToSubmitData(values, isWorldfavorOrganization),
      },
      {
        onSuccess: async data => {
          showSnackbar({
            message: formatMessage(
              { id: 'templateBuilder.success' },
              { b: (chunks: React.ReactNode) => <b>{chunks}</b>, templateName: values.questionText },
            ),
            severity: 'success',
          })

          if (!questionId) {
            history.push(
              generatePath(paths.questionnaireBuilderCustomQuestion, {
                questionId: data?.id,
              }),
            )
          }
        },
        onError: error => {
          showErrorNotification({ requestError: error })
          setIsUnsavedChanges(true)
        },
      },
    )
  }

  const shoulodBlockNavigation = isDirty && isUnsavedChanges

  const confirmUpdate = async () => {
    confirm({
      title: formatMessage({ id: 'general.areYouSure' }),
      content: formatMessage({ id: 'schemas.question.update' }, { br: <br /> }),
      confirmationText: formatMessage({ id: 'templateBuilder.publish' }),
      confirmationButtonProps: { variant: 'text' },
      cancellationButtonProps: { variant: 'text' },
    }).then(() => {
      handleSubmit(onSubmit)()
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Box mt={2} mx={5}>
        <LinkButton startIcon={<ArrowBack color="primary" />} to={questionPreviewPath}>
          {formatMessage({ id: 'general.back' })}
        </LinkButton>
      </Box>
      <Box component="form" onSubmit={handleSubmit(values => onSubmit(values))} flexGrow={1}>
        <ResourceCollectionScene
          enableScroll
          title={item?.questionText ?? formatMessage({ id: 'templateBuilder.newCustomQuestion' })}
          actionButtons={[
            {
              label: formatMessage({ id: 'general.cancel' }),
              variant: 'outlined',
              onClick: () => history.push(questionPreviewPath),
            },
            {
              label: formatMessage({ id: 'general.save' }),
              variant: 'contained',
              type: 'button',
              onClick: item ? confirmUpdate : handleSubmit(onSubmit),
              loading: isSubmitting,
              disabled: !isDirty,
            },
          ]}
        >
          <Box pt={6} pb={2} px={6}>
            <Grid container py={3} justifyContent="center">
              <Grid
                container
                item
                bgcolor="common.white"
                spacing={3}
                xs={6}
                pt={2}
                pb={5}
                pl={2}
                pr={5}
                borderRadius={2}
              >
                {isWorldfavorOrganization && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="isStandard"
                          defaultChecked={item?.isStandard ?? true}
                          inputRef={register()}
                        />
                      }
                      labelPlacement="end"
                      label={
                        <FormLabel component="legend">
                          {formatMessage({ id: 'templateBuilder.includeInPool' })}
                        </FormLabel>
                      }
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Select
                    size="small"
                    name="questionTypeId"
                    fieldLabel="Question type"
                    control={control}
                    defaultValue={item?.questionTypeId}
                    options={QUESTION_TYPE_OPTIONS}
                    required
                    rules={{ required: true }}
                    error={errors.questionTypeId}
                    disabled={isStandardQuestion}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="questionText"
                    defaultValue={item?.questionText}
                    label="Title"
                    size="small"
                    inputRef={register({ required: true })}
                    required
                    multiline
                    minRows={1}
                    maxRows={4}
                    inputProps={{
                      maxLength: 500,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextFieldWithFormatting
                    name="guidance"
                    defaultValue={item?.guidance}
                    control={control}
                    label={formatMessage({ id: 'questionsConfig.questionSupplierGuidance' })}
                    error={Boolean(errors?.guidance)}
                    helperText={errors?.guidance?.message}
                    multiline
                    maxLength={3000}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextFieldWithFormatting
                    name="accessorGuidance"
                    defaultValue={item?.accessorGuidance}
                    control={control}
                    label={formatMessage({ id: 'questionsConfig.questionAccessorGuidance' })}
                    error={Boolean(errors?.accessorGuidance)}
                    helperText={errors?.accessorGuidance?.message}
                    multiline
                    maxLength={3000}
                    size="small"
                  />
                </Grid>

                {questionTypeId === QuestionType.Number && (
                  <Grid item xs={12}>
                    <Select
                      name="unitId"
                      control={control}
                      fieldLabel="Unit"
                      size="small"
                      navigation={{
                        url: endpoints.units,
                        type: 'post',
                        postObject: {
                          include: [],
                          filter: [],
                          sort: { target: 'name', order: SortOrder.ASCENDING },
                        },
                      }}
                      objectToOption={(unit: Unit): Option<number> => ({
                        label: unit.name,
                        additionalText: unit.symbol ?? undefined,
                        value: unit.id,
                      })}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography variant="subtitle1">{option.label}</Typography>
                            <Typography color="textSecondary">{option.additionalText}</Typography>
                          </Box>
                        </li>
                      )}
                      defaultValue={item?.unitId}
                      required
                      rules={{ required: true }}
                      error={errors.unitId}
                    />
                  </Grid>
                )}

                {questionTypeId === QuestionType.Options && (
                  <>
                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row">
                        <Box flex="auto">
                          <Select
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Box
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  display="flex"
                                  flexDirection="column"
                                >
                                  <Typography variant="subtitle1">{option.label}</Typography>
                                  <Typography color="textSecondary">{option.additionalText}</Typography>
                                </Box>
                              </li>
                            )}
                            name="requestCollectionId"
                            control={control}
                            fieldLabel="Options"
                            size="small"
                            navigation={{
                              url: endpoints.pickers,
                              type: 'post',
                              postObject: {
                                include: [],
                                filter: [],
                                sort: { target: 'name', order: SortOrder.ASCENDING },
                              },
                              fetchKey: FetchKey.Picker,
                            }}
                            objectToOption={(picker: RequestCollectionPicker): Option<number> => ({
                              label: picker.name,
                              value: picker.id,
                              additionalText: picker.permittedOptions.join(),
                            })}
                            findSelectedValue={(value, option) => value === option?.value}
                            defaultValue={item?.requestCollection?.id}
                            forceFetch
                            required
                            rules={{ required: questionTypeId === QuestionType.Options }}
                            error={errors.requestCollectionId}
                            disabled={isStandardQuestion}
                          />
                        </Box>
                        <Button
                          onClick={() => openDrawer(<DrawerViewAddPicker assignPicker={assignPicker} />)}
                          variant="text"
                          disabled={isStandardQuestion}
                          data-testid="add-custom-picker"
                        >
                          {formatMessage({ id: 'templateBuilder.addCustomPicker' })}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="filled" component="fieldset">
                        <FormLabel component="legend">{formatMessage({ id: 'templateBuilder.optionType' })}</FormLabel>
                        <FormHelperText>{formatMessage({ id: 'templateBuilder.optionTypeDesc' })}</FormHelperText>
                        <Controller
                          name="allowMultiChoice"
                          control={control}
                          render={({ onChange, value }): JSX.Element => (
                            <RadioGroup onChange={onChange} value={value?.toString()}>
                              <Box display="flex">
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="Single"
                                  data-testid="single-option"
                                  sx={{ marginRight: theme.spacing(5) }}
                                />
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="Multiple"
                                  data-testid="multiple-option"
                                />
                              </Box>
                            </RadioGroup>
                          )}
                        ></Controller>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="kpiName"
                    defaultValue={item?.kpiName}
                    label={formatMessage({ id: 'schemas.question.kpiName' })}
                    size="small"
                    inputRef={register()}
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                </Grid>
                {shouldSeeAdvancedSection && (
                  <Grid item xs={12}>
                    <AdvancedFeatures>
                      <EditTag name="tag" defaultValue={item?.tag} />
                    </AdvancedFeatures>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isWorldfavorOrganization && (
              <Grid container py={3} justifyContent="center">
                <Grid
                  container
                  item
                  bgcolor="common.white"
                  spacing={3}
                  xs={6}
                  pt={2}
                  pb={5}
                  pl={2}
                  pr={5}
                  borderRadius={2}
                >
                  <EditQuestionAnswerClassification questionTypeId={questionTypeId} />
                </Grid>
              </Grid>
            )}
            {isStandard && <EditQuestionCategories control={control} />}
          </Box>
        </ResourceCollectionScene>
      </Box>
      <RouteLeavingGuard
        when={shoulodBlockNavigation}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => shoulodBlockNavigation}
      />
    </FormProvider>
  )
}

export default EditQuestion
