import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import DrawerViewDeleteProvider from '@app/src/components/Drawer/Views/DrawerViewDeleteProvider'
import ReferralContactListItem from '@app/src/components/ReferralContactListItem'
import Avatar, { AvatarSize } from '@app/src/components/Ui/Avatar'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { EmailPurpose, useConnectProvidersModal } from '@app/src/context/ConnectProvidersModalContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useMenuState } from '@app/src/hooks/mui-hooks'
import { useReferralContact } from '@app/src/hooks/referralContact'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Provider } from '@app/src/types/organizations'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import {
  ChevronRight,
  ContentCopyOutlined,
  DeleteOutlineOutlined,
  InfoOutlined,
  LabelOutlined,
  Language,
  LinkOutlined,
  MailOutline,
  MoreHorizOutlined,
  NotificationsActiveOutlined,
} from '@mui/icons-material'
import {
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'
import DrawerViewLinkProviderCandidates from './DrawerViewLinkProviderCandidates'
import DrawerViewOrganizationForm from './DrawerViewOrganizationForm'
import DrawerViewReferralContact from './DrawerViewReferralContact'

type DrawerViewLinkProviderProps = {
  provider: Provider
  solution?: string
} & Omit<DrawerViewProps, 'title' | 'buttons'>

const DrawerViewLinkProvider: React.FC<DrawerViewLinkProviderProps> = ({ provider, solution = '', ...props }) => {
  const { formatMessage } = useIntl()
  const { openDrawer, closeDrawer } = useDrawer()
  const { trackEvent } = useAmplitude()
  const { referralContact, isReferralContactsLoading } = useReferralContact(provider.id)
  const { showSnackbar } = useSnackbar()
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()
  const { handleOpenConnectDialog } = useConnectProvidersModal()

  const { data: foundOrganizations } = useFetchResource<Provider[]>({
    endpoint: endpoints.findOrganization(provider.name),
    key: [FetchKey.FindOrganization, provider.name],
    options: {
      enabled: Boolean(provider.name),
    },
  })

  const matchedOrganizationsFound = Boolean(foundOrganizations?.length)
  const hasSentInvite = Boolean(referralContact?.referralEmailHistory?.length)

  const copyRefLinkToClipBoard = async () => {
    await navigator.clipboard.writeText(`${window.location.origin}?referral=${referralContact.referralCode}`)
    showSnackbar({ message: formatMessage({ id: 'referral.sendInvite.copySuccess' }), severity: 'success' })
  }

  return (
    <DrawerView
      title=""
      buttons={[
        {
          variant: 'text',
          label: formatMessage({ id: 'general.more' }),
          startIcon: <MoreHorizOutlined />,
          onClick: openMenu,
        },
        {
          label: hasSentInvite
            ? formatMessage({ id: 'referral.sendInvite.resendInviteToConnect' })
            : formatMessage({ id: 'referral.sendInvite.inviteToConnect' }),
          startIcon: hasSentInvite ? <NotificationsActiveOutlined /> : <MailOutline />,
          variant: 'contained',
          onClick: () => {
            trackEvent({
              name: AmplitudeTrackingEvents.Onboarding.SendInvite.OpenedConnectFlow,
              eventProps: {
                provider_id: String(provider.id),
              },
            })
            if (matchedOrganizationsFound && !referralContact) {
              openDrawer(
                <DrawerViewLinkProviderCandidates
                  resource={provider}
                  organizationCandidates={foundOrganizations}
                  referralContact={referralContact}
                />,
              )
            } else if (hasSentInvite) {
              openDrawer(<DrawerViewReferralContact provider={provider} />)
            } else {
              handleOpenConnectDialog([provider], EmailPurpose.InitialInvitation)
              closeDrawer()
            }
          },
        },
      ]}
      stackButtons
      {...props}
    >
      <Stack alignItems="center" spacing={2} pb={2}>
        <CompanyAvatar size={AvatarSize.XXL} organizationName={provider.name} imageUrl={provider.image?.url} unlinked />
        <Typography variant="h6">{provider.name}</Typography>

        {hasSentInvite ? (
          <Chip label={formatMessage({ id: 'referral.inviteSent.title' })} variant="filled" />
        ) : (
          <Chip
            label={formatMessage({ id: 'referral.invitation.notConnected' })}
            variant="outlined"
            icon={<InfoOutlined fontSize="small" />}
          />
        )}
      </Stack>

      <ReferralContactListItem
        provider={provider}
        referralContact={referralContact}
        isReferralContactsLoading={isReferralContactsLoading}
      />
      {hasSentInvite && (
        <ListItem
          dense
          disablePadding
          secondaryAction={
            <Button variant="text" startIcon={<ContentCopyOutlined />} onClick={copyRefLinkToClipBoard}>
              {formatMessage({ id: 'general.copy' })}
            </Button>
          }
        >
          <ListItemButton onClick={copyRefLinkToClipBoard}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'secondary.main' }}>
                <LinkOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'referral.sendInvite.menuItemTitle' })}
              secondary={formatMessage({ id: 'referral.sendInvite.menuItemSecondary' })}
            />
          </ListItemButton>
        </ListItem>
      )}
      {matchedOrganizationsFound && (
        <ListItem dense disablePadding secondaryAction={<ChevronRight />}>
          <ListItemButton
            onClick={() =>
              openDrawer(
                <DrawerViewLinkProviderCandidates
                  resource={provider}
                  referralContact={referralContact}
                  organizationCandidates={foundOrganizations}
                />,
              )
            }
          >
            <ListItemAvatar>
              <Avatar sx={({ palette }) => ({ bgcolor: palette.text.primary })}>
                <Language />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'referral.invitation.companyExists' })}
              secondary={formatMessage(
                { id: 'referral.invitation.similarCompanyCount' },
                { count: foundOrganizations?.length },
              )}
            />
          </ListItemButton>
        </ListItem>
      )}
      <Menu
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={closeMenu}
        sx={({ zIndex }) => ({
          zIndex: zIndex.modal + 2,
        })}
      >
        <MenuItem
          onClick={() => {
            openDrawer(<DrawerViewOrganizationForm provider={provider} />)
            closeMenu()
          }}
        >
          <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
            <LabelOutlined />
          </ListItemIcon>
          <ListItemText>{formatMessage({ id: 'general.categorize' })}</ListItemText>
        </MenuItem>
        {Boolean(solution) && (
          <MenuItem
            onClick={() => {
              openDrawer(<DrawerViewDeleteProvider provider={provider} solution={solution} />)
              closeMenu()
            }}
          >
            <ListItemIcon sx={({ spacing }) => ({ '&.MuiListItemIcon-root': { minWidth: spacing(7) } })}>
              <DeleteOutlineOutlined />
            </ListItemIcon>
            <ListItemText>{formatMessage({ id: 'schemas.company.delete' })}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </DrawerView>
  )
}

export default DrawerViewLinkProvider
