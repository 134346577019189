import RouterLink from '@app/src/components/RouterLink'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import MappingRequestStatusCell from '@app/src/components/Table/Cells/MappingRequestStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ProductMappingRequest } from '@app/src/types/product'
import { ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

interface ReceivedMappingRequestRowProps {
  row: ProductMappingRequest
}

const ReceivedMappingRequestRow: React.FC<ReceivedMappingRequestRowProps> = ({ row: mappingRequest }) => {
  const { formatMessage } = useIntl()

  const latestSubmittedResponse = mappingRequest?.productMappingResponses
    ?.sort(res => +new Date(res.submittedAt) - +new Date(res.submittedAt))
    .findLast(res => res.status === ResponseDraftStatus.Submitted)

  return (
    <TableRow
      component={RouterLink}
      to={generatePath(paths.mappingRequest, { id: mappingRequest?.id })}
      underline="none"
    >
      <MappingRequestStatusCell value={mappingRequest?.status} dueDate={mappingRequest?.deadline} isProvider />
      <ValueCell
        sx={{
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        value={mappingRequest?.product?.name}
      />
      <ValueCell value={mappingRequest?.creatorOrganization?.name} />
      <CategoryCell
        value={
          latestSubmittedResponse?.valueChainStatus
            ? formatMessage({
                id: `schemas.product.mappingStatusValues.${latestSubmittedResponse?.valueChainStatus}`,
              })
            : undefined
        }
      />
      <DateCell value={mappingRequest?.deadline} />
      <PeriodNameCell periodName={mappingRequest.periodName} />
    </TableRow>
  )
}

export default ReceivedMappingRequestRow
