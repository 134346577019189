import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import useBaselineAssessmentToPDF from '@app/src/components/ExportToPDF/useBaselineAssessmentToPDF'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import sortDistinctPeriodLabels from '@app/src/utils/sortDistinctPeriodLabels'
import { Download } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FiltersProps } from '..'
import FilterSelect from './FilterSelect'

type AssessmentCompanyPageFiltersProps = {
  provider: Provider
  contentRef: React.RefObject<HTMLDivElement>
  enabledAssessmentTemplates: AssessmentTemplate[]
  assessmentTemplateId: number
  setMaxRows: (value: number) => void
} & FiltersProps

const staleTime = 60000
const AssessmentCompanyPageFilters: React.FC<AssessmentCompanyPageFiltersProps> = ({
  provider,
  contentRef,
  allowedFilters,
  enabledAssessmentTemplates,
  assessmentTemplateId,
  setMaxRows,
  resetPage,
}) => {
  const { formatMessage } = useIntl()

  const {
    facets: [periodFacets],
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'periods'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [{ value: enabledAssessmentTemplates?.map(template => template.id), operator: Operators.In }],
      },
    ],
    sort: { order: SortOrder.DESCENDING },
    options: { enabled: Boolean(enabledAssessmentTemplates), staleTime: staleTime },
  })

  const assessmentTemplateName = enabledAssessmentTemplates?.find(
    template => template.id === assessmentTemplateId,
  )?.name

  const assessmentTemplateType = enabledAssessmentTemplates?.find(
    template => template.id === assessmentTemplateId,
  )?.assessmentType

  const sortedPeriodFacets = sortDistinctPeriodLabels(periodFacets, SortOrder.DESCENDING)

  const reorderedTemplates = React.useMemo(() => {
    const baseline = enabledAssessmentTemplates.find(
      template => template.assessmentType === AssessmentType.BaselineAssessment,
    )
    const automated = enabledAssessmentTemplates.filter(
      template => template.assessmentType === AssessmentType.StandardAssessment,
    )
    return baseline ? [baseline, ...automated] : enabledAssessmentTemplates
  }, [enabledAssessmentTemplates])

  const { generatePDF, isLoading } = useBaselineAssessmentToPDF(
    contentRef,
    provider,
    assessmentTemplateName,
    assessmentTemplateType,
    setMaxRows,
  )

  const handleExportToPdf = async () => {
    await generatePDF()
  }

  return (
    <Box>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {() => (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={3}>
              <Box width={400}>
                <Filter name="assessmentTemplate.id" operator={Operators.EqualTo}>
                  {({ value, onChange }) => (
                    <FilterSelect
                      size="small"
                      options={
                        reorderedTemplates.map(a => ({
                          label: a.name,
                          value: a.id.toString(),
                        })) ?? []
                      }
                      multiple={false}
                      value={value as string}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.filter.assessment' })}
                      preSelectedValue={reorderedTemplates?.[0]?.name}
                    />
                  )}
                </Filter>
              </Box>
              <Box width={160}>
                <Filter name="periodName" operator={Operators.EqualTo}>
                  {({ value, onChange }) => (
                    <FilterSelect
                      size="small"
                      options={
                        sortedPeriodFacets?.map(a => ({
                          label: a,
                          value: a,
                        })) ?? []
                      }
                      multiple={false}
                      value={value as string}
                      onChange={value => onChange(value)}
                      fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                      preSelectedValue={sortedPeriodFacets?.[0]}
                    />
                  )}
                </Filter>
              </Box>
            </Box>

            <Button
              startIcon={<Download />}
              variant="outlined"
              size="small"
              onClick={handleExportToPdf}
              disabled={isLoading}
              sx={{
                borderColor: 'grey.300',
                color: 'text.primary',
                ml: 3,
              }}
            >
              Export to PDF
            </Button>
          </Box>
        )}
      </Filters>
    </Box>
  )
}

export default AssessmentCompanyPageFilters
