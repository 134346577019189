import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import EmptyState from '@app/src/components/EmptyState'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { StandardAssessmentsExportColumnsAccessor } from '@app/src/export-columns/standardAssessments'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { useSelectableRows } from '@app/src/hooks/useSelectableRows'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import Assessment from '@app/src/types/assessment'
import { Provider } from '@app/src/types/organizations'
import { br } from '@app/src/utils/translationMarkup'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import ChatIcon from '@mui/icons-material/Chat'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import AssessmentCompareHeader from '../ResourceCollection/Collections/Assessments/AssessmentCompareHeader'
import AssessmentCompareRow from '../ResourceCollection/Collections/Assessments/AssessmentCompareRow'
import AssessmentFilters from '../ResourceCollection/Filters/AssessmentFilters'
import { Operators } from '../ResourceCollection/Filters/useFilters'
import { useOrganizedAssessmentData } from './useOrganizedAssessmentData'
import { assessmentOverviewAllowedFilters } from '@app/src/pages/Assessments/AssessmentsScene'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'

type AssessmentCompareSceneProps = {
  allowedFilters: string[]
  activatedAssessmentTemplates: AssessmentTemplate[]
  assessedPeriodNames: string[]
} & Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const AssessmentCompareScene: React.FC<AssessmentCompareSceneProps> = ({
  allowedFilters,
  activatedAssessmentTemplates,
  assessedPeriodNames,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const userFilters = useGetApiQueryFilters(assessmentOverviewAllowedFilters)
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)
  const { openDrawer } = useDrawer()

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
    ],
    sort: sorting,
    include: ['assessmentTemplate', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  const payload = {
    filter: [
      ...userFilters,
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.In,
            value: activatedAssessmentTemplates.map(x => x.id),
          },
        ],
      },
    ],
    sort: sorting,
    include: ['provider', 'assessmentTemplate'],
  }

  const {
    items: assessments,
    isLoading,
    isFetching,
    count,
    isError,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload,
    options: { enabled: Boolean(activatedAssessmentTemplates?.length) },
  })

  const { uniqueProviders, uniqueSortedAssessmentTemplateAndPeriod, structuredData } =
    useOrganizedAssessmentData(assessments)

  const { selectedRowsIds, setSelectedRowsIds, handleCheckboxChange, isHeaderChecked, handleHeaderCheckboxChange } =
    useSelectableRows<Provider>({
      rowsInPage: uniqueProviders,
    })

  return (
    <>
      <ResourceCollectionScene
        enableScroll
        title={formatMessage({ id: 'navbar.assessments' })}
        filter={
          <AssessmentFilters
            allowedFilters={assessmentOverviewAllowedFilters}
            preSelectedAssessmentTemplateIds={activatedAssessmentTemplates.map(x => x.id)}
            preSelectedPeriodNames={assessedPeriodNames}
          />
        }
        actionButtons={[
          {
            label: formatMessage({ id: 'assessments.setAssessments' }),
            variant: 'outlined',
            to: generatePath(paths.configurations, {
              configurationsPage: CONFIGURATION_PAGE_IDS.Assessments,
            }),
          },
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Assessment}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={StandardAssessmentsExportColumnsAccessor}
                  rawExportPayload={basePayload}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        buttonRow={
          selectedRowsIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.categorize' },
                    { count: selectedRowsIds.length },
                  ),
                  onClick: openUpdateDrawer,
                  disabled: isLoading || isFetching,
                },
                {
                  label: formatMessage({ id: 'assessments.setYourRiskAssessment' }, { count: selectedRowsIds.length }),
                  onClick: () => openDrawer(<DrawerViewRiskAssessment providerIds={selectedRowsIds} />),
                  disabled: isLoading || isFetching,
                },
              ]
            : undefined
        }
        {...props}
      >
        <Table<Provider>
          RowComponent={({ row }): JSX.Element => (
            <AssessmentCompareRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedProviders={selectedRowsIds}
              providerAssessmentData={structuredData}
            />
          )}
          HeaderComponent={() => (
            <AssessmentCompareHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              assessmentTemplateAndPeriod={uniqueSortedAssessmentTemplateAndPeriod}
              handleHeaderCheckboxChange={handleHeaderCheckboxChange}
              isHeaderChecked={isHeaderChecked}
            />
          )}
          data={uniqueProviders}
          count={count}
          isLoading={isLoading || isFetching}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          noPagination
          stickyColumnIndex={2}
          emptyState={
            <EmptyState
              title={formatMessage({ id: 'assessments.compare.emptyStateTitle' }, { br })}
              description={formatMessage({ id: 'assessments.compare.emptyStateDescription' })}
              iconComponent={ChatIcon}
            />
          }
        />
      </ResourceCollectionScene>

      <UpdateProvidersDrawer
        selectedProviders={uniqueProviders
          .filter(provider => selectedRowsIds.includes(provider.id))
          .map(provider => ({
            providerId: provider.id,
            name: provider.name,
            activityStatus: provider.activityStatus,
            finalRiskRating: provider.finalRiskRating,
            priority: provider.priority,
            providerApprovalStatus: provider.providerApprovalStatus,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            ownerUserId: provider.ownerUserId,
            tier: provider.tier,
            supplierUsage: provider.supplierUsage,
          }))}
        setSelectedProviders={setSelectedRowsIds}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
    </>
  )
}
export default AssessmentCompareScene
