import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import CreateNewRequestModal from '@app/src/components/CreateNewRequestModal'
import EmptyState from '@app/src/components/EmptyState'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { ReportType } from '@app/src/context/CreationModalProgressContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useAssessmentQuestionnaireInquiriesCount from '@app/src/hooks/useAssessmentQuestionnaireInquiriesCount'
import Assessment from '@app/src/types/assessment'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { AssessmentType } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents } from '@app/src/wf-constants'
import { ListAltOutlined } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import ResourceCollectionScene from '../../ResourceCollection'
import AssessmentCompanyPageFilters from '../../ResourceCollection/Filters/AssessmentCompanyPageFilters'
import { Operators } from '../../ResourceCollection/Filters/useFilters'
import { useFetchAssessmentSettings } from '../useFetchAssessmentSettings'
import AutomatedAssessmentCompanyPage from './AutomatedAssessmentCompanyPage'
import BaselineAssessmentCompanyPage from './BaselineAssessmentCompanyPage'

interface AssessmentsCompanyPageSceneProps {
  provider: Provider
}

const staleTime = 60000
const allowedFilters = ['assessmentTemplate.id', 'periodName', 'assessmentTemplateId']

const AssessmentsCompanyPageScene: React.FC<AssessmentsCompanyPageSceneProps> = ({ provider }) => {
  const { trackEvent } = useAmplitude()
  const { formatMessage } = useIntl()
  const { assessments } = useFlags()
  const [maxRowsForLongAnswers, setMaxRows] = useState(3)
  const [isCreateRequestDialogOpen, openCreateRequestDialog, closeCreateRequestDialog] = useDialogState()
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const contentRef = useRef<HTMLDivElement>(null)
  const assessmentTemplateIdFilterValue = useMemo(() => {
    const value = userFilters?.find(group => group.name === 'assessmentTemplate.id')?.filters[0]?.value
    return Number(value)
  }, [userFilters])

  const periodFilterValue = useMemo(() => {
    return userFilters?.find(group => group.name === 'periodName')?.filters[0]?.value?.toString()
  }, [userFilters])
  const { assessmentSettings } = useFetchAssessmentSettings()

  const activatedAssessmentTemplates = assessmentSettings
    .filter(setting => setting.isActivated)
    .map(setting => setting.assessmentTemplate)

  const activatedAccessibleAssessmentTemplates = useMemo(() => {
    if (assessments) {
      return activatedAssessmentTemplates
    } else {
      return activatedAssessmentTemplates.filter(
        template => template.assessmentType !== AssessmentType.StandardAssessment,
      )
    }
  }, [activatedAssessmentTemplates, assessments])

  const {
    items: [assessment],
    isLoading: assessmentIsLoading,
  } = useFetchCollectionWithPost<Assessment>({
    key: [FetchKey.AssessmentCollection, periodFilterValue],
    endpoint: endpoints.assessmentCollection,
    payload: {
      filter: [
        ...userFilters,
        {
          name: 'provider.id',
          filters: [
            {
              value: provider.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: [
        'assessmentTemplate.rules',
        'provider.organization.SubIndustryNaceCode',
        'assessmentTemplate.sections.rules',
        'response.items.requestItem.template.RequestCollection',
        'response.items.files',
      ],
      sort: {
        target: 'createdAt',
        order: SortOrder.DESCENDING,
      },
    },
    options: {
      staleTime: staleTime,
    },
  })

  useEffect(() => {
    trackEvent({
      name: AmplitudeTrackingEvents.Analyze.Assessment.ViewedPage,
      eventProps: {
        page: 'provider',
      },
    })
  }, [])

  const isBaselineAssessmentSelected = useMemo(() => {
    return activatedAssessmentTemplates.find(
      template =>
        template.assessmentType === AssessmentType.BaselineAssessment &&
        template.id === assessmentTemplateIdFilterValue,
    )
  }, [activatedAssessmentTemplates, assessmentTemplateIdFilterValue])

  const selectedAssessmentTemplates = useMemo(() => {
    return activatedAccessibleAssessmentTemplates.filter(setting => setting.id === assessmentTemplateIdFilterValue)
  }, [activatedAccessibleAssessmentTemplates, assessmentTemplateIdFilterValue])

  const { inquiriesCount, isLoadingInquiriesCount, questionnaireTemplates } = useAssessmentQuestionnaireInquiriesCount(
    selectedAssessmentTemplates[0],
    Boolean(periodFilterValue && selectedAssessmentTemplates),
    provider.id,
    periodFilterValue,
  )

  return (
    <>
      <ResourceCollectionScene
        enableScroll
        title=""
        filter={
          <AssessmentCompanyPageFilters
            provider={provider}
            contentRef={contentRef}
            allowedFilters={allowedFilters}
            enabledAssessmentTemplates={activatedAccessibleAssessmentTemplates}
            assessmentTemplateId={assessmentTemplateIdFilterValue}
            setMaxRows={setMaxRows}
          />
        }
        embedded
      >
        <Box ref={contentRef} height="100%">
          {!isLoadingInquiriesCount && !assessmentIsLoading && !assessment && !inquiriesCount ? (
            <>
              <Stack flexGrow={1} justifyContent="center" alignItems="center" height="100%">
                <EmptyState
                  iconComponent={ListAltOutlined}
                  title={formatMessage({ id: 'baseAssessment.providerPage.noData' })}
                  description={formatMessage({ id: 'baseAssessment.providerPage.noDataDescription' })}
                >
                  <Button variant="outlined" onClick={openCreateRequestDialog}>
                    {formatMessage({ id: 'assessments.overviewGraph.noInquiriesEmptyState.action' })}
                  </Button>
                </EmptyState>
                <CreationModalProgressContextProvider>
                  <CreateNewRequestModal
                    open={isCreateRequestDialogOpen}
                    onClose={closeCreateRequestDialog}
                    reportType={ReportType.WF}
                    disableInitialBack
                    selectedQuestionnaireTemplates={questionnaireTemplates.filter(x => x.isStandardReport)}
                  />
                </CreationModalProgressContextProvider>
              </Stack>
            </>
          ) : !isLoadingInquiriesCount && !assessmentIsLoading && !assessment && inquiriesCount ? (
            <Stack flexGrow={1} justifyContent="center" alignItems="center" height="100%">
              <EmptyState
                iconComponent={ListAltOutlined}
                title={formatMessage({ id: 'baseAssessment.providerPage.noResponses' })}
                description={formatMessage({ id: 'baseAssessment.providerPage.noResponseDescription' })}
              />
            </Stack>
          ) : isBaselineAssessmentSelected ? (
            <BaselineAssessmentCompanyPage provider={provider} assessment={assessment} />
          ) : (
            <AutomatedAssessmentCompanyPage
              provider={provider}
              assessment={assessment}
              maxRowsForLongAnswers={maxRowsForLongAnswers}
            />
          )}
        </Box>
      </ResourceCollectionScene>
    </>
  )
}

export default AssessmentsCompanyPageScene
