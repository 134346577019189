import { ActionButton } from '@app/src/components/ActionButtons'
import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import RouteLeavingGuard from '@app/src/components/RouteLeavingGuard'
import SimpleBreadcrumbs from '@app/src/components/SimpleBreadcrumbs'
import MappingRequestStatusCell from '@app/src/components/Table/Cells/MappingRequestStatusCell'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import { ProductMappingRequest } from '@app/src/types/product'
import { MappingRequestStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import paths from '@app/src/wf-constants/paths'
import { Edit } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import AutomationAlert from './AutomationAlert'

interface ReceivedMappingRequestSceneContainerProps {
  enableSubmitButton: boolean
  onSubmitResponse?: () => void
  onUpdateResponse?: () => void
  onDiscardDraft?: () => void
  mappingRequest?: ProductMappingRequest
  isUpdating?: boolean
  responseSubmittedAt?: string
}

const ReceivedMappingRequestSceneContainer: React.FC<PropsWithChildren<ReceivedMappingRequestSceneContainerProps>> = ({
  children,
  enableSubmitButton,
  mappingRequest,
  isUpdating,
  onSubmitResponse,
  onUpdateResponse,
  onDiscardDraft,
  responseSubmittedAt,
}) => {
  const { formatMessage } = useIntl()
  const isDirty = false
  const history = useHistory()
  const { formatValueToDate } = useGetFormattedDate()

  const getButtons = (): ActionButton[] | undefined => {
    if (responseSubmittedAt) {
      return [
        {
          label: formatMessage({ id: 'general.update' }),
          loading: isUpdating,
          variant: 'outlined',
          startIcon: <Edit />,
          size: 'large',
          onClick: onUpdateResponse,
        },
      ]
    }
    return [
      ...insertIf<ActionButton>(Boolean(mappingRequest?.productMappingResponses?.length), {
        label: formatMessage({ id: 'reporting.discardDraft' }),
        loading: isUpdating,
        variant: 'text',
        startIcon: <Edit />,
        size: 'large',
        onClick: onDiscardDraft,
      }),
      {
        label: formatMessage({ id: 'general.submit' }),
        disabled: !enableSubmitButton,
        variant: 'contained',
        size: 'large',
        loading: isUpdating,
        onClick: onSubmitResponse,
      },
    ]
  }

  if (!mappingRequest) return null

  return (
    <>
      <RouteLeavingGuard when={isDirty} navigate={path => history.push(path)} shouldBlockNavigation={() => isDirty} />
      <Helmet>
        <title>{formatMessage({ id: 'navbar.receivedMappingRequests' })}</title>
      </Helmet>
      <AutomationAlert />
      <Stack px={4} pt={2}>
        <SimpleBreadcrumbs
          crumbs={[
            {
              label: formatMessage({
                id: 'reporting.statuses.Sent',
              }),
              to: generatePath(paths.mappingRequestCollection),
            },
            {
              label: mappingRequest?.product?.name,
            },
          ]}
        />
      </Stack>
      <ResourceCollectionScene
        title={
          <Stack direction="row" spacing={2}>
            <Typography variant="h1">{mappingRequest.product?.name}</Typography>
            <MappingRequestStatusCell
              value={mappingRequest.status}
              dueDate={mappingRequest.deadline}
              isProvider
              disableCell
            />
          </Stack>
        }
        actionButtons={getButtons()}
        subtitle={
          mappingRequest.status !== MappingRequestStatus.Submitted &&
          mappingRequest.deadline && (
            <Typography variant="body2" color="textSecondary">
              {formatMessage(
                { id: 'transparency.productMappingDeadline' },
                { date: formatValueToDate({ value: mappingRequest.deadline }) },
              )}
            </Typography>
          )
        }
        enableScroll
      >
        {children}
      </ResourceCollectionScene>
    </>
  )
}

export default ReceivedMappingRequestSceneContainer
