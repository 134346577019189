import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import { StandardAssessmentsExportColumnsAccessor } from '@app/src/export-columns/standardAssessments'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Box, Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'
import { Operators } from '../ResourceCollection/Filters/useFilters'
import AssessmentOverviewGraphWithHeader from './AssessmentOverviewGraphWithHeader'
import AssessmentFilters from '@app/src/pages/ResourceCollection/Filters/AssessmentFilters'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { assessmentOverviewAllowedFilters } from '@app/src/pages/Assessments/AssessmentsScene'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import endpoints from '@app/src/api/endpoints'

type AssessmentOverviewSceneProps = {
  allowedFilters: string[]
  activatedAssessmentTemplates: AssessmentTemplate[]
  assessedPeriodNames: string[]
} & Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const AssessmentOverviewScene: React.FC<AssessmentOverviewSceneProps> = ({
  allowedFilters,
  activatedAssessmentTemplates,
  assessedPeriodNames,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { sorting } = useSort()
  const { openDrawer } = useDrawer()
  const userFilters = useGetApiQueryFilters(assessmentOverviewAllowedFilters)

  const {
    facets: [assessmentFacets = []],
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'periods'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'assessmentTemplateId', isEnum: true }],
    filter: userFilters,
    options: { enabled: Boolean(userFilters), staleTime: 60000 },
  })

  const assessmentTemplateIdFilterValue = useMemo(() => {
    return (
      (userFilters?.find(group => group.name === 'assessmentTemplate.id')?.filters[0]?.value as string[]) ?? []
    ).map(Number)
  }, [userFilters])

  const filteredAssessmentTemplates = useMemo(() => {
    return activatedAssessmentTemplates.filter(template => assessmentTemplateIdFilterValue.includes(template.id))
  }, [assessmentFacets, activatedAssessmentTemplates])

  const rawExportPayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
    ],
    sort: sorting,
    include: ['assessmentTemplate', 'provider.country.risks.riskType', 'provider.organization.contacts.user'],
  }

  return (
    <ResourceCollectionScene
      enableScroll
      title={formatMessage({ id: 'navbar.assessments' })}
      filter={
        <AssessmentFilters
          allowedFilters={allowedFilters}
          preSelectedAssessmentTemplateIds={activatedAssessmentTemplates.map(x => x.id)}
          preSelectedPeriodNames={assessedPeriodNames}
        />
      }
      actionButtons={[
        {
          label: formatMessage({ id: 'assessments.setAssessments' }),
          variant: 'outlined',
          to: generatePath(paths.configurations, {
            configurationsPage: CONFIGURATION_PAGE_IDS.Assessments,
          }),
        },
        {
          label: formatMessage({ id: 'resourceCollections.general.export' }),
          variant: 'outlined',
          onClick: () =>
            openDrawer(
              <DrawerViewExport
                resourceType={ResourceTypes.Assessment}
                count={0}
                userFilter={userFilters}
                exportColumns={StandardAssessmentsExportColumnsAccessor}
                rawExportPayload={rawExportPayload}
              />,
            ),
        },
      ]}
      disableLeftPadding
      {...props}
    >
      <Box px={4} my={3}>
        <Grid container spacing={2}>
          {filteredAssessmentTemplates.map(assessmentTemplate => (
            <Grid item xs={12} md={6} key={assessmentTemplate.id}>
              <AssessmentOverviewGraphWithHeader assessmentTemplate={assessmentTemplate} userFilters={userFilters} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ResourceCollectionScene>
  )
}

export default AssessmentOverviewScene
