import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { FilterFacetSelectCommonProps } from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import OptionWithLabel from '@app/src/pages/ResourceCollection/Filters/OptionWithLabel'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { Chip } from '@mui/material'
import React from 'react'

const PeriodNameOptionWithLabel: FilterFacetSelectCommonProps['renderOption'] = (
  props,
  facetItem,
  { selected },
  { multiple },
) => {
  const { formatPeriodName } = usePeriodName()
  return (
    <OptionWithLabel
      props={props}
      selected={selected}
      label={formatPeriodName(facetItem.label)}
      multiple={multiple ?? false}
    />
  )
}

const PeriodNameTags: FilterFacetSelectCommonProps['renderTags'] = (value, getTagProps) => {
  const { formatPeriodName } = usePeriodName()
  return value?.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      key={option?.value?.toString() || index}
      label={formatPeriodName(option?.label)}
      size="small"
    />
  ))
}

const orderByLatestPeriodFirst: FilterFacetSelectCommonProps['customSort'] = (a, b) => -comparePeriods(a.label, b.label)

export const PeriodFilterOptions: Pick<FilterFacetSelectCommonProps, 'renderOption' | 'renderTags' | 'customSort'> = {
  renderOption: PeriodNameOptionWithLabel,
  renderTags: PeriodNameTags,
  customSort: orderByLatestPeriodFirst,
}
