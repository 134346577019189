import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { EmailPurpose, useConnectProvidersModal } from '@app/src/context/ConnectProvidersModalContext'
import { SortOrder } from '@app/src/types/filter'
import { Provider, ProviderLinkStatus } from '@app/src/types/organizations'
import paths from '@app/src/wf-constants/paths'
import { CheckCircleOutline, FolderOffOutlined, MailOutline, WarningAmberOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Divider, Stack, Typography, alpha, useTheme } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { Operators } from '../../../Filters/useFilters'
import { UploadImportFileResoponse } from './ImportProvidersModal'

type ImportProviderSummaryProps = {
  importResult: UploadImportFileResoponse
  onClose: () => void
}

const ImportProviderSummary: React.FC<ImportProviderSummaryProps> = ({ importResult, onClose }) => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const history = useHistory()
  const { handleOpenConnectDialog } = useConnectProvidersModal()

  const { items: importedProviders, isFetching } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.ProviderCollection,
    endpoint: endpoints.providersCollection,
    payload: {
      sort: { target: 'createdAt', order: SortOrder.DESCENDING },
      include: ['referralContact'],
      filter: [
        {
          name: 'linkStatus',
          filters: [
            {
              operator: Operators.EqualTo,
              value: ProviderLinkStatus.Created,
            },
          ],
        },
      ],
      pagination: {
        pageNumber: 1,
        itemsPerPage: importResult.successCount,
      },
    },
    options: {
      enabled: importResult.successCount > 0,
    },
  })

  const isAnySuccess = importResult.successCount > 0
  const isAnyError = importResult.errorCount > 0
  const isResultNoNewEntries = importResult.successCount === 0 && importResult.errorCount === 0

  return (
    <Stack p={2} spacing={5}>
      {isResultNoNewEntries && (
        <>
          <Stack direction="row" spacing={4}>
            <FolderOffOutlined sx={{ color: alpha(palette.common.black, 0.6) }} />
            <Typography>{formatMessage({ id: 'import.summary.noNewEntriesInfo' })}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end" pt={4}>
            <Button onClick={onClose}>{formatMessage({ id: 'general.close' })}</Button>
          </Stack>
        </>
      )}
      {!isResultNoNewEntries && (
        <Typography color="textSecondary">{formatMessage({ id: 'import.summary.description' })}</Typography>
      )}
      {isAnySuccess && (
        <Stack direction="row" spacing={4}>
          <CheckCircleOutline sx={{ color: 'brandDecorative.emerald' }} />
          <Typography>
            {formatMessage({ id: 'import.summary.successfullyImported' }, { count: importResult.successCount })}
          </Typography>
        </Stack>
      )}
      {isAnySuccess && isAnyError && <Divider />}
      {isAnyError && (
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <WarningAmberOutlined sx={{ color: 'brandDecorative.sunsetSky' }} />
          <Stack direction="row" justifyContent="space-between" flexGrow={1}>
            <Stack>
              <Typography gutterBottom>
                {formatMessage({ id: 'import.summary.failedToImport' }, { count: importResult.errorCount })}
              </Typography>
              <Typography gutterBottom variant="caption" color="textSecondary">
                {formatMessage({ id: 'import.summary.failedToImportDetails' })}
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                history.push(generatePath(paths.notifications))
                onClose()
              }}
            >
              {formatMessage({ id: 'general.view' })}
            </Button>
          </Stack>
        </Stack>
      )}
      {isAnySuccess && (
        <>
          <Box pt={4}>
            <Alert
              variant="filled"
              severity="info"
              sx={{
                padding: '0px',
                backgroundColor: 'common.white',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                '& .MuiAlert-icon': {
                  marginLeft: '0px',
                },
              }}
            >
              {formatMessage({ id: 'import.summary.info' })}
            </Alert>
          </Box>

          <Stack direction="row" spacing={2} justifyContent="flex-end" pt={4}>
            <Button onClick={onClose}>{formatMessage({ id: 'import.summary.inviteLaterButton' })}</Button>
            <LoadingButton
              variant="contained"
              startIcon={<MailOutline />}
              onClick={() => {
                handleOpenConnectDialog(importedProviders, EmailPurpose.InitialInvitation)
                onClose()
              }}
              loading={isFetching}
            >
              {formatMessage({ id: 'import.summary.inviteButton' }, { count: importResult.successCount })}
            </LoadingButton>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default ImportProviderSummary
