import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import DrawerView from '@app/src/components/Drawer/DrawerView'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import CompanyAvatar from '@app/src/pages/ResourceCollection/Collections/CellComponents/CompanyAvatar'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Organization, SimpleOrganization } from '@app/src/types/organizations'
import { SearchOutlined, SwapHoriz } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField as MuiTextField,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useState } from 'react'

interface TransferDataToOtherOrganizationDrawerProps {
  closeDrawer: () => void
  sourceOrganization: Organization
  transferData: (sourceOrganizationId: number, destinationOrganizationId: number) => Promise<void>
}

const TransferDataToOtherOrganizationDrawer: React.FC<TransferDataToOtherOrganizationDrawerProps> = ({
  closeDrawer,
  sourceOrganization,
  transferData,
}) => {
  const [organizationSearch, setOrganizationSearch] = useState('')
  const confirm = useConfirm()

  const { items: organizations, isLoading } = useFetchCollectionWithPost<SimpleOrganization>({
    key: [FetchKey.OrganizationCollection, organizationSearch],
    endpoint: endpoints.organizationsCollection,
    payload: {
      filter: [
        {
          name: 'name',
          filters: [{ value: organizationSearch, operator: Operators.Contains }],
        },
        {
          name: 'id',
          filters: [{ value: sourceOrganization.id, operator: Operators.NotEqualTo }],
        },
      ],
      include: ['image'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 20,
      },
    },
    options: {
      enabled: organizationSearch?.length >= 2,
    },
  })

  const confirmTransferData = async (destinationOrganization: SimpleOrganization) => {
    confirm({
      confirmationKeyword: sourceOrganization.name,
      title: 'Are you sure?',
      description: `All data from ${sourceOrganization.name} will be transferred to ${destinationOrganization.name}. Enter the name "${sourceOrganization.name}"  to confirm`,
      confirmationText: 'Transfer',
      confirmationButtonProps: { color: 'warning', variant: 'contained', startIcon: <SwapHoriz /> },
      buttonOrder: ['confirm', 'cancel'],
    }).then(async () => {
      closeDrawer()
      await transferData(sourceOrganization.id, destinationOrganization.id)
    })
  }

  return (
    <DrawerView title="Transfer all data to">
      <Box mx={3} mt={2}>
        <MuiTextField
          fullWidth
          variant="outlined"
          size="small"
          label="Search destination organization"
          value={organizationSearch}
          onChange={e => setOrganizationSearch(e.target.value)}
          sx={{ mb: 4, pr: 4 }}
        />
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : organizationSearch.length < 2 ? (
        <EmptyState
          variant={EmptyStateVariant.Small}
          title="Enter at least two characters to search"
          iconComponent={SearchOutlined}
        />
      ) : (
        <List>
          {organizations.map(o => (
            <ListItemButton key={o.id} onClick={() => confirmTransferData(o)}>
              <ListItemAvatar>
                <CompanyAvatar organizationName={o.name[0]} imageUrl={o.image?.url} />
              </ListItemAvatar>
              <ListItemText>
                {o.name} ({o.id})
              </ListItemText>
              <IconButton>
                <SwapHoriz />
              </IconButton>
            </ListItemButton>
          ))}
        </List>
      )}
    </DrawerView>
  )
}

export default TransferDataToOtherOrganizationDrawer
