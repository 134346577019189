import { ReportType, useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CompanyReport from './CompanyReport'
import EdciReport from './EdciReport'
import RequestType from './RequestType'
import SfdrReportWithCompanies from './SfdrReport/SfdrReportWithCompanies'
import SfdrReportWithoutCompanies from './SfdrReport/SfdrReportWithoutCompanies'
import WfReport from './WfReport'
import { QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { Periods } from './PeriodSelector/PeriodSelector'

export type CreateNewRequestModalProps = {
  open: boolean
  onClose: () => void
  reportType?: ReportType
  disableInitialBack?: boolean
  selectedQuestionnaireTemplates?: QuestionnaireTemplate[] | undefined
}

export type BaseFormData = {
  templateIds: Array<{ id: number; title: string }>
  dateType: Periods
  datePeriod: undefined
  dateYear: undefined
  periodName: string
  responders: Array<{ id: number; name: string; organizationId: number }>
  deadline: string
}

const CreateNewRequestModal: React.FC<CreateNewRequestModalProps> = ({
  open,
  onClose,
  reportType: reportTypeProp = ReportType.NONE,
  disableInitialBack = false,
  selectedQuestionnaireTemplates,
}) => {
  const { setActiveStep } = useCreationModalProgress()
  const [reportType, setReportType] = useState<ReportType>(reportTypeProp)

  const getReportTypeComponent = (reportType: ReportType) => {
    switch (reportType) {
      case ReportType.COMPANY:
        return <CompanyReport onClose={onClose} onBack={() => setReportType(ReportType.NONE)} />
      case ReportType.EDCI:
        return <EdciReport onClose={onClose} onBack={() => setReportType(ReportType.NONE)} />
      case ReportType.SFDR_WITHOUT_COMPANIES:
        return (
          <SfdrReportWithoutCompanies
            onClose={onClose}
            onBack={() => setReportType(ReportType.NONE)}
            disableInitialBack={disableInitialBack}
          />
        )
      case ReportType.SFDR_WITH_COMPANIES:
        return (
          <SfdrReportWithCompanies
            onClose={onClose}
            onBack={() => setReportType(ReportType.NONE)}
            disableInitialBack={disableInitialBack}
          />
        )
      case ReportType.WF:
        return (
          <WfReport
            onClose={onClose}
            onBack={() => setReportType(ReportType.NONE)}
            disableInitialBack={disableInitialBack}
            selectedQuestionnaireTemplates={selectedQuestionnaireTemplates}
          />
        )
      case ReportType.NONE:
        return <RequestType onClose={onClose} setReportType={setReportType} />
    }
  }

  useEffect(() => {
    if (open && !reportTypeProp) {
      setReportType(ReportType.NONE)
      setActiveStep(1)
    }
    if (open && ReportType.WF) {
      setActiveStep(1)
    }
  }, [open, reportTypeProp])

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      {getReportTypeComponent(reportType)}
    </Dialog>
  )
}

export default CreateNewRequestModal
