import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import HoverDialog from '@app/src/components/HoverDialog'
import LinkButton from '@app/src/components/LinkButton'
import { useProvidersByPendingInquiries } from '@app/src/hooks/providersByPendingInquiries'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { InquiryStatus } from '@app/src/types/resourceExplorer'
import { getDateInDays } from '@app/src/utils'
import { br } from '@app/src/utils/translationMarkup'
import paths from '@app/src/wf-constants/paths'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import { Box, Button, Menu, MenuItem, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import ReactEcharts, { EChartsOption } from 'echarts-for-react'
import React, { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'
import GraphLegend from './GraphLegend'
import StatisticsCard from './StatisticsCard'

const ALL_PERIODS = 'All'

enum DeadlineTypes {
  Overdue = 'overdue',
  DueSoon = 'due-soon',
  DueLater = 'due-later',
}

const CompaniesByRequestDeadline = () => {
  const { formatMessage } = useIntl()
  const { palette } = useTheme()
  const [selectedPeriod, setSelectedPeriod] = useState<string>(ALL_PERIODS)
  const [highlightedDeadline, setHighlightedDeadline] = useState<DeadlineTypes>()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const history = useHistory()
  const [isPeriodMenuOpen, setIsPeriodMenuOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { formatPeriodName } = usePeriodName()

  const today = new Date()
  const inOneWeek = getDateInDays(7)
  const formattedTodaysDate = formatDate(today)?.split(' ')?.[0] ?? ''
  const formattedWithinAWeeksDate = formatDate(inOneWeek)?.split(' ')?.[0] ?? ''

  const periodsFilter =
    selectedPeriod.length && selectedPeriod !== ALL_PERIODS
      ? [
          {
            name: 'periodName',
            filters: [
              {
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ],
          },
        ]
      : []

  const {
    providersWithInquiriesWithNoDeadline,
    providersWithOverdueInquiries,
    providersWithInquiriesOverdueWithinAWeek,
    providersWithInquiriesOverdueWithinOverAWeek,
    isLoading: providersByPendingInquiriesLoading,
  } = useProvidersByPendingInquiries(periodsFilter)

  const {
    facets: [requestedPeriods],
    isLoading: requestedPeriodsIsLoading,
  } = useFetchFacets({
    key: [FetchKey.Inquiry, 'periods'],
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [],
  })

  const availablePeriods = requestedPeriods?.map(facet => facet.label).sort((a, b) => parseInt(b) - parseInt(a))

  const overdueCount = providersWithOverdueInquiries?.length
  const dueSoonCount = providersWithInquiriesOverdueWithinAWeek?.length
  const laterCount = providersWithInquiriesWithNoDeadline?.length + providersWithInquiriesOverdueWithinOverAWeek?.length

  const isLoading = providersByPendingInquiriesLoading || requestedPeriodsIsLoading
  const noCompanyData = overdueCount === 0 && dueSoonCount === 0 && laterCount === 0
  const emptyState = !isLoading && !availablePeriods?.length && noCompanyData
  const noData = Boolean(availablePeriods?.length) && noCompanyData

  const companiesOverdue = providersWithOverdueInquiries?.map(inquiry => inquiry.value)
  const companiesDueSoon = providersWithInquiriesOverdueWithinAWeek?.map(inquiry => inquiry.value)
  const companiesDueOverAWeek = providersWithInquiriesOverdueWithinOverAWeek?.map(inquiry => inquiry.value) ?? []
  const companiesNoDeadline = providersWithInquiriesWithNoDeadline?.map(inquiry => inquiry.value) ?? []
  const companiesDueLater = [...companiesNoDeadline, ...companiesDueOverAWeek]

  const overdueLink = stringifyQueryFilters({
    url: generatePath(paths.manageRequest, {
      view: ViewTypeName.Requests,
    }),
    queryParams: {
      filters: [
        {
          name: 'status',
          value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
          operator: Operators.In,
        },
        {
          name: 'provider.id',
          value: companiesOverdue,
          operator: Operators.In,
        },
        {
          name: 'deadline',
          value: formattedTodaysDate,
          operator: Operators.LowerThan,
          uniqueId: 'deadlineTo',
        },
        ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
          ? [
              {
                name: 'periodName',
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ]
          : []),
      ],
    },
  })

  const dueSoonLink = stringifyQueryFilters({
    url: generatePath(paths.manageRequest, {
      view: ViewTypeName.Requests,
    }),
    queryParams: {
      filters: [
        {
          name: 'status',
          value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
          operator: Operators.In,
        },
        {
          name: 'provider.id',
          value: companiesDueSoon,
          operator: Operators.In,
        },
        {
          name: 'deadline',
          value: formattedWithinAWeeksDate,
          operator: Operators.LowerThanOrEqual,
          uniqueId: 'deadlineTo',
        },
        {
          name: 'deadline',
          value: formattedTodaysDate,
          operator: Operators.GreaterThanOrEqual,
          uniqueId: 'deadlineFrom',
        },
        ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
          ? [
              {
                name: 'periodName',
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ]
          : []),
      ],
    },
  })

  const dueLaterLink = stringifyQueryFilters({
    url: generatePath(paths.manageRequest, {
      view: ViewTypeName.Requests,
    }),
    queryParams: {
      filters: [
        {
          name: 'status',
          value: [InquiryStatus.Requested, InquiryStatus.CorrectionNeeded],
          operator: Operators.In,
        },
        {
          name: 'provider.id',
          value: companiesDueLater,
          operator: Operators.In,
        },
        {
          name: 'deadline',
          value: formattedWithinAWeeksDate,
          operator: Operators.GreaterThan,
          uniqueId: 'deadlineFrom',
        },
        ...(selectedPeriod.length && selectedPeriod !== ALL_PERIODS
          ? [
              {
                name: 'periodName',
                value: [selectedPeriod],
                operator: Operators.In,
              },
            ]
          : []),
      ],
    },
  })

  const options: EChartsOption = {
    type: 'bar',
    stack: 'chart',
    itemStyle: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    xAxis: {
      show: false,
      max: overdueCount + dueSoonCount + laterCount,
      splitLine: {
        show: false,
      },
      triggerEvent: true,
    },
    yAxis: {
      inverse: true,
      type: 'category',

      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      triggerEvent: true,
      axisLabel: {
        show: false,
      },
    },
    grid: {
      top: 0,
      bottom: 0,
      right: 0,
      containLabel: false,
      show: false,
      left: 0,
    },
    series: [
      {
        type: 'bar',
        stack: 'x',
        data: [{ value: overdueCount, name: DeadlineTypes.Overdue }],

        emphasis: {
          focus: 'self',
        },

        itemStyle: {
          color: palette.error.dark,
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius: Boolean(dueSoonCount) || Boolean(laterCount) ? [5, 0, 0, 5] : [5, 5, 5, 5],
        },
      },
      {
        type: 'bar',
        stack: 'x',
        data: [{ value: dueSoonCount, name: DeadlineTypes.DueSoon }],

        emphasis: {
          focus: 'self',
        },
        itemStyle: {
          color: palette.warning.dark,
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius:
            Boolean(overdueCount) && Boolean(laterCount)
              ? [0, 0, 0, 0]
              : overdueCount
                ? [0, 5, 5, 0]
                : laterCount
                  ? [5, 0, 0, 5]
                  : [5, 5, 5, 5],
        },
      },
      {
        type: 'bar',
        stack: 'x',
        data: [{ value: laterCount, name: DeadlineTypes.DueLater }],

        emphasis: {
          focus: 'self',
        },
        itemStyle: {
          color: palette.grey[300],
          borderType: 'solid',
          borderWidth: 1,
          borderColor: 'white',
          borderRadius: Boolean(overdueCount) || Boolean(dueSoonCount) ? [0, 5, 5, 0] : [5, 5, 5, 5],
        },
      },
    ],
  }

  const events = useMemo(
    () => ({
      mouseover: ({ data }: { data: { name: DeadlineTypes } }) => {
        setHighlightedDeadline(data.name)
      },
      click: ({ data }: { data: { name: DeadlineTypes } }) => {
        if (data.name === DeadlineTypes.Overdue) {
          history.push(overdueLink)
        }
        if (data.name === DeadlineTypes.DueSoon) {
          history.push(dueSoonLink)
        }
        if (data.name === DeadlineTypes.DueLater) {
          history.push(dueLaterLink)
        }
      },
    }),
    [],
  )

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.title' })}
      helperText={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.helperText' }, { br })}
      action={
        !emptyState && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="primary" variant="body1">
              {formatMessage({ id: 'schemas.request.periodDisplayName' })}
            </Typography>
            <Box>
              <Button
                endIcon={<ArrowDropDownIcon />}
                size="small"
                onClick={() => setIsPeriodMenuOpen(true)}
                ref={anchorRef}
              >
                {selectedPeriod}
              </Button>
              <Menu
                open={isPeriodMenuOpen}
                anchorEl={anchorRef.current}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                elevation={1}
                onClose={() => setIsPeriodMenuOpen(false)}
              >
                <MenuItem
                  onClick={() => {
                    setIsPeriodMenuOpen(false)
                    setSelectedPeriod(ALL_PERIODS)
                  }}
                >
                  {formatMessage({ id: `dashboard.sourcing.companyStatistics.all` })}
                </MenuItem>
                {availablePeriods?.map(period => (
                  <MenuItem
                    key={formatPeriodName(period)}
                    onClick={() => {
                      setIsPeriodMenuOpen(false)
                      setSelectedPeriod(period)
                    }}
                  >
                    {period}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Stack>
        )
      }
      loading={{
        isLoading,
        skeleton: (
          <Stack width="100%">
            <Skeleton width="100%" height={50} sx={{ my: 1 }} />

            <Stack direction="row" spacing={2}>
              <Skeleton width="10%" height={32} variant="rounded" />
              <Skeleton width="10%" height={32} variant="rounded" />
              <Skeleton width="10%" height={32} variant="rounded" />
            </Stack>
          </Stack>
        ),
      }}
    >
      <>
        {emptyState ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.emptyState.title' })}
              description={formatMessage({
                id: 'dashboard.sourcing.companiesByRequestDeadline.emptyState.description',
              })}
              iconComponent={SignalCellularAltOutlinedIcon}
            />
          </Box>
        ) : noData ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.noData.title' })}
              description={formatMessage({
                id: 'dashboard.sourcing.companiesByRequestDeadline.noData.description',
              })}
              iconComponent={SignalCellularAltOutlinedIcon}
            />
          </Box>
        ) : (
          <Stack width="100%">
            <HoverDialog
              content={
                <Box p={2} onMouseEnter={() => setHighlightedDeadline(undefined)}>
                  <Stack spacing={1}>
                    <Typography variant="overline" color="textSecondary" noWrap>
                      {formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.title' })}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius={2}
                      bgcolor={highlightedDeadline === DeadlineTypes.Overdue ? 'grey.100' : 'white'}
                      sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                      onMouseEnter={() => setHighlightedDeadline(DeadlineTypes.Overdue)}
                      onMouseLeave={() => setHighlightedDeadline(undefined)}
                      onClick={() => history.push(overdueLink)}
                    >
                      <GraphLegend
                        color="error.dark"
                        variant="body1"
                        legend={formatMessage(
                          { id: 'dashboard.sourcing.companiesByRequestDeadline.overdueWithCount' },
                          { count: overdueCount },
                        )}
                      />
                      {highlightedDeadline === DeadlineTypes.Overdue && (
                        <LinkButton to={overdueLink}>{formatMessage({ id: 'dashboard.sourcing.seeAll' })}</LinkButton>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius={2}
                      bgcolor={highlightedDeadline === DeadlineTypes.DueSoon ? 'grey.100' : 'white'}
                      sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                      onMouseEnter={() => setHighlightedDeadline(DeadlineTypes.DueSoon)}
                      onMouseLeave={() => setHighlightedDeadline(undefined)}
                      onClick={() => history.push(dueSoonLink)}
                    >
                      <GraphLegend
                        color="warning.dark"
                        variant="body1"
                        legend={formatMessage(
                          { id: 'dashboard.sourcing.companiesByRequestDeadline.dueSoonWithCount' },
                          { count: dueSoonCount },
                        )}
                      />
                      {highlightedDeadline === DeadlineTypes.DueSoon && (
                        <LinkButton to={dueSoonLink}>{formatMessage({ id: 'dashboard.sourcing.seeAll' })}</LinkButton>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderRadius={2}
                      bgcolor={highlightedDeadline === DeadlineTypes.DueLater ? 'grey.100' : 'white'}
                      sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                      onMouseEnter={() => setHighlightedDeadline(DeadlineTypes.DueLater)}
                      onMouseLeave={() => setHighlightedDeadline(undefined)}
                      onClick={() => history.push(dueLaterLink)}
                    >
                      <GraphLegend
                        color="grey.300"
                        variant="body1"
                        legend={formatMessage(
                          { id: 'dashboard.sourcing.companiesByRequestDeadline.laterWithCount' },
                          { count: laterCount },
                        )}
                      />
                      {highlightedDeadline === DeadlineTypes.DueLater && (
                        <LinkButton to={dueLaterLink}>{formatMessage({ id: 'dashboard.sourcing.seeAll' })}</LinkButton>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              }
            >
              <Box width="100%" py={1}>
                <ReactEcharts option={options} onEvents={events} style={{ height: 50 }} />
              </Box>
            </HoverDialog>
            <Stack direction="row" spacing={2}>
              <GraphLegend
                color={palette.error.dark}
                legend={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.overdue' })}
                linkTo={overdueLink}
              />
              <GraphLegend
                color={palette.warning.dark}
                legend={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.dueSoon' })}
                linkTo={dueSoonLink}
              />
              <GraphLegend
                color={palette.grey[300]}
                legend={formatMessage({ id: 'dashboard.sourcing.companiesByRequestDeadline.later' })}
                linkTo={dueLaterLink}
              />
            </Stack>
          </Stack>
        )}
      </>
    </StatisticsCard>
  )
}

export default CompaniesByRequestDeadline
