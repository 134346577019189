import { NoPeriodWarning } from '@app/src/components/CreateNewRequestModal/PeriodSelector/NoPeriodWarning'
import { Button, ButtonGroup, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { COMPANY_REPORT_QUESTION_KEYS } from '../CompanyReport'
import { PeriodFields } from './PeriodFields'

export enum Periods {
  Yearly = 'Yearly',
  HalfYearly = 'HalfYearly',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  NoPeriod = 'NoPeriod',
}

export const NO_PERIOD_PERIOD_NAME = 'NoPeriod'

type PeriodSelectorProps = {
  fieldNames?: Array<COMPANY_REPORT_QUESTION_KEYS>
  defaultValues?: { dateYear?: number; dateType: string; datePeriod?: number }
}

const PeriodSelector: React.FC<PeriodSelectorProps> = ({ fieldNames, defaultValues }) => {
  const { formatMessage } = useIntl()
  const { setValue, watch } = useFormContext()

  const watchDateType: string = watch(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE)

  useEffect(() => {
    if (watchDateType !== Periods.Yearly) {
      fieldNames?.push(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD)
    } else {
      const fieldIndex = fieldNames?.indexOf(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD) ?? -1

      if (fieldIndex > -1) {
        fieldNames?.splice(fieldIndex, 1)
      }
    }
  }, [watchDateType, fieldNames])

  const resetPeriodValues = () => {
    setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR, undefined)
    setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD, undefined)
  }

  const periodOptions = [
    {
      period: Periods.Yearly,
      textId: 'form.createRequest.periodTypes.yearly',
    },
    {
      period: Periods.HalfYearly,
      textId: 'form.createRequest.periodTypes.halfYearly',
    },
    {
      period: Periods.Quarterly,
      textId: 'form.createRequest.periodTypes.quarterly',
    },
    {
      period: Periods.Monthly,
      textId: 'form.createRequest.periodTypes.monthly',
    },
    {
      period: Periods.NoPeriod,
      textId: 'form.createRequest.periodTypes.noPeriod',
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup
          sx={({ palette }) => ({
            '& .MuiButton-outlined': {
              border: `1px solid ${palette.grey[500]}`,
            },
          })}
          fullWidth
          variant="outlined"
          defaultValue={defaultValues?.datePeriod}
        >
          {periodOptions.map(op => (
            <Button
              key={op.period}
              sx={({ palette }) =>
                watchDateType === op.period
                  ? {
                      backgroundColor: palette.grey[100],
                    }
                  : {}
              }
              onClick={() => {
                setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE, op.period)
                resetPeriodValues()
              }}
            >
              {formatMessage({ id: op.textId })}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      {watchDateType !== Periods.NoPeriod ? <PeriodFields defaultValues={defaultValues} /> : <NoPeriodWarning />}
    </Grid>
  )
}

export default PeriodSelector
