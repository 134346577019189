import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAccessorQuestionGuidance from '@app/src/components/Drawer/Views/DrawerViewAccessorQuestionGuidance'
import { AssessmentRule, ResponseItem } from '@app/src/types/resourceExplorer'
import { InfoOutlined } from '@mui/icons-material'
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import AssessmentClassificationRow from './AssessmentClassificationRow'

interface AssessmentResponseItemRowProps {
  responseItem: ResponseItem
  assessmentRules: AssessmentRule[] | undefined
  maxRowsForLongAnswers: number
}

const AssessmentResponseItemRow: React.FC<AssessmentResponseItemRowProps> = ({
  responseItem,
  assessmentRules,
  maxRowsForLongAnswers,
}) => {
  const { openDrawer } = useDrawer()

  return (
    <Box display="flex" mb={3}>
      <Paper
        elevation={0}
        sx={{
          border: 1,
          borderColor: 'grey.300',
          width: '100%',
        }}
      >
        <Box px={2} py={1}>
          <Box py={1} display="flex" alignItems="center">
            <IconButton
              onClick={() =>
                responseItem.requestItem.template &&
                openDrawer(<DrawerViewAccessorQuestionGuidance question={responseItem.requestItem.template} />, {
                  anchor: 'left',
                })
              }
            >
              <InfoOutlined />
            </IconButton>
            <Typography variant="subtitle1">
              {responseItem.requestItem.template?.kpiName || responseItem.requestItem.template?.questionText}
            </Typography>
          </Box>
          <Stack>
            {responseItem.questionAnswerClassificationResults?.map(item => (
              <AssessmentClassificationRow
                key={item.id}
                responseItem={responseItem}
                questionAnswerClassificationResult={item}
                assessmentRules={assessmentRules}
                maxRowsForLongAnswers={maxRowsForLongAnswers}
              />
            )) ?? []}
          </Stack>
        </Box>
      </Paper>
    </Box>
  )
}

export default AssessmentResponseItemRow
