import { FacetItem } from '@app/src/pages/ResourceCollection'
import { SortOrder } from '@app/src/types/filter'
import { comparePeriods } from './getOrderedPeriods'

const sortDistinctPeriodLabels = (allAvailablePeriods?: FacetItem[], sortOrder?: SortOrder) =>
  allAvailablePeriods
    ?.map(availablePeriod => availablePeriod.label)
    ?.filter((label, index, self) => self.indexOf(label) === index)
    ?.sort((a, b) => comparePeriods(a, b) * (sortOrder === SortOrder.DESCENDING ? -1 : 1)) ?? []

export default sortDistinctPeriodLabels
