import { QuestionItemAnswer } from '@app/src/components/Table/Cells/AnswerWithFlagCell'
import { getSxByRank } from '@app/src/pages/QuestionEditor/AnswerClassification/ClassificationRow'
import { AssessmentRule, QuestionAnswerClassificationResult, ResponseItem } from '@app/src/types/resourceExplorer'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AssessmentClassificationRowProps {
  questionAnswerClassificationResult?: QuestionAnswerClassificationResult
  responseItem?: ResponseItem
  assessmentRules?: AssessmentRule[]
  maxRowsForLongAnswers: number
}

const AssessmentClassificationRow: React.FC<AssessmentClassificationRowProps> = ({
  questionAnswerClassificationResult,
  responseItem,
  assessmentRules,
  maxRowsForLongAnswers,
}) => {
  const { formatMessage } = useIntl()
  const matchedRule = assessmentRules?.filter(
    r => r.value === questionAnswerClassificationResult?.questionAnswerClassificationRule?.rank,
  )
  const points = matchedRule?.[0]?.points ?? 0
  const formattedPoints = `${points > 0 ? '+' : ''}${points}`

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      key={responseItem?.id}
      borderTop="1px solid"
      borderColor="divider"
      py={1.5}
      px={1.5}
    >
      <Box flexGrow={1} display="flex" alignItems="center" pr={3}>
        {responseItem && <QuestionItemAnswer item={responseItem} maxRows={maxRowsForLongAnswers} responderName="" />}
      </Box>
      <Box width="150px" flexShrink={0}>
        <Chip
          label={formatMessage({
            id: `templateBuilder.answerClassifications.classificationsRanks.${questionAnswerClassificationResult?.questionAnswerClassificationRule?.rank}`,
          })}
          size="small"
          sx={({ palette }) => ({
            ...getSxByRank(palette, questionAnswerClassificationResult?.questionAnswerClassificationRule?.rank),
            minWidth: '80px',
          })}
        />
      </Box>

      <Typography sx={{ textAlign: 'right', width: '150px', mr: '2px', flexShrink: 0 }}>
        {formatMessage({ id: 'baseAssessment.providerPage.points' }, { points: formattedPoints })}
      </Typography>
    </Stack>
  )
}

export default AssessmentClassificationRow
