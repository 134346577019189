import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Spend } from '@app/src/types/organizations'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Stack } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import Select from '../../Form/Select/ControlledSelect'
import TextField from '../../Ui/TextField'
import { useDrawer } from '../DrawerContext'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewAddAnnualSpendProps = {
  providerId: number
  periodsWithoutSpend: {
    value: number
    label: string
  }[]
  spend?: Spend
} & Omit<DrawerViewProps, 'title'>

const DrawerViewAddAnnualSpend: React.FC<DrawerViewAddAnnualSpendProps> = ({
  providerId,
  spend,
  periodsWithoutSpend,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const { closeDrawer } = useDrawer()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const { setValue, control, register, errors, handleSubmit } = useForm()
  const { mutateAsync: saveSpendAsync, isLoading: isSavingSpend } = useUpdateResource<Spend, Partial<Spend>>()

  const periodsToDisplay = [
    ...(spend ? [{ value: Number(spend.periodName), label: spend.periodName }] : []),
    ...periodsWithoutSpend,
  ].sort((a, b) => b.value - a.value)

  const isEditMode = Boolean(spend)

  const onSubmit = async (values: Spend) => {
    const body = {
      ...values,
      providerId,
      id: spend?.id,
      periodType: Periods.Yearly,
    }
    await saveSpendAsync(
      {
        url: endpoints.providerAnnualSpendSave,
        body,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({
              id: isEditMode
                ? 'schemas.supplier.annualSpend.spendEditedSuccessfully'
                : 'schemas.supplier.annualSpend.spendAddedSuccessfully',
            }),
            severity: NotificationSeverity.success,
          })
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          closeDrawer()
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  return (
    <DrawerView
      title={formatMessage({ id: 'schemas.supplier.annualSpend.spend' })}
      subTitle={spend?.periodName}
      buttons={[
        {
          label: formatMessage({ id: 'general.close' }),
          onClick: closeDrawer,
          disabled: isSavingSpend,
        },
        {
          label: formatMessage({ id: 'general.submit' }),
          variant: 'contained',
          type: 'submit',
          loading: isSavingSpend,
        },
      ]}
      stackButtons
      {...props}
      onFormSubmit={handleSubmit(onSubmit)}
    >
      <Stack p={2} spacing={3}>
        <Select
          options={periodsToDisplay}
          fieldLabel={formatMessage({ id: 'schemas.supplier.annualSpend.periodName' })}
          onInputChange={(e, value) => {
            if (!value) setValue('periodName', undefined)
          }}
          defaultValue={Number(spend?.periodName) || periodsToDisplay[0].value}
          enableAutoSelect
          name="periodName"
          control={control}
        />
        <TextField
          hoveringLabel
          inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
          fullWidth
          defaultValue={spend?.amount}
          name="amount"
          label={formatMessage({ id: 'schemas.supplier.annualSpend.amount' })}
          error={Boolean(errors?.amount)}
          type="number"
          helperText={errors?.amount?.message}
          onClear={(): void => setValue('amount', '')}
        />
      </Stack>
    </DrawerView>
  )
}

export default DrawerViewAddAnnualSpend
