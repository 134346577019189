import DirectFileUploadField from '@app/src/components/Form/DirectFileUploadField'
import ResponseItemContextProvider from '@app/src/context/ResponseItemContextProvider'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import OptionSelectWithSuggestion from './OptionSelectWithSuggestion'
import TextFieldWithSuggestions from './TextFieldWithSuggestions'

export enum PreviewMode {
  Regular = 'regular',
  SelectionDisabled = 'selectionDisabled',
}
export interface EditResponseProps {
  questionType: string
  requestItem: RequestItem
  periodName?: string
  responseItemId?: number
  previewMode?: PreviewMode
  isAnswered?: boolean
  sectionNumber: number
  itemNumber: number
}

const EditResponse = ({
  questionType,
  requestItem,
  periodName,
  responseItemId,
  previewMode,
  sectionNumber,
  itemNumber,
  isAnswered = false,
}: EditResponseProps): JSX.Element => {
  const { errors, watch, register, setValue } = useFormContext()

  const requestId = requestItem.id
  const answer = `${requestId}.answer`
  const selectedCollection = `${requestItem.id}.selectedOptions`
  const fileUpload = `${requestId}.uploadReferences`
  const cannotAnswer = `${requestId}.cannotAnswer`

  const hasError = Boolean(errors?.[`${requestItem.id}`])
  const error = hasError ? errors[`${requestItem.id}`].message : null

  const cannotAnswerWatch = watch(cannotAnswer)

  useEffect(() => {
    if (cannotAnswerWatch) {
      setValue(answer, undefined)
      setValue(fileUpload, undefined)
      setValue(selectedCollection, undefined)
    }
  }, [cannotAnswerWatch])

  return (
    <ResponseItemContextProvider
      cannotAnswer={cannotAnswerWatch}
      requestItem={requestItem}
      sectionNumber={sectionNumber}
      itemNumber={itemNumber}
    >
      <Stack pb={previewMode === PreviewMode.SelectionDisabled ? 0 : 4} spacing={1}>
        <input type="hidden" name={`${requestId}.id`} ref={register} />
        {questionType === 'options' && (
          <OptionSelectWithSuggestion
            requestItem={requestItem}
            periodName={periodName}
            disabled={cannotAnswerWatch}
            error={error}
            hasError={hasError}
            previewMode={previewMode}
          />
        )}
        {(questionType === 'text' || questionType === 'number') && (
          <TextFieldWithSuggestions
            requestItem={requestItem}
            disabled={cannotAnswerWatch || previewMode === PreviewMode.SelectionDisabled}
            hasError={hasError}
            error={error}
            isPreview={Boolean(previewMode)}
          />
        )}
        {questionType === 'file' && (responseItemId || Boolean(previewMode)) && (
          <DirectFileUploadField
            required
            requestItem={requestItem}
            name={fileUpload}
            id={requestId}
            validationError={error}
            disabled={cannotAnswerWatch || previewMode === PreviewMode.SelectionDisabled}
            sourceObjectId={responseItemId ?? 0}
            sourceObjectType="ResponseItem"
            isPreview={Boolean(previewMode)}
          />
        )}
      </Stack>
    </ResponseItemContextProvider>
  )
}

export default EditResponse
