import { Box } from '@mui/material'
import {
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonEditLink,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonUndo,
  MenuControlsContainer,
  MenuDivider,
} from 'mui-tiptap'
import React from 'react'

export default function EditorMenuControls() {
  return (
    <Box
      sx={{
        p: 0.5,
        display: 'flex',
        alignItems: 'center',
        '& button': {
          width: '36px',
          height: '36px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '20px',
        },
      }}
    >
      <MenuControlsContainer>
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonStrikethrough />
        <MenuDivider />
        <MenuButtonEditLink />
        <MenuDivider />
        <MenuButtonOrderedList />
        <MenuButtonBulletedList />
        <MenuDivider />
        <MenuButtonRemoveFormatting />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
      </MenuControlsContainer>
    </Box>
  )
}
