import { CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS } from '@app/src/components/CreateNewRequestModal/Common/SelectFilterStep'
import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import FilterDisplayChip from '@app/src/components/FilterDisplayChip'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { AutomationFormData } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { Box, Chip, Stack, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { RequestAutomationType } from '../RequestAutomationType'

interface SummarySectionProps {
  label: string
}

const SummarySection: React.FC<PropsWithChildren<SummarySectionProps>> = ({ label, children }) => (
  <Stack spacing={2}>
    <Typography variant="subtitle1">{label}</Typography>
    <Box display="flex" columnGap={1}>
      {children}
    </Box>
  </Stack>
)

const AutomationSummary: React.FC = () => {
  const { formatMessage } = useIntl()
  const { getValues } = useFormContext<AutomationFormData>()
  const data = getValues()
  const templateNames = data.templates.map(t => t.title)
  const filters = useGetApiQueryFilters(CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS)
  const { getPeriodDisplayName } = usePeriodName()

  const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
    dateType: data.dateType,
    datePeriod: data.datePeriod,
    dateYear: data.dateYear,
  })
  const periodDisplayName = getPeriodDisplayName(periodStartsAt, periodEndsAt)

  return (
    <>
      <Stack spacing={3}>
        <SummarySection label={formatMessage({ id: 'form.createAutomation.automationType.sendQuestionnaireIf' })}>
          {data.automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER ? (
            <Chip label={formatMessage({ id: 'form.createAutomation.automationType.newCompany' })} />
          ) : (
            filters.map(filter => (
              <FilterDisplayChip
                key={`${filter.name}-${filter.filters[0].value}`}
                filterName={filter.name}
                filterValue={filter.filters[0].value}
                operator={filter.filters[0].operator}
              />
            ))
          )}
        </SummarySection>
        <SummarySection label={formatMessage({ id: 'form.createAutomation.automationType.questionnaires' })}>
          {templateNames.map(template => (
            <Chip key={template} label={template} />
          ))}
        </SummarySection>
        <SummarySection label={formatMessage({ id: 'form.createAutomation.automationType.period' })}>
          <Chip label={periodDisplayName} />
        </SummarySection>

        {data.deadlineOffsetDays && (
          <SummarySection label={formatMessage({ id: 'reporting.deadline' })}>
            <Chip
              label={formatMessage(
                {
                  id:
                    data.automationType === RequestAutomationType.FOR_EVERY_NEW_PROVIDER
                      ? 'form.createAutomation.deadlineOffset.optionTextForEveryNewProvider'
                      : 'form.createAutomation.deadlineOffset.optionTextForProvidersInCategory',
                },
                { days: data.deadlineOffsetDays },
              )}
            />
          </SummarySection>
        )}
      </Stack>
    </>
  )
}
export default AutomationSummary
