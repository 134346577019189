import Table from '@app/src/components/Table'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import React from 'react'
import { CombinedFormData } from '../RequestQuestionStep'
import { calculateStartsAndEndsAtDate } from '../utils'
import ReviewTableHeader from './ReviewTableHeader'
import { ReviewTableRow } from './ReviewTableRow'

export type ReviewTableData = {
  id: string
  template: string
  period: string | number
  company: string
  deadline: string
}

type ReviewTableProps = {
  data: CombinedFormData
}

const ReviewTable: React.FC<ReviewTableProps> = ({ data }) => {
  const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
    dateType: data.dateType,
    datePeriod: data.datePeriod,
    dateYear: data.dateYear,
  })
  const { getPeriodDisplayName } = usePeriodName()

  const reviewData = data?.templateIds?.flatMap(template =>
    [data?.responders].flat().map(company => ({
      id: `${template.id}-${company.id}`,
      template: template.title,
      period: getPeriodDisplayName(periodStartsAt, periodEndsAt),
      company: company.name,
      deadline: data.deadline,
    })),
  )

  return (
    <Table<ReviewTableData>
      RowComponent={ReviewTableRow}
      HeaderComponent={() => <ReviewTableHeader companies={[data?.responders].flat()} reports={data?.templateIds} />}
      data={reviewData}
      count={reviewData?.length}
      page={1}
      pageSize={reviewData?.length}
      isLoading={false}
      isError={false}
      noPagination
    />
  )
}

export default ReviewTable
