import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { AssessmentQuestionnaireLink, AssessmentType } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'

interface useAssessmentQuestionnaireLinkProps {
  assessmentType?: AssessmentType
  assessmentTemplateId?: number
  questionnaireTemplateId?: number
  enabled?: boolean
  includeAssessment?: boolean
  includeQuestionnaire?: boolean
}

const useAssessmentQuestionnaireLink = ({
  assessmentType,
  assessmentTemplateId,
  questionnaireTemplateId,
  enabled = true,
  includeAssessment = true,
  includeQuestionnaire = true,
}: useAssessmentQuestionnaireLinkProps) => {
  const { items: assessmentQuestionnaireLinks, isLoading: isLoadingAssessmentQuestionnaireLink } =
    useFetchCollectionWithPost<AssessmentQuestionnaireLink>({
      key: FetchKey.AssessmentQuestionnaireLink,
      endpoint: endpoints.assessmentQuestionnaireLink,
      payload: {
        filter: [
          ...insertIf(Boolean(assessmentType), {
            name: 'assessmentTemplate.assessmentType',
            filters: [
              {
                operator: Operators.EqualTo,
                value: assessmentType,
              },
            ],
          }),
          ...insertIf(Boolean(assessmentTemplateId), {
            name: 'assessmentTemplateId',
            filters: [
              {
                operator: Operators.EqualTo,
                value: assessmentTemplateId,
              },
            ],
          }),
          ...insertIf(Boolean(questionnaireTemplateId), {
            name: 'questionnaireTemplateId',
            filters: [
              {
                operator: Operators.EqualTo,
                value: questionnaireTemplateId,
              },
            ],
          }),
        ],
        include: [includeAssessment && 'assessmentTemplate', includeQuestionnaire && 'questionnaireTemplate'].filter(
          Boolean,
        ) as string[],
        sort: { target: 'id', order: SortOrder.ASCENDING },
      },
      options: {
        enabled: enabled && Boolean(assessmentType || assessmentTemplateId || questionnaireTemplateId),
        staleTime: Infinity,
      },
    })

  const assessmentQuestionnaireLink = assessmentQuestionnaireLinks?.[0] || null
  return {
    assessmentQuestionnaireLink,
    assessmentQuestionnaireLinks,
    isLoadingAssessmentQuestionnaireLink,
  }
}

export default useAssessmentQuestionnaireLink
