import { useEchartsContainerWidth } from '@app/src/hooks/useEchartsContainerWidth'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { getMinMaxOnYAxisCountCharts, getVisualizationColorsMaxContrast } from '@app/src/utils/statisticsUtils'
import { darken, useTheme } from '@mui/material'
import { EChartsOption } from 'echarts'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { CartesianAxisOption } from 'echarts/types/src/coord/cartesian/AxisModel'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { OptionResultItemMultiPeriod } from '../../DataHubScene'

interface OptionMultiPeriodChartConfigsParams {
  optionItemResult: { period: string; options: OptionResultItemMultiPeriod[] }[]
  selectedChartType: ChartType
  itemsInOther: string[]
  echartsRef: React.MutableRefObject<ReactEChartsCore | null>
}

const APPROXIMATE_WIDTH_OF_YAXIS = 60

const getAxisLabelWidth = (widthOfChart: number | undefined, existingOptions: string[]) => {
  if (!widthOfChart) return undefined
  return (widthOfChart - APPROXIMATE_WIDTH_OF_YAXIS) / existingOptions.length
}

const getMinMaxYAxis = (optionItemResult: { options: OptionResultItemMultiPeriod[] }[]) => {
  const optionValues = optionItemResult.flatMap(op => op.options.map(op => op.count))
  return getMinMaxOnYAxisCountCharts(optionValues)
}

export const useOptionMultiPeriodChartConfigs = ({
  optionItemResult,
  selectedChartType,
  itemsInOther,
  echartsRef,
}: OptionMultiPeriodChartConfigsParams) => {
  const { formatMessage } = useIntl()
  const { typography } = useTheme()
  const widthOfChart = useEchartsContainerWidth(echartsRef)
  const isHorizontalBarChart = selectedChartType === ChartType.Horizontal
  const { formatPeriodName } = usePeriodName()

  const itemsForChartType = useMemo(
    () =>
      selectedChartType === ChartType.Horizontal
        ? optionItemResult.map(r => ({ ...r, options: r.options.toReversed() }))
        : optionItemResult,
    [selectedChartType, optionItemResult],
  )

  const existingOptions = itemsForChartType?.[0]?.options.map(op => op.name)

  const { min, max } = useMemo(() => getMinMaxYAxis(optionItemResult), [optionItemResult])

  const categoryAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'category',
      data: existingOptions,
      triggerEvent: true,
      axisLabel: {
        interval: 0,
        width: getAxisLabelWidth(widthOfChart, existingOptions),
        overflow: 'break',
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [existingOptions, typography.body2.fontSize, typography.fontFamily],
  )

  const valueAxis: CartesianAxisOption = useMemo(
    () => ({
      type: 'value',
      name: formatMessage({ id: 'statistics.dataInsights.count' }),
      nameLocation: 'middle',
      nameGap: 35,
      min: min,
      max: max,
      minInterval: 1,
      nameTextStyle: {
        fontFamily: typography.fontFamily,
        fontSize: typography.body2.fontSize,
      },
    }),
    [formatMessage, typography.body2.fontSize, typography.fontFamily, min, max],
  )
  const colors = getVisualizationColorsMaxContrast(itemsForChartType.length)

  const horizontalBarChartOptions: EChartsOption = useMemo(() => {
    const dataSeries: EChartsOption['series'] = itemsForChartType.map((f, seriesIndex) => ({
      name: formatPeriodName(f.period),
      type: 'bar',
      barCategoryGap: '35%',
      emphasis: { itemStyle: { color: darken(colors[seriesIndex], 0.2) } },
      data: f.options.map(op => ({
        value: op.count,
        name: op.name,
        label:
          seriesIndex === 0
            ? {
                show: true,
                position: [0, -14],
                align: 'left',
                formatter: '{b}',
                fontFamily: typography.fontFamily,
                fontSize: typography.body2.fontSize,
                overflow: 'truncate',
                width: widthOfChart ? widthOfChart : undefined,
              }
            : undefined,
        period: f.period,
        answersSelected: op.isOther ? itemsInOther : [op.name],
      })),
      label: {
        show: false,
      },
    }))

    const xAxis = valueAxis as XAXisOption
    const yAxis = { ...categoryAxis, show: false } as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: false,
        left: 15,
        right: 7,
        top: 30,
        bottom: 70,
      },
      color: colors,
      xAxis,
      yAxis,
      series: dataSeries,
    }
  }, [categoryAxis, itemsForChartType, typography.body2.fontSize, typography.fontFamily, valueAxis])

  const verticalBarChartOptions: EChartsOption = useMemo(() => {
    const dataSeries: EChartsOption['series'] = itemsForChartType.map((f, seriesIndex) => ({
      name: formatPeriodName(f?.period),
      type: 'bar',
      barCategoryGap: '35%',
      emphasis: { itemStyle: { color: darken(colors[seriesIndex], 0.2) } },
      data: f?.options.map(op => ({
        value: op.count,
        name: op.name,
        period: f.period,
        answersSelected: op.isOther ? itemsInOther : [op.name],
        label: {
          position: 'top',
        },
      })),
    }))

    const xAxis = categoryAxis as XAXisOption
    const yAxis = valueAxis as YAXisOption

    return {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        containLabel: true,
        left: 30,
        right: 7,
        top: 30,
        bottom: 20,
      },
      color: colors,
      xAxis,
      yAxis,
      series: dataSeries,
    }
  }, [categoryAxis, itemsForChartType, typography.body2.fontSize, typography.fontFamily, valueAxis])

  return isHorizontalBarChart ? horizontalBarChartOptions : verticalBarChartOptions
}
