import TruncatedText from '@app/src/components/Ui/TruncatedText'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import ChartCardMenu from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/ChartCardMenu'
import { Box, ButtonBase, Paper, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import { StatisticsForQuestionPerPeriod } from '../../DataHubScene'

interface DataHubInsightsCardProps {
  children: React.ReactNode
  statistics: StatisticsForQuestionPerPeriod
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  onClickTitle: () => void
  chartSelector: React.ReactNode
}

const DataHubCard: React.FC<DataHubInsightsCardProps> = ({
  children,
  statistics,
  eChartsRef,
  onClickTitle,
  chartSelector,
}) => {
  const { formatPeriodName } = usePeriodName()

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={({ palette }) => ({ height: '100%', padding: 2, outline: `1px solid ${palette.grey[300]}`, borderRadius: 2 })}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="start">
          <Box>
            <Box component={ButtonBase} onClick={onClickTitle}>
              <TruncatedText text={statistics.title} line={1} variant="subtitle1" />
            </Box>
            {statistics.resultForPeriods.length === 1 && (
              <Typography variant="body1" color="text.secondary">
                {formatPeriodName(statistics.resultForPeriods[0].period)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="start" gap={1}>
          {chartSelector}
          <ChartCardMenu echartsRef={eChartsRef} title={statistics.title} />
        </Box>
      </Box>
      {children}
    </Paper>
  )
}

export default DataHubCard
