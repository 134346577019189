import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { DataPoint } from '@app/src/types/resourceExplorer'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import EvaluationStatus from './EvaluationStatus'

interface EvaluationRowProps {
  row: DataPoint
}

export const EvaluationRow = ({ row: evaluation }: EvaluationRowProps): JSX.Element => {
  const { formatMessage } = useIntl()

  const isOptionsType = evaluation.indicator?.indicatorType === 'Options'
  const unit = evaluation.indicator.unit

  return (
    <TableRow>
      <CompanyCell company={evaluation.targetObject} drawer />
      <ValueCell value={evaluation.indicator?.section?.title} />
      <ValueCell value={evaluation.indicator?.description} />
      <TableCell>
        {evaluation.value ? (
          isOptionsType ? (
            <EvaluationStatus value={evaluation?.value?.toString()} />
          ) : (
            `${evaluation.value} ${unit.symbol}`
          )
        ) : (
          <>{formatMessage({ id: 'general.none' })}</>
        )}
      </TableCell>
      <PeriodNameCell periodName={evaluation.periodName ?? '-'} />
      <ValueCell value={evaluation.evidence} />
      <ValueCell value={evaluation.indicator?.section?.framework?.title} />
      <DateCell value={evaluation.createdAt} />
    </TableRow>
  )
}

export default EvaluationRow
