//TODO: type this file
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck

import { ResourceTypes } from '@app/src/wf-constants'

export default {
  navbar: '/api/resources/account',
  accessibleOrganizations: '/api/resources/user/accessibleorganization',
  resource: (solution, resourceType, resourceId) => `/api/${solution}/${resourceType}/${resourceId}`,

  // GLOBAL RESOURCES
  requestsCollection: '/api/resources/request',
  inquiriesCollection: '/api/resources/inquiry',
  inquiriesGroupedByTemplate: '/api/resources/inquiry/groupedByTemplate',
  requestsWithFacets: '/api/resources/request/facet',
  inquiryWithFacets: '/api/resources/inquiry/facet',
  inquiryCount: '/api/resources/inquiry/count',
  inquiryById: inquiryId => `/api/resources/inquiry/${inquiryId}`,
  inquiriesByIds: (inquiryId: number[]) => `/api/resources/inquiry/${inquiryId.join(',')}`,
  responsesCollection: '/api/resources/response',
  responsesWithFacets: '/api/resources/response/facet',
  requestItemsCollection: '/api/resources/templateQuestion',
  claims: '/api/resources/claim',
  pendingClaimsCollection: '/api/resources/claim/getAllPendingClaims',
  reviewClaims: '/api/resources/claim/bulkClaimUpdate',
  organizationsCollection: '/api/resources/organization',
  getMyOrganization: `/api/resources/organization/get-my-organization`,
  updateOwnOrganization: `/api/resources/organization/update-own-organization`,
  updateOwnOrganizationImage: `/api/resources/organization/update-own-organization-image`,
  potentialDuplicates: (name: string, website: string, vatNumber?: string, address?: string) => {
    const queryParams = new URLSearchParams({
      name: name,
      website: website,
      ...(vatNumber?.trim()?.length ? { vatNumber: vatNumber } : {}),
      ...(address?.trim()?.length ? { address: address } : {}),
    })
    return `/api/resources/organization/potential-duplicates?${queryParams.toString()}`
  },
  contactsCollection: '/api/resources/contact',
  contactsWithFacets: '/api/resources/contact/facet',
  saveContact: '/api/resources/contact/save',
  deleteContact: id => `/api/resources/contact/delete/${id}`,
  notifications: '/api/resources/notification',
  notificationsWithFacets: '/api/resources/notification/facet',
  markNotificationsAsSeen: '/api/resources/notification/markseen',
  user: userId => `/api/resources/user/${userId}`,
  unsetReferralCodeForUser: `/api/resources/user/unsetReferralCode`,
  setReferralCodeForUser: referralCode => `/api/resources/user/setReferralCode/${referralCode}`,
  naceCodes: '/api/resources/nacecode',
  evaluationsCollection: '/api/resources/datapoint',
  evaluationsWithFacets: '/api/resources/datapoint/facet',
  frameworks: '/api/resources/framework',
  frameworksWithFacets: '/api/resources/framework/facet',
  frameworkIndicators: '/api/resources/framework/indicator',
  frameworkIndicatorsWithFacets: '/api/resources/framework/indicator/facet',
  countries: '/api/resources/country',
  countriesWithFacets: '/api/resources/country/facet',
  riskPrioritization: '/api/resources/risk/prioritization',
  riskPrioritizationWithFacets: '/api/resources/risk/prioritization/facet',

  // REMINDERS
  sendRequestReminders: '/api/requestReminder/send-request-reminder',

  // DATAHUB
  responseItemsCollection: '/api/resources/response/items',
  responseItemsWithFacets: '/api/resources/response/items/facet',
  responseItemCount: '/api/resources/response/items/count',
  responseItemsWithStatisticsByQuestions: '/api/resources/response/items/statistics/statisticsByQuestions',
  dataInsightsByQuestions: '/api/resources/response/items/statistics/dataInsightsQuestions',

  subscriptions: '/api/resources/request/subscription',
  subscriptionsFacets: '/api/resources/request/subscription/facet',

  // PROVIDERS COLLECTIONS
  providersCollection: '/api/resources/provider',
  providersWithFacets: '/api/resources/provider/facet',
  provider: providerId => `/api/resources/provider/${providerId}`,
  providerActivity: '/api/resources/provider/activity',
  providerRiskRating: '/api/resources/provider/riskRating',
  providerActivityByType: (providerId, activityType) =>
    `/api/resources/provider/${providerId}/activity?type=${activityType}`,
  providerSpendHistoryImportFileDownload: '/api/resources/provider/bulk-update-export',
  providerSpendHistoryImport: '/api/resources/provider/bulk-update-file',
  providerAnnualSpendDelete: spendId => `/api/resources/provider/spend/${spendId}`,
  providerAnnualSpendSave: '/api/resources/provider/spend/save',

  //PRODUCTS
  productsCollection: '/api/resources/product/filter',
  productsWithFacets: '/api/resources/product/facet',
  saveProduct: '/api/resources/product',
  product: id => `/api/resources/product/${id}`,
  deleteProduct: id => `/api/resources/product/delete/${id}`,
  updateProducts: '/api/resources/product/bulkUpdate',
  mappingNodeCollection: '/api/resources/MappingNode/filter',
  mappingNodeWithFacets: '/api/resources/MappingNode/facet',
  mappingNode: '/api/resources/MappingNode',
  mappingNodeById: id => `/api/resources/MappingNode/${id}`,
  deleteMappingNode: id => `/api/resources/MappingNode/delete/${id}`,
  productMappingRequest: id => `/api/resources/ProductMappingRequest/${id}`,
  productMappingRequests: '/api/resources/ProductMappingRequest/filter',
  saveProductMappingRequest: '/api/resources/productMappingRequest',
  mappingRequestCollection: '/api/resources/ProductMappingRequest/filter',
  mappingRequestsWithFacets: '/api/resources/ProductMappingRequest/facet',
  productMappingRequestByIds: (mappingRequestIds: number[]) =>
    `/api/resources/ProductMappingRequest/${mappingRequestIds.join(',')}`,
  productMappingResponse: '/api/resources/productMappingResponse',
  productMappingResponseCollection: '/api/resources/productMappingResponse/filter',
  submitProductMappingResponse: id => `/api/resources/productMappingResponse/${id}/submit`,
  deleteProductMappingResponse: id => `/api/resources/productMappingResponse/${id}`,
  cloneProductMappingResponse: `/api/resources/productMappingResponse/clone`,
  copyValueChainFromNode: '/api/resources/MappingNode/copyValueChainFromNode',
  copyValueChainFromResponse: '/api/resources/MappingNode/copyValueChainFromResponse',
  mappingResponseConnections: id => `/api/resources/productMappingResponse/${id}/connections`,
  mappingResponseCollection: '/api/resources/ProductMappingResponse/filter',
  mappingResponseWithFacets: '/api/resources/ProductMappingResponse/facet',
  actorTypesCollection: '/api/resources/actortype/filter',
  ActorTypesWithFacets: '/api/resources/actortype/facet',

  // TRANSPARENCY/REPORTING
  requests: '/api/resources/request',
  request: (requestId, language) => `/api/resources/request/${requestId}${language ? `?language=${language}` : ''}`,
  response: (responseId, language, includePreviousCorrections) => {
    const queryParams = new URLSearchParams()
    if (language) queryParams.append('language', language)
    if (includePreviousCorrections) queryParams.append('include-previous-corrections', 'true')
    return `/api/resources/response/${responseId}?${queryParams.toString()}`
  },
  responses: '/api/resources/response',
  createResponseDraft: `/api/resources/response/createDraft`,
  updateResponseDraft: `/api/resources/response/updateDraft`,
  submitResponseDraft: `/api/resources/response/submitDraft`,
  submitResponse: `/api/resources/response/submit`,
  submitPublicReport: `/api/resources/report/submit`,
  getAccessForReport: requestId => `/api/resources/report/accessors/${requestId}`,
  getAccessForMultipleReports: requestIdsParams => `/api/resources/report/accessors?${requestIdsParams}`,
  getAccessForMultipleReports2: '/api/resources/report/accessors',
  assignAccessors: '/api/resources/report/assignaccessor',
  getOrCreateReport: '/api/resources/report/getorcreate',
  saveResponse: '/api/resources/response/save',
  verifyResponse: '/api/resources/response/verify',
  reuseResponse: (requestId, responseId) =>
    `/api/resources/response/clone?requestId=${requestId}&responseId=${responseId}`,
  reuseFile: (originalResponseItemId, newResponseItemId) =>
    `/api/resources/response/items/cloneFileItem?originalResponseItemId=${originalResponseItemId}&newResponseItemId=${newResponseItemId}`,
  verifyResponseItem: '/api/resources/response/items/verify',
  discardResponse: responseId => `/api/resources/response/delete/${responseId}`,
  accessors: '/api/resources/accessor',
  requestHistory: requestId => `/api/resources/request/history/${requestId}`,
  downloadResponse: responseId => `/api/resources/response/download/${responseId}`,

  // FILES
  fileUpload: (sourceObjectId, sourceObjectType) =>
    `/file-management/file/upload?sourceObjectId=${sourceObjectId}&sourceObjectType=${sourceObjectType}`,
  fileDownload: id => `/file-management/file/${id}`,
  exportDownload: id => `/file-management/export/${id}`,
  deleteFile: id => `/file-management/file/singleDelete/${id}`,
  filesBySourceObject: '/file-management/file/getBySourceObject',
  uploadImage: '/file-management/file/uploadimage',

  //PROVIDER COMMENTS
  providerComments: '/api/resources/provider/comment',
  providerCommentById: id => `/api/resources/provider/comment/${id}`,

  // SAVE RESOURCES
  saveResource: (solution, resourceType) => `/api/${solution}/${resourceType}/save`,
  saveResourceInBulk: resourceType => `/api/resources/${resourceType}/bulk/save`,
  password: `/api/resources/user/password`,

  // DELETE RESOURCES
  deleteResource: (solution, resourceType, resourceId) => {
    if (resourceType === ResourceTypes.Colleague || resourceType === ResourceTypes.User)
      return `/api/resources/account/solution/members/delete/${resourceId}`

    return `/api/${solution}/${resourceType}/delete/${resourceId}`
  },

  // CONFIGURATIONS
  requestTemplates: '/api/resources/request/template',
  sharedAndOwnedTemplates: '/api/resources/request/template/sharedAndOwnedTemplates',
  worldfavorTemplates: '/api/resources/request/template/worldfavortemplates',
  worldfavorTemplatesWithRecommendation: '/api/resources/request/template/worldfavortemplateswithrecommendation',
  recommendedWorldfavorTemplates: '/api/resources/request/template/recommendedworldfavortemplates',
  worldfavorAndCustomTemplates: '/api/resources/request/template/worldfavorandcustomtemplates',
  worldfavorTemplatesFacets: '/api/resources/request/template/worldfavortemplatesfacet',
  recommendedWorldfavorTemplatesFacets: '/api/resources/request/template/recommendedworldfavortemplatesfacet',
  auditLogs: '/api/utility/auditlog',
  colleagues: '/api/resources/user/colleagues',
  saveSolutionMembership: '/api/resources/account/solution/members/save',
  solutionOrganizations: '/api/resources/account/solution/organization-solution',
  invitations: '/api/resources/invitation',
  templateById: (templateId, isStandard = false) =>
    `/api/resources/request/template/${templateId}?isStandard=${isStandard}`,
  publishTemplate: templateId => `/api/resources/request/template/${templateId}/publish`,
  unpublishTemplate: templateId => `/api/resources/request/template/${templateId}/unpublish`,
  saveTemplate: '/api/resources/request/template/save',
  savePicker: '/api/resources/request/template/requestcollection/save',
  deleteTemplate: templateId => `/api/resources/request/template/delete/${templateId}`,
  units: '/api/resources/unit',
  pickers: '/api/resources/request/template/requestcollection',
  organizationOwnedTemplates: '/api/resources/request/template/organizationownedtemplates',
  userManagement: '/api/resources/useradmin',

  questionCollection: '/api/resources/question/filter',
  questionWithFacets: '/api/resources/question/facet',
  saveQuestion: '/api/resources/question',
  deleteQuestion: id => `/api/resources/question/delete/${id}`,
  questionById: questionId => `/api/resources/question/${questionId}`,

  questionCategoryOptionCollection: '/api/resources/questionCategoryOption/filter',

  // EXPORT
  exportData: resourceType => `/api/resources/${resourceType}/export`,
  queueDataExport: resourceType => `/api/resources/${resourceType}/queueExport`,
  queueResponseItemsExport: `/api/resources/response/items/queueExport`,

  // IMPORT
  importData: (solution, resourceType) => `/api/${solution}/${resourceType}/import`,

  //RISK
  providerRisk: '/api/resources/providerrisk',
  riskScreening: '/api/resources/risk-screening',
  riskScreeningHqRiskStatusFacet: '/api/resources/risk-screening/FacetHqRiskStatus',
  riskScreeningHqProviderFacet: '/api/resources/risk-screening/FacetHqProvider',
  riskScreeningAllRisks: '/api/resources/risk-screening/all-risk',
  riskScreeningAllRisksRiskStatusFacet: '/api/resources/risk-screening/all-risk/facetRiskStatus',
  riskScreeningAllRisksProviderFacet: '/api/resources/risk-screening/provider/facet',

  //Referral
  generateReferralCode: '/api/resources/referral',
  referralCode: guid => `/api/resources/referral/${guid}`,
  linkWithReferrer: '/api/resources/referral/linkWithReferrer',
  getReferralCollection: '/api/resources/referral/GetCollection',
  bulkFindOrganizations: '/api/resources/referralContact/find-if-exists',

  //Find organization
  findOrganization: query => `/api/resources/provider/find?q=${query}`,
  //Get organization
  organization: id => `/api/resources/organization/${id}`,
  transferOrganization: '/api/resources/organization/transfer',

  //Admin
  organizations: '/api/resources/organization/',
  organizationDelete: id => `/api/resources/organization/${id}`,
  assignSolution: '/api/resources/account/solution/create',

  // Provider
  saveProvider: '/api/resources/provider/save',
  updateProviders: '/api/resources/provider/bulkUpdate',
  linkOrganizationWithProvider: '/api/resources/provider/linkOrganizationWithProvider',
  suggestedContact: '/api/resources/suggestedContact',
  acceptSuggestedContact: id => `/api/resources/suggestedContact/accept/${id}`,
  rejectSuggestedContact: id => `/api/resources/suggestedContact/reject/${id}`,

  //Automated sendouts
  automations: '/api/resources/request/template/automation',
  saveAutomation: '/api/resources/request/template/automation/save',
  deleteAutomation: automationId => `/api/resources/request/template/automation/${automationId}`,
  affectedAutomationCount: '/api/resources/request/template/automation/affectedProviderCount',

  //Category
  category: '/api/resources/category',
  categoriesWithIncludes: '/api/resources/category/getWithIncludes',
  categoryGetWithIds: '/api/resources/category/getWithIds',
  categoryById: categoryId => `/api/resources/category/${categoryId}`,
  categoryOptionsByCategory: categoryId => `/api/resources/category/${categoryId}/categoryOptions`,
  categoryOption: `/api/resources/categoryOption`,
  categoryOptionById: categoryOptionId => `/api/resources/categoryOption/${categoryOptionId}`,

  //Export template
  exportTemplateCollection: '/api/resources/exporttemplate',

  //EDCI
  edciProviderConfiguration: providerId => `/edci-management/edci/configuration/provider/${providerId}`,
  saveEdciProviderConfiguration: '/edci-management/edci/configuration/provider/save',
  edciAccessorConfiguration: '/edci-management/edci/configuration/accessor',
  saveEdciAccessorConfiguration: '/edci-management/edci/configuration/accessor/save',
  publishEdciData: '/edci-management/edci/ingress',

  availableLanguageTranslation: '/api/resources/language/availablelanguagetranslations',

  //Flags
  saveReportFlag: '/api/resources/report/flag/save',
  activateReportFlag: requestItemTemplateId => `/api/resources/report/flag/activation/${requestItemTemplateId}`,
  deactivateReportFlag: requestItemTemplateId => `/api/resources/report/flag/deactivation/${requestItemTemplateId}`,
  deleteReportFlag: reportFlagId => `/api/resources/report/flag/${reportFlagId}`,

  //SuggestedRiskGuidance
  deleteSuggestions: id => `/api/resources/report/flag/suggested-risk-guidance/${id}`,
  removeSuggestedGuidanceLink: id => `/api/resources/report/flag/suggested-risk-guidance/remove-link/${id}`,

  //GoalTracker
  createGoalTracker: '/api/resources/progressgoal',
  goalTrackerCollection: '/api/resources/progressgoal/filter',
  deleteGoalTracker: id => `/api/resources/progressgoal/remove/${id}`,

  //Questionnaire statistics
  overviewStatistics: '/api/statistics/questionnaire/overview-statistics',
  requestOverviewStatisticsInProgress: '/api/statistics/questionnaire/request-progress-statistics/in-progress',
  requestOverviewStatisticsCompleted: '/api/statistics/questionnaire/request-progress-statistics/completed',
  companyOverviewStatisticsInProgress: '/api/statistics/questionnaire/company-progress-statistics/in-progress',
  companyOverviewStatisticsCompleted: '/api/statistics/questionnaire/company-progress-statistics/completed',

  // Organization settings
  organizationSettings: '/api/resources/organization/settings',
  organizationRiskSettings: '/api/resources/OrganizationRiskSetting',
  organizationAssessmentSettings: '/api/resources/OrganizationAssessment',
  reEvaluateAssessmentsByTemplate: '/api/resources/OrganizationAssessment/reEvaluateAssessmentsByTemplate',
  organizationAssessmentSettingsFacet: '/api/resources/OrganizationAssessment/facet',
  suggestedResponse: questionType => `/suggestions/${questionType}`,
  suggestionFiles: '/suggestions/files',
  suggestionFile: name => `/suggestions/files/${name}`,

  // Referral contact
  referralContactCollection: '/api/resources/referralContact/filter',
  saveReferralContact: '/api/resources/referralContact',
  sendBulkInviteToReferralContacts: '/api/resources/referralContact/send-bulk-invite',
  deleteReferralContact: id => `/api/resources/referralContact/remove/${id}`,

  // Base assessment
  assessmentCollection: '/api/resources/assessment/filter',
  assessmentFacet: '/api/resources/assessment/facet',
  assessmentQuestionnaireLink: '/api/resources/assessmentQuestionnaireLink/filter',
  assessmentTemplateCollection: '/api/resources/assessmentTemplate/filter',
  assessmentTemplate: '/api/resources/assessmentTemplate',
  deleteAssessmentTemplate: id => `/api/resources/assessmentTemplate/remove/${id}`,
  questionAnswerClassificationRule: '/api/resources/QuestionAnswerClassificationRule',
  getAssessmentTemplateRiskIndices: assessmentTemplateId =>
    `/api/resources/assessmentTemplate/GetAssessmentTemplateRiskIndices/${assessmentTemplateId}`,

  anyAccessorHasFeature: (featureToggleName: string) =>
    `/api/utility/featuretoggle/any-accessor-has-feature?featureToggle=${featureToggleName}`,
  assessmentTemplateRiskIndices: assessmentTemplateId =>
    `/api/resources/assessmentTemplate/getAssessmentTemplateRiskIndices/${assessmentTemplateId}`,
  assessmentTemplateWithClassificationRules: id => `/api/resources/assessmentTemplateWithClassificationRules/${id}`,
}
