import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import { Periods } from '@app/src/components/EvaluationsWizard/PeriodField'
import { AutomationFormData } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/CreateAutomationModal'
import { RequestAutomationType } from '@app/src/pages/Configurations/ConfigurationsPages/Automation/RequestAutomationType'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { UseFormMethods } from 'react-hook-form'

interface RequestAutomationAffectedCountQuery {
  providerFilters: FilterGroup[]
  templateIds: number[]
  periodStartsAt: string | null
  periodEndsAt: string | null
}

const useAutomationAffectedCount = (formMethods: UseFormMethods<AutomationFormData>) => {
  const { watch } = formMethods
  const { automationType, categoryFilters, templates, dateType, datePeriod, dateYear } = watch()

  const { periodStartsAt, periodEndsAt } =
    dateYear && (dateType === Periods.Yearly || datePeriod)
      ? calculateStartsAndEndsAtDate({
          dateType: dateType,
          datePeriod: datePeriod,
          dateYear: dateYear,
        })
      : { periodStartsAt: '', periodEndsAt: '' }

  const body = {
    providerFilters: categoryFilters ?? [],
    templateIds: templates?.map(t => t.id) ?? [],
    periodStartsAt,
    periodEndsAt,
  }

  const allNecessaryFieldsSet =
    automationType === RequestAutomationType.FOR_PROVIDERS_IN_CATEGORY &&
    (categoryFilters?.length ?? 0) > 0 &&
    (templates?.length ?? 0) > 0 &&
    (periodStartsAt?.length ?? 0) > 0 &&
    (periodEndsAt?.length ?? 0) > 0

  const { data: affectedCount, isLoading: isLoadingAffectedCount } = useFetchResourceWithPost<
    number,
    RequestAutomationAffectedCountQuery
  >({
    key: FetchKey.AutomationAffectedCount,
    endpoint: endpoints.affectedAutomationCount,
    body: body,
    options: {
      enabled: allNecessaryFieldsSet,
    },
  })
  return { affectedCount, isLoadingAffectedCount }
}

export default useAutomationAffectedCount
