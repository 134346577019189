import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { GenericOrganization, Organization, Provider } from '@app/src/types/organizations'
import { Country, Image, MappingRequestStatus, ResponseDraftStatus, User } from '@app/src/types/resourceExplorer'

export enum ProductSceneView {
  Edit = 'edit',
  Create = 'create',
}

export enum ProductStatus {
  NotSelected = 'NotSelected',
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ProductMappingStatus {
  NotSelected = 'NotSelected',
  FullyMapped = 'FullyMapped',
  NotMapped = 'NotMapped',
  PartlyMapped = 'PartlyMapped',
}

export enum ProductIndustry {
  Beverage = 'Beverage',
  Textile = 'Textile',
}

export interface Product {
  id: number
  name: string
  gtin: string
  providerId?: number
  provider?: Provider
  countryId: number
  country?: Country
  productNumber?: string
  productCustomId?: string
  purchaseOrder?: string
  productStatus: ProductStatus
  mappingStatus: ProductMappingStatus
  image?: Image
  mappingNodes: MappingNode[]
  deletedAt?: Date | null
  createdAt?: string
  updatedAt?: string
  industry: ProductIndustry
  updatedByUser?: User
}

export enum NodeType {
  OrganizationNode = 'OrganizationNode',
  StopNode = 'StopNode',
}

export enum ActorType {
  ProcessingProducer = 'ProcessingProducer',
  Grower = 'Grower',
  BottlingFacility = 'BottlingFacility',
  ActualProducer = 'ActualProducer',
  BrandOwner = 'BrandOwner',
  NotSet = 'NotSet',
}

export interface MappingNode {
  id: number
  name: string
  product: Product
  productId: number
  organization: GenericOrganization
  organizationId: number
  parentNodeId: number | null
  parentNode: MappingNode | null
  tier: number
  nodeType: NodeType
  actorTypeId?: number
  actorTypeModel?: ActorTypeModel
  provider: Provider
}

export interface ActorTypeModel {
  id: number
  industry: ProductIndustry
  actorTypeGroup: ActorTypeGroup
  name: string
  selectable: boolean
}

export enum ActorTypeGroup {
  None = 'None',
  Offices = 'Offices',
  FinishedProduct = 'FinishedProduct',
  Trims = 'Trims',
  FinalMaterial = 'FinalMaterial',
  MaterialProcessing = 'MaterialProcessing',
  RawMaterial = 'RawMaterial',
  WarehousingAndTransportation = 'WarehousingAndTransportation',
}

export interface ProductMappingRequest {
  id: number
  creatorOrganization: GenericOrganization
  targetProductId: number
  product: Product
  responderOrganizationId: number
  responderOrganization: GenericOrganization
  status: MappingRequestStatus
  periodType: Periods
  periodName: string
  deadline: string
  createdAt: string
  periodStartsAt: string
  periodEndsAt: string
  productMappingResponses: ProductMappingResponse[]
  deletedAt: string
  instructions?: string
}

export interface ProductMappingResponse {
  id: number
  creatorOrganization: GenericOrganization
  productId: number
  product: Product
  productMappingRequestId: number
  productMappingRequest: ProductMappingRequest
  status: ResponseDraftStatus
  submittedAt: string
  valueChainStatus: ProductMappingStatus
  mappingNodesSnapshot: MappingNode[]
  createdAt: string
  deletedAt: string
  isUpdate?: boolean
}

export interface CloneProductMappingResponsePayload {
  productMappingResponseId: number
}

export type ProductFormData = Omit<Product, 'country'>

export interface MappingResponseConnections {
  providers: Provider[]
  unconnectedOrganizations: Organization[]
}
