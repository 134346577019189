import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import Markdown from '@app/src/components/Markdown'
import { MoveAndDeleteControls } from '@app/src/pages/TemplateBuilder/MoveAndDeleteControls'
import QuestionInputPreview from '@app/src/pages/TemplateBuilder/QuestionInputPreview'
import { Question, QuestionInTemplate } from '@app/src/types/resourceExplorer'
import { HelpOutlineOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, Checkbox, FormControlLabel, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FormDataItem } from './TemplateBuilder'

type EditRequestItemProps = {
  item: Partial<FormDataItem>
  questionFromTemplate?: QuestionInTemplate
  sectionIndex: number
  requestItemIndex: number
  removeItem: () => void
  moveUp: () => void
  moveDown: () => void
  questionCount: number
}

const EditQuestionInTemplate: React.FC<EditRequestItemProps> = ({
  item,
  questionFromTemplate,
  requestItemIndex,
  sectionIndex,
  removeItem,
  moveUp,
  moveDown,
  questionCount,
}) => {
  const { register, control } = useFormContext()
  const { formatMessage } = useIntl()
  const { palette } = useTheme()

  const target = `sections[${sectionIndex}].questions[${requestItemIndex}]`

  const { data: fetchedQuestion, isFetching } = useFetchResourceWithPost<Question>({
    endpoint: endpoints.questionById(item.questionId),
    key: [FetchKey.Questions, item.questionId],
    body: ['questionType', 'unit', 'requestCollection'],
    options: {
      enabled: Boolean(item.questionId) && !questionFromTemplate,
    },
  })

  const questionToShow = questionFromTemplate ?? fetchedQuestion

  return (
    <Box py={4} border={`1px solid ${palette.grey['300']}`} borderRadius="8px" mb={4}>
      <input type="hidden" name={`${target}.questionId`} defaultValue={item?.questionId ?? ''} ref={register()} />
      <Stack data-testid="question-in-template-builder" spacing={3}>
        <Stack px={4} direction="row" justifyContent="space-between">
          {questionToShow?.guidance ? (
            <Tooltip title={<Markdown>{questionToShow.guidance.toString()}</Markdown>}>
              <Box display="flex" alignItems="center">
                <HelpOutlineOutlined fontSize="medium" />
              </Box>
            </Tooltip>
          ) : (
            <Box />
          )}
          <MoveAndDeleteControls
            onDelete={removeItem}
            moveUp={moveUp}
            moveDown={moveDown}
            index={requestItemIndex}
            totalCount={questionCount}
            typeName={formatMessage({ id: 'templateBuilder.fields.controls.type.question' })}
          />
        </Stack>
        <Typography variant="subtitle1" px={4}>
          {sectionIndex + 1}.{requestItemIndex + 1}. {questionToShow?.questionText}
        </Typography>

        {isFetching ? (
          <Box px={4}>
            <Skeleton height={60} />
          </Box>
        ) : (
          <QuestionInputPreview question={questionToShow} />
        )}

        <Stack direction="row" alignItems="center" px={4}>
          <Controller
            name={`${target}.isRequired`}
            control={control}
            defaultValue={item.isRequired ?? false}
            render={({ onChange, value }): JSX.Element => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked)}
                    color="primary"
                  />
                }
                label={formatMessage({ id: 'templateBuilder.mandatoryQuestion' })}
              />
            )}
          />
          <Tooltip title={formatMessage({ id: 'templateBuilder.mandatoryInfo' })}>
            <InfoOutlined fontSize="small" sx={{ color: 'grey.700' }} />
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  )
}

export default EditQuestionInTemplate
