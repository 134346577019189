import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import { formatDate } from '@app/src/components/Form/ControlledDateField'
import LinkButton from '@app/src/components/LinkButton'
import StatusChip from '@app/src/components/StatusChip'
import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { Operators, RESPONSE_LATEST_SUBMITTED_FILTER } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Response } from '@app/src/types/resourceExplorer'
import { getDateInDays, getDaysAgo } from '@app/src/utils'
import paths from '@app/src/wf-constants/paths'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { Box, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import StatisticsCard from './StatisticsCard'

const ResponsesCard = () => {
  const { formatRelativeTime, formatMessage } = useIntl()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const aMonthAgo = getDateInDays(-30)
  const formattedAMonthAgoDate = formatDate(aMonthAgo)?.split(' ')?.[0] ?? ''
  const { formatPeriodName } = usePeriodName()

  const { items: responses, isLoading } = useFetchCollectionWithPost<Response>({
    key: FetchKey.Response,
    endpoint: endpoints.responsesCollection,
    payload: {
      filter: [
        {
          name: 'createdAt',
          filters: [
            {
              value: formattedAMonthAgoDate,
              operator: Operators.GreaterThan,
            },
          ],
        },
        RESPONSE_LATEST_SUBMITTED_FILTER,
      ],
      sort: {
        target: 'createdAt',
        order: SortOrder.DESCENDING,
      },
      include: ['creatorOrganization', 'request.responderOrganization'],
      pagination: {
        pageNumber: 1,
        itemsPerPage: 99999,
      },
    },
  })

  const emptyState = !isLoading && !responses.length

  return (
    <StatisticsCard
      title={formatMessage({ id: 'dashboard.sourcing.responsesTitle' })}
      justifyContent={!emptyState ? 'left' : 'center'}
      helperText={formatMessage({ id: 'dashboard.sourcing.responsesHelperText' })}
      action={
        !emptyState && (
          <LinkButton
            size="small"
            to={stringifyQueryFilters({
              url: generatePath(paths.manageRequest, {
                view: ViewTypeName.Responses,
              }),
              queryParams: {
                filters: [
                  {
                    name: 'submittedAt',
                    value: formattedAMonthAgoDate,
                    operator: Operators.GreaterThan,
                    uniqueId: 'responseDateFrom',
                  },
                ],
              },
            })}
          >
            {formatMessage({ id: 'dashboard.investment.seeAll' })}
          </LinkButton>
        )
      }
      loading={{
        isLoading,
        skeleton: (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              <Skeleton width={70} variant="rounded" />
            </Typography>

            <Skeleton width={200} />
          </Stack>
        ),
      }}
    >
      <>
        {emptyState ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={2}>
            <EmptyState
              title={formatMessage({ id: 'dashboard.sourcing.responsesEmptyStateTitle' })}
              iconComponent={EmailOutlinedIcon}
              variant={EmptyStateVariant.Small}
            />
          </Box>
        ) : (
          <Stack spacing={2}>
            <Typography variant="kpi" pt={2}>
              {responses.length}
            </Typography>

            {Boolean(responses.length) && (
              <Tooltip
                title={`${responses[0]?.request.title} ${formatPeriodName(responses[0]?.request.periodName)} - ${responses[0]?.creatorOrganization.name}`}
              >
                <Box sx={{ '&:hover': { backgroundColor: 'grey.100', borderRadius: 4 } }}>
                  <StatusChip
                    transparent
                    color="info"
                    label={formatMessage(
                      { id: 'dashboard.sourcing.lastReceived' },
                      {
                        time:
                          getDaysAgo(responses[0]?.createdAt) === 0
                            ? formatMessage({ id: 'dashboard.sourcing.today' })
                            : formatRelativeTime(-getDaysAgo(responses[0]?.createdAt), 'day'),
                      },
                    )}
                  />
                </Box>
              </Tooltip>
            )}
          </Stack>
        )}
      </>
    </StatisticsCard>
  )
}

export default ResponsesCard
