import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useCreateResource, useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import DateDisplay, { useGetFormattedDate } from '@app/src/components/DateDisplay'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAddMappingNode from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewAddMappingNode'
import DrawerViewCreateProductMappingResponse from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewCreateProductMappingResponse'
import DrawerViewEditMappingNode from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewEditMappingNode'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import LoadingButton from '@app/src/components/LoadingButton'
import { Detail } from '@app/src/components/ResourceDetails/ResourceDetailsElements/Detail'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import RequestError from '@app/src/errors/RequestError'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import useActorTypes from '@app/src/hooks/useActorTypes'
import MappingResponseHistoryLog from '@app/src/pages/Product/MappingResponseHistoryLog'
import ProductHasConnectionsAlert from '@app/src/pages/Product/ProductHasConnectionsAlert'
import ValueChainView from '@app/src/pages/Product/ValueChainView'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import {
  CloneProductMappingResponsePayload,
  MappingNode,
  ProductFormData,
  ProductMappingRequest,
  ProductMappingResponse,
  ProductMappingStatus,
} from '@app/src/types/product'
import { ResponseDraftStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Add, DateRange, InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CollapseWithEllipsis from '../../components/Ui/CollapseWithEllipsis'
import ProductMappingStatusField from './ProductMappingStatusField'
import ReceivedMappingRequestSceneContainer from './ReceivedMappingRequestSceneContainer'

const ReceivedMappingRequestScene: React.FC = () => {
  const { formatMessage } = useIntl()
  const { id } = useParams<{ id?: string }>()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { openDrawer } = useDrawer()
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useDialogState()
  const formMethods = useForm<ProductFormData>()
  const { formatValueToDate } = useGetFormattedDate()
  const { handleSubmit, control, errors } = formMethods
  const options = {
    options: {
      onError: (error: RequestError) => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  }
  const { mutate: updateResponse, isLoading: isUpdatingResponse } =
    useUpdateResource<Partial<ProductMappingResponse>>(options)
  const { mutate: submitResponse, isLoading: isSubmitting } = useUpdateResource<ProductMappingResponse>(options)
  const { mutate: discardDraft, isLoading: isDiscarding } = useDeleteResource(options)

  const { mutate: createNewDraft, isLoading: isCreatingNewDraft } = useCreateResource<
    ProductMappingResponse,
    CloneProductMappingResponsePayload
  >(options)

  const {
    items: [mappingRequest],
  } = useFetchCollectionWithPost<ProductMappingRequest>({
    endpoint: endpoints.productMappingRequests,
    key: FetchKey.MappingRequestsByProduct,
    payload: {
      filter: [
        {
          name: 'id',
          filters: [
            {
              value: id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: [
        'product',
        'creatorOrganization',
        'productMappingResponses.product.mappingNodes',
        'productMappingResponses.product.updatedByUser',
        'productMappingResponses.product.mappingNodes.actorTypeModel',
        'productMappingResponses.product.mappingNodes.parentNode.actorTypeModel',
      ],
    },
    options: {
      enabled: Boolean(id),
    },
  })

  const { items: actorTypes } = useActorTypes({
    filter: [
      {
        name: 'industry',
        filters: [
          {
            value: mappingRequest?.product?.industry,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'selectable',
        filters: [
          {
            value: true,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    enabled: Boolean(mappingRequest?.product),
  })

  const sortedResponses = useMemo(
    () => mappingRequest?.productMappingResponses?.filter(r => !r.deletedAt).sort((a, b) => b.id - a.id),
    [mappingRequest],
  )
  const response = sortedResponses?.[0]
  const confirm = useConfirm()

  const { items: connectedMappingResponses } = useFetchCollectionWithPost<ProductMappingResponse>({
    endpoint: endpoints.productMappingResponseCollection,
    key: FetchKey.MappingResponseCollection,
    payload: {
      filter: [
        {
          name: 'product.id',
          filters: [
            {
              value: response?.product?.id,
              operator: Operators.EqualTo,
            },
          ],
        },
        {
          name: 'productMappingRequestId',
          filters: [
            {
              value: mappingRequest?.id,
              operator: Operators.NotEqualTo,
            },
          ],
        },
      ],
      include: ['productMappingRequest.product', 'productMappingRequest.creatorOrganization'],
    },
    options: {
      enabled: Boolean(response?.product?.id),
    },
  })

  const onSubmitResponse = (values: ProductFormData) => {
    updateResponse(
      {
        url: endpoints.productMappingResponse,
        body: { id: response?.id, valueChainStatus: values.mappingStatus },
      },
      {
        onSuccess: () => {
          submitResponse(
            {
              url: endpoints.submitProductMappingResponse(response?.id),
            },
            {
              onSuccess: () => {
                showSnackbar({
                  message: formatMessage({ id: 'schemas.mappingRequest.submitSuccessful' }),
                  severity: NotificationSeverity.success,
                })
                queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
                queryClient.invalidateQueries(FetchKey.ReceivedMappingRequests)
              },
            },
          )
        },
      },
    )

    closeConfirmationDialog()
  }

  const onDiscardDraft = () => {
    discardDraft(
      {
        url: endpoints.deleteProductMappingResponse(response?.id),
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'schemas.mappingRequest.discardSuccessful' }),
            severity: NotificationSeverity.success,
          })
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          queryClient.invalidateQueries(FetchKey.ReceivedMappingRequests)
        },
      },
    )
  }

  const onUpdateResponse = () => {
    createNewDraft(
      {
        url: endpoints.cloneProductMappingResponse,
        body: {
          productMappingResponseId: response?.id,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulDraftSave' }),
            severity: NotificationSeverity.success,
          })
          queryClient.invalidateQueries(FetchKey.MappingRequestsByProduct)
          queryClient.invalidateQueries(FetchKey.ReceivedMappingRequests)
        },
      },
    )
  }

  const { count: availableMappingNodesCount } = useFetchCollectionWithPost<MappingNode>({
    key: FetchKey.MappingNodeCollection,
    endpoint: endpoints.mappingNodeCollection,
    payload: {
      filter: [
        {
          name: 'tier',
          filters: [{ value: '0', operator: Operators.NotEqualTo }],
        },
        ...insertIf(Boolean(mappingRequest?.product?.industry), {
          name: 'product.industry',
          filters: [{ value: mappingRequest?.product?.industry, operator: Operators.EqualTo }],
        }),
      ],
      include: [],
    },
  })

  const handleAddNodeClick = async () => {
    if (!mappingRequest?.productMappingResponses?.length) {
      openDrawer(
        <DrawerViewCreateProductMappingResponse
          mappingRequest={mappingRequest}
          selectableActorTypes={actorTypes}
          hasAvailableMappingNodes={Boolean(availableMappingNodesCount)}
        />,
      )
      return
    }
    openDrawer(<DrawerViewAddMappingNode product={response.product} selectableActorTypes={actorTypes} />)
  }

  if (!mappingRequest) return null

  const responseSubmittedAt = response?.submittedAt
  const userHasAddedMappingNodes = response?.product?.mappingNodes?.length > 1
  const enableSubmitButton = !responseSubmittedAt && userHasAddedMappingNodes
  const isUpdating = isUpdatingResponse || isSubmitting || isCreatingNewDraft || isDiscarding

  return (
    <>
      <ReceivedMappingRequestSceneContainer
        onSubmitResponse={openConfirmationDialog}
        onUpdateResponse={onUpdateResponse}
        enableSubmitButton={enableSubmitButton}
        isUpdating={isUpdating}
        onDiscardDraft={onDiscardDraft}
        mappingRequest={mappingRequest}
        responseSubmittedAt={responseSubmittedAt}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={9}>
            {mappingRequest.instructions && (
              <Paper elevation={0} sx={{ my: 4, p: 4 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5">{formatMessage({ id: 'schemas.mappingRequest.instructions' })}</Typography>
                  <Button
                    startIcon={<InfoOutlined />}
                    onClick={() =>
                      confirm({
                        hideCancelButton: true,
                        confirmationText: formatMessage({ id: 'general.cancel' }),
                        confirmationButtonProps: { variant: 'text' },
                        title: formatMessage({ id: 'schemas.mappingRequest.aboutInstructions' }),
                        content: formatMessage({ id: 'schemas.mappingRequest.instructionsInfo.description' }),
                      })
                    }
                  >
                    {formatMessage({ id: 'general.info' })}
                  </Button>
                </Box>
                <CollapseWithEllipsis
                  boxProps={{
                    sx: {
                      mt: 2,
                      whiteSpace: 'pre-line',
                    },
                  }}
                  defaultVisible={false}
                >
                  {mappingRequest.instructions}
                </CollapseWithEllipsis>
              </Paper>
            )}
            <Paper elevation={0} sx={{ my: 4, p: 4 }}>
              <Typography variant="h5" mb={1}>
                {formatMessage({ id: 'schemas.mappingRequest.productDetails' })}
              </Typography>
              <Typography variant="body2" color="textSecondary" mb={2}>
                {formatMessage(
                  { id: 'schemas.mappingRequest.productStamp' },
                  {
                    creator: mappingRequest.creatorOrganization.name,
                    date: formatValueToDate({ value: mappingRequest.createdAt }),
                  },
                )}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <Detail isSecondary label={formatMessage({ id: 'schemas.product.gtin' }).toUpperCase()}>
                      <ValueCell value={mappingRequest.product.gtin} disableCell />
                    </Detail>
                    <Detail isSecondary label={formatMessage({ id: 'schemas.product.productNumber' }).toUpperCase()}>
                      <ValueCell value={mappingRequest.product.productNumber} disableCell />
                    </Detail>
                    <Detail isSecondary label={formatMessage({ id: 'schemas.product.productName' }).toUpperCase()}>
                      <Box pr={3}>
                        <ValueCell value={mappingRequest.product.name} disableCell />
                      </Box>
                    </Detail>
                    <Detail
                      isSecondary
                      label={formatMessage({ id: 'schemas.mappingRequest.mappingStatus' }).toUpperCase()}
                    >
                      <CategoryCell
                        value={formatMessage({
                          id: `schemas.product.mappingStatusValues.${mappingRequest.product.mappingStatus}`,
                        })}
                        disableCell
                      />
                    </Detail>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <Detail isSecondary label={formatMessage({ id: 'schemas.product.purchaseOrder' }).toUpperCase()}>
                      <ValueCell value={mappingRequest.product.purchaseOrder} disableCell />
                    </Detail>
                    <Detail isSecondary label={formatMessage({ id: 'schemas.mappingRequest.period' }).toUpperCase()}>
                      <PeriodNameCell periodName={mappingRequest.periodName} disableCell />
                    </Detail>
                    <Detail isSecondary label={formatMessage({ id: 'schemas.mappingRequest.deadline' }).toUpperCase()}>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <DateRange style={{ marginRight: '0.2em' }} />
                        <DateDisplay value={mappingRequest.deadline} />
                      </Box>
                    </Detail>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={0} sx={{ my: 4, p: 4 }}>
              <Box>
                {responseSubmittedAt ? (
                  <ValueChainView
                    title={
                      <Typography variant="h5">
                        {formatMessage({ id: 'schemas.mappingRequest.yourSupplyChain' })}
                      </Typography>
                    }
                    mappingNodes={response?.mappingNodesSnapshot}
                    isSnapshot={Boolean(responseSubmittedAt)}
                    responseSubmittedAt={responseSubmittedAt}
                  />
                ) : (
                  <ValueChainView
                    title={
                      <Typography variant="h5">
                        {formatMessage({ id: 'schemas.mappingRequest.yourSupplyChain' })}
                      </Typography>
                    }
                    mappingNodes={response?.product?.mappingNodes}
                    onEditNodeClick={mappingNode =>
                      openDrawer(
                        <DrawerViewEditMappingNode mappingNode={mappingNode} selectableActorTypes={actorTypes} />,
                      )
                    }
                    isSnapshot={Boolean(responseSubmittedAt)}
                    product={response?.product}
                    showConnectedProduct
                    showLastUpdate
                    connectedResponsesCount={connectedMappingResponses.length}
                  />
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                {response?.status !== ResponseDraftStatus.Submitted && (
                  <LoadingButton startIcon={<Add />} variant="contained" size="large" onClick={handleAddNodeClick}>
                    {formatMessage({ id: 'schemas.mappingNode.add' })}
                  </LoadingButton>
                )}
              </Box>
              <ProductHasConnectionsAlert
                connectedMappingResponses={connectedMappingResponses}
                product={response?.product}
              />
            </Paper>
          </Grid>
          <Grid item sm={3} mt={2} pr={4}>
            <MappingResponseHistoryLog responses={sortedResponses} />
          </Grid>
        </Grid>
      </ReceivedMappingRequestSceneContainer>
      <Dialog open={isConfirmationDialogOpen}>
        <DialogTitle>{formatMessage({ id: 'schemas.mappingRequest.confirmationDialog.title' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formatMessage({ id: 'schemas.mappingRequest.confirmationDialog.content' })}
          </DialogContentText>
          <Box mt={2}>
            <FormProvider {...formMethods}>
              <Controller
                control={control}
                name="mappingStatus"
                defaultValue={null}
                render={({ onChange, value }) => (
                  <ProductMappingStatusField
                    onChange={opt => onChange((opt as Option<ProductMappingStatus>)?.value)}
                    value={value}
                    hideNotMapped
                  />
                )}
                rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
                helperText={errors?.mappingStatus?.message}
                error={Boolean(errors?.mappingStatus)}
              />
            </FormProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeConfirmationDialog}>
            {formatMessage({ id: 'general.cancel' })}
          </Button>
          <Button variant="text" onClick={handleSubmit(onSubmitResponse)}>
            {formatMessage({ id: 'general.submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReceivedMappingRequestScene
