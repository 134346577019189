import DateDisplay from '@app/src/components/DateDisplay'

import RouterLink from '@app/src/components/RouterLink'
import CategoryCell from '@app/src/components/Table/Cells/CategoryCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import MappingRequestStatusCell from '@app/src/components/Table/Cells/MappingRequestStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { ProductMappingResponse } from '@app/src/types/product'
import paths from '@app/src/wf-constants/paths'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

interface ReceivedMappingResponseRowProps {
  row: ProductMappingResponse
}

const ReceivedMappingResponseRow: React.FC<ReceivedMappingResponseRowProps> = ({ row: response }) => {
  const { formatMessage } = useIntl()
  const mappingResponseStatus = response.status

  return (
    <TableRow
      component={RouterLink}
      to={generatePath(paths.product, {
        id: response?.productMappingRequest?.product?.id,
      })}
      underline="none"
    >
      <MappingRequestStatusCell value={mappingResponseStatus} dueDate={response?.productMappingRequest?.deadline} />
      <ValueCell value={response?.productMappingRequest?.product?.name} />
      <CompanyCell onClick={e => e.preventDefault()} drawer company={response?.creatorOrganization} />
      <TableCell>
        <DateDisplay value={String(response?.submittedAt)} />
      </TableCell>
      <CategoryCell
        value={formatMessage({
          id: `schemas.product.mappingStatusValues.${response?.valueChainStatus}`,
        })}
      />
      <PeriodNameCell periodName={response.productMappingRequest.periodName} />
    </TableRow>
  )
}

export default ReceivedMappingResponseRow
