import endpoints from '@app/src/api/endpoints'
import { useCreateResource } from '@app/src/api/updateHooks'
import DrawerView from '@app/src/components/Drawer/DrawerView'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { Organization } from '@app/src/types/organizations'
import { AssignSolution } from '@app/src/types/resourceExplorer'
import { getSolutionTitle } from '@app/src/utils'
import { br } from '@app/src/utils/translationMarkup'
import { AccountType, CreationLimits } from '@app/src/wf-constants'
import { InfoOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, FormControlLabel, FormLabel, Stack, Switch, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

const SOLUTIONS_THAT_CAN_BE_ADDED = [
  AccountType.SOURCING,
  AccountType.SOURCINGSTART,
  AccountType.INVESTMENT,
  AccountType.FINANCE,
]

interface GrantAccessToSolutionDrawerProps {
  closeDrawer: () => void
  organization: Organization
}

const GrantAccessToSolutionDrawer: React.FC<GrantAccessToSolutionDrawerProps> = ({ closeDrawer, organization }) => {
  const { control, register, handleSubmit, errors } = useForm<AssignSolution>()
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const { showSnackbar } = useSnackbar()
  const { mutateAsync, isLoading: isAssingSolutionLoading } = useCreateResource<AssignSolution>()

  const onSubmit = async (values: AssignSolution) => {
    if (organization && organization.id) {
      await mutateAsync(
        {
          url: endpoints.assignSolution,
          body: {
            organizationId: organization.id,
            accountType: values.accountType,
            isPayingAccessor: values.isPayingAccessor,
          },
        },
        {
          onSuccess: () => {
            showSnackbar({ message: 'Success!', severity: 'success' })
            closeDrawer()
          },
          onError: error => {
            showErrorNotification({ requestError: error })
          },
        },
      )
    }
  }

  return (
    <DrawerView title="Grant access to solution">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mx={3}>
          <Select<AccountType, AccountType>
            control={control}
            error={errors.accountType?.message}
            rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
            name="accountType"
            fieldLabel="Solution"
            options={SOLUTIONS_THAT_CAN_BE_ADDED.map(solution => ({
              value: solution,
              label: getSolutionTitle(solution),
            }))}
          />
          <FormControlLabel
            control={
              <Box display="flex" alignItems="center">
                <Switch color="primary" name="isPayingAccessor" defaultChecked={false} inputRef={register()} />
              </Box>
            }
            labelPlacement="end"
            label={
              <Box display="flex" alignItems="center">
                <FormLabel component="legend">Paying Accessor</FormLabel>
                <Tooltip
                  title={
                    <Box>
                      <Typography variant="body2" paragraph fontWeight="bold">
                        {formatMessage({ id: 'payingAccessorInfo.title' })}
                      </Typography>
                      <Typography variant="body2" paragraph>
                        {formatMessage(
                          { id: 'payingAccessorInfo.description' },
                          { categoryOptionLimit: CreationLimits.CategoryOptions, br },
                        )}
                      </Typography>
                      <Typography variant="body2" paragraph fontWeight="bold">
                        {formatMessage({ id: 'payingAccessorInfo.conclusionBold' })}
                      </Typography>
                      <Typography variant="body2" paragraph>
                        {formatMessage({ id: 'payingAccessorInfo.conclusion' })}
                      </Typography>
                    </Box>
                  }
                >
                  <InfoOutlined fontSize="small" sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
            }
            sx={{ mb: 1 }}
          />

          <LoadingButton
            loading={isAssingSolutionLoading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            size="large"
            fullWidth
            sx={{ mt: 2 }}
          >
            Add
          </LoadingButton>
        </Stack>
      </form>
    </DrawerView>
  )
}

export default GrantAccessToSolutionDrawer
