import { useGetFormattedDate } from '@app/src/components/DateDisplay'
import Tabs, { TabsProps } from '@app/src/components/Tabs'
import { useAccount } from '@app/src/context/AccountContext'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { reporting } from '@app/src/wf-constants/paths'
import { Chip, Skeleton, Stack, Typography } from '@mui/material'
import qs from 'qs'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { useReport } from '../ReportContext'
import ResponseStatus from './ResponseStatus'

const ReportingHeader: React.FC = () => {
  const {
    request,
    response,
    isResponseLoading,
    isRequestLoading,
    requestsPerPeriod,
    isRequestsPerPeriodLoading,
    requestId,
  } = useReport()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { formatValueToDate } = useGetFormattedDate()
  const { account } = useAccount()
  const { formatPeriodName } = usePeriodName()

  const deadlines = request?.inquiries?.filter(inq => inq.deadline).map(inq => new Date(inq?.deadline).getTime()) ?? []
  const closestDeadline = deadlines.length ? new Date(Math.min(...deadlines)).toString() : undefined
  const inquiry = response?.request?.inquiries?.find(
    inquiry => inquiry.creatorOrganizationId === account?.organization?.id,
  )

  const tabs: TabsProps['tabs'] =
    requestsPerPeriod?.map(request => ({
      label: formatPeriodName(request.periodName),
      type: request.id.toString(),
      url: `${reporting}?${qs.stringify({ requestId: request.id })}`,
    })) ?? []

  const handleTabChange = (value: string): void => {
    const selectedTab = tabs?.find(t => t.type === value)

    if (selectedTab) {
      history.push(selectedTab.url)
    }
  }

  return (
    <Stack spacing={2}>
      {isRequestLoading ? (
        <Skeleton variant="rounded" width="30%" height={40} />
      ) : (
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h2">{request?.title}</Typography>
          <ResponseStatus
            responseStatus={response?.verifications?.[0]?.externalVerificationStatus}
            draftStatus={response?.draftStatus}
            deadline={closestDeadline}
            isLoadingStatus={isResponseLoading}
            inquiryStatus={inquiry?.status}
          />
        </Stack>
      )}
      <Stack direction="row">
        <Chip sx={{ mr: 1 }} size="small" variant="outlined" label={request?.responderOrganization?.name} />
        {closestDeadline && (
          <Typography variant="body1" color="textSecondary" sx={{ ml: 1 }}>
            {formatMessage(
              { id: 'questionnaire.actions.deadline' },
              { date: formatValueToDate({ value: closestDeadline }) },
            )}
          </Typography>
        )}
      </Stack>

      {isRequestsPerPeriodLoading ? (
        <Stack direction="row" alignItems="center">
          <Skeleton variant="text" width="10%" height={48} sx={{ mr: 1 }} />
          <Skeleton variant="text" width="10%" height={48} />
        </Stack>
      ) : (
        <Tabs
          activeTab={requestId?.toString() ?? ''}
          tabs={tabs}
          handleChange={(_event, value) => handleTabChange(value)}
        />
      )}
    </Stack>
  )
}

export default ReportingHeader
