import { ProviderRiskStatus, ProviderRiskStatusType, RiskStatus } from '@app/src/types/resourceExplorer'
import { alpha, Chip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface SimpleRiskChipProps {
  riskStatus?: ProviderRiskStatusType
  disabled?: boolean
}

const getColorBasedOnRisk = (risk?: ProviderRiskStatusType): string => {
  switch (risk) {
    case ProviderRiskStatus.Low:
      return 'info.main'
    case ProviderRiskStatus.Medium:
      return 'warning.main'
    case ProviderRiskStatus.High:
      return 'error.main'
    case ProviderRiskStatus.Extreme:
      return 'semantic.error'
    default:
      return alpha('#000', 0.04)
  }
}

const SimpleRiskChip: React.FC<SimpleRiskChipProps> = ({ riskStatus, disabled }) => {
  const { formatMessage } = useIntl()

  const color = useMemo(() => (disabled ? alpha('#000', 0.04) : getColorBasedOnRisk(riskStatus)), [riskStatus])

  if (!riskStatus) {
    return (
      <Typography variant="body2" color="text.disabled">
        {formatMessage({ id: 'companyProfile.inactiveIndex' })}
      </Typography>
    )
  }

  return (
    <Chip
      label={formatMessage({ id: `schemas.risk.${riskStatus ? riskStatus : 'notAvailable'}` })}
      size="small"
      disabled={disabled}
      sx={{
        color: riskStatus === RiskStatus.Extreme && !disabled ? 'primary.contrastText' : 'primary.main',

        '& .MuiChip-label': {
          bgcolor: color,
          border: '2px solid',
          borderColor: color,
          borderRadius: 1,
        },
      }}
    />
  )
}

export default SimpleRiskChip
