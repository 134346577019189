import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets, useFetchPost } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS } from '@app/src/components/CreateNewRequestModal/Common/SelectFilterStep'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState, useMenuState } from '@app/src/hooks/mui-hooks'
import { getPeriodsToShowInStatisticsView, usePeriodName } from '@app/src/hooks/usePeriodName'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { FileUploadType } from '@app/src/types/reporting'
import { GoalDirectionType, GoalTracker } from '@app/src/types/resourceExplorer'
import { comparePeriods } from '@app/src/utils/getOrderedPeriods'
import { percentageChange } from '@app/src/utils/helpersTs'
import { NotificationSeverity, PERCENTAGE_UNIT_ID, QuestionTypes } from '@app/src/wf-constants'
import BarChartIcon from '@mui/icons-material/BarChart'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ResponseItemStatisticsByTemplateModel } from '../../ResourceCollection/Collections/DataHubScene'
import CreateGoalTrackerModal from './CreateGoalTrackerModal'
import GoalTrackerDialog, { ChartData } from './GoalTrackerDialog'

export enum ShowValueOptions {
  SUM = 'sum',
  AVG = 'avg',
}

interface GoalTrackerCardProps {
  goalTrackerItem: GoalTracker
}

interface StatisticsByQuestionsQuery {
  filters: FilterGroup[]
  targetOptionResult: string
}

function closestToTarget(target: number, num1: number, num2: number): number | 'n/a' {
  const distanceNum1 = Math.abs(target - num1)
  const distanceNum2 = Math.abs(target - num2)

  if (distanceNum1 < distanceNum2) {
    return num1
  } else if (distanceNum1 > distanceNum2) {
    return num2
  } else {
    return 'n/a'
  }
}

const getPeriodsToFetchDataFor = (requestedPeriods: FacetItem[]) => {
  const orderedRequestedPeriods = requestedPeriods?.map(facet => facet.label).sort(comparePeriods)

  const uniquePeriods = Array.from(new Set(orderedRequestedPeriods))
  return getPeriodsToShowInStatisticsView(uniquePeriods)
}

const GoalTrackerCard: React.FC<GoalTrackerCardProps> = ({ goalTrackerItem }) => {
  const { formatMessage } = useIntl()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { showErrorNotification } = useErrorNotification()
  const [isMenuOpen, menuAnchorElement, openMenu, closeMenu] = useMenuState()

  const [isDialogOpen, openDialog, closeDialog] = useDialogState(false)
  const [showValue, setShowValue] = useState<ShowValueOptions>(ShowValueOptions.SUM)

  const handleCloseDialog = () => {
    setShowValue(ShowValueOptions.SUM)
    closeDialog()
  }

  const [isCreateGoalTrackerDialogOpen, openCreateGoalTrackerDialog, closeCreateGoalTrackerDialog] =
    useDialogState(false)
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useDialogState()
  const { mutate: deleteResource, isLoading: isDeleting } = useDeleteResource()
  const { formatPeriodName } = usePeriodName()

  const {
    facets: [requestedPeriods],
    isLoading: requestedPeriodsIsLoading,
  } = useFetchFacets({
    key: [FetchKey.Inquiry, 'periods'],
    endpoint: endpoints.inquiryWithFacets,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      {
        name: 'template.sections.questions.id',
        filters: [
          {
            value: goalTrackerItem?.questionId,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
  })
  const uniquePeriods = useMemo(() => getPeriodsToFetchDataFor(requestedPeriods), [requestedPeriods])

  const mostRecentPeriod = uniquePeriods?.[uniquePeriods.length - 1]

  const requestItemTemplateIdValues = useMemo(
    () => uniquePeriods?.map(period => `${goalTrackerItem.questionId}:${period}`),
    [goalTrackerItem.questionId, uniquePeriods],
  )

  const { data: goalStatistics, isLoading: goalStatisticsIsLoading } = useFetchPost<
    ResponseItemStatisticsByTemplateModel[],
    StatisticsByQuestionsQuery
  >({
    payload: {
      filters: [
        {
          name: 'itemTemplateIdAndPeriod',
          filters: [
            {
              value: requestItemTemplateIdValues,
              operator: Operators.In,
            },
          ],
        },
        ...(goalTrackerItem?.filterCollections || []),
      ],
      targetOptionResult: goalTrackerItem?.targetReference ?? '',
    },
    endpoint: endpoints.responseItemsWithStatisticsByQuestions,
    key: [FetchKey.RequestTemplate],
    options: {
      enabled: Boolean(requestItemTemplateIdValues?.length),
    },
  })

  const isLoading = requestedPeriodsIsLoading || goalStatisticsIsLoading

  const latestPeriodStatistics = goalStatistics?.[goalStatistics.length - 1]
  const previousPeriodStatistics = goalStatistics?.[goalStatistics.length - 2]

  const questionType = latestPeriodStatistics?.questionType

  const isSimpleNumerical =
    latestPeriodStatistics?.questionType === QuestionTypes.Number &&
    latestPeriodStatistics?.unit?.quantityType !== 'Fraction' &&
    latestPeriodStatistics?.unit?.id !== PERCENTAGE_UNIT_ID

  const getPeriodStatisticValue = (periodStatistic: ResponseItemStatisticsByTemplateModel | undefined) => {
    //Question type Options
    if (questionType === QuestionTypes.Options) {
      return periodStatistic?.optionsResult?.items?.[0]?.percentage ?? 0
    }
    //Question type File
    if (questionType === QuestionTypes.File) {
      if (goalTrackerItem.targetReference === FileUploadType.FileUploaded) {
        return periodStatistic?.questionMonitoring?.companiesHaveAnswered?.percentage ?? 0
      }
      if (goalTrackerItem.targetReference === FileUploadType.FileNotUploaded) {
        return periodStatistic?.questionMonitoring?.companiesHaveNotAnswered?.percentage ?? 0
      }
    }
    //Question type Number
    if (questionType === QuestionTypes.Number) {
      if (isSimpleNumerical) {
        return periodStatistic?.numberResult?.sum ?? 0
      }
      return periodStatistic?.numberResult?.average ?? 0
    }
  }

  const latestPeriodStatisticValue = getPeriodStatisticValue(latestPeriodStatistics)
  const previousPeriodStatisticValue = getPeriodStatisticValue(previousPeriodStatistics)

  const getAllPeriodsData = (): ChartData[] => {
    //Question type Options
    if (questionType === QuestionTypes.Options) {
      return (
        goalStatistics?.map(statistic => {
          const matchGoalPercentage = statistic.optionsResult?.items?.[0]?.percentage
          const matchGoalCount = statistic.optionsResult.items[0]?.count

          return {
            value: matchGoalPercentage,
            period: statistic.periodName,
            symbol: statistic.unit?.symbol,
            matchGoalCount,
            matchGoalPercentage,
            doesNotMatchGoalCount: statistic.optionsResult.totalCount - matchGoalCount,
            doesNotMatchGoalPercentage: 100 - matchGoalPercentage,
          }
        }) ?? []
      )
    }

    //Question type File
    if (questionType === QuestionTypes.File) {
      if (goalTrackerItem.targetReference === FileUploadType.FileUploaded) {
        return (
          goalStatistics?.map(statistic => {
            const matchGoalPercentage = statistic.questionMonitoring?.companiesHaveAnswered?.percentage
            return {
              value: matchGoalPercentage,
              symbol: statistic.unit?.symbol,
              period: statistic.periodName,
              matchGoalCount: statistic.questionMonitoring?.companiesHaveAnswered?.number,
              matchGoalPercentage,
              doesNotMatchGoalCount: statistic.questionMonitoring?.companiesHaveNotAnswered?.number,
              doesNotMatchGoalPercentage: statistic.questionMonitoring?.companiesHaveNotAnswered?.percentage,
            }
          }) ?? []
        )
      }

      if (goalTrackerItem.targetReference === FileUploadType.FileNotUploaded) {
        return (
          goalStatistics?.map(statistic => {
            const matchGoalPercentage = statistic.questionMonitoring?.companiesHaveNotAnswered?.percentage

            return {
              value: matchGoalPercentage,
              symbol: statistic.unit?.symbol,
              period: statistic.periodName,
              matchGoalCount: statistic.questionMonitoring?.companiesHaveNotAnswered?.number,
              matchGoalPercentage,
              doesNotMatchGoalCount: statistic.questionMonitoring?.companiesHaveAnswered?.number,
              doesNotMatchGoalPercentage: statistic.questionMonitoring?.companiesHaveAnswered?.percentage,
            }
          }) ?? []
        )
      }
    }

    //Question type Number
    if (questionType === QuestionTypes.Number) {
      if (isSimpleNumerical) {
        return (
          goalStatistics?.map(statistic => {
            return {
              value:
                showValue === ShowValueOptions.AVG
                  ? Number(statistic.numberResult?.average?.toFixed(2)) ?? 0
                  : Number(statistic.numberResult?.sum?.toFixed(2)) ?? 0,
              period: statistic.periodName,
              symbol: statistic.unit?.symbol,
              matchGoalCount:
                statistic.questionMonitoring.companiesHaveAnswered.number !== 0
                  ? statistic.questionMonitoring.companiesHaveAnswered.number
                  : statistic.questionMonitoring.companiesHaveNotAnswered.number,
            }
          }) ?? []
        )
      }

      return (
        goalStatistics?.map(statistic => {
          return {
            value: Number(statistic.numberResult?.average?.toFixed(2)) ?? 0,
            period: statistic.periodName,
            symbol: statistic.unit?.symbol,
            matchGoalCount:
              statistic.questionMonitoring.companiesHaveAnswered.number !== 0
                ? statistic.questionMonitoring.companiesHaveAnswered.number
                : statistic.questionMonitoring.companiesHaveNotAnswered.number,
          }
        }) ?? []
      )
    }
    return []
  }

  const valueDiff = percentageChange(previousPeriodStatisticValue, latestPeriodStatisticValue)
  const isNotApplicableValueDiff = valueDiff === 'n/a'
  const goalValue = goalTrackerItem.goalValue
  const goalDirectionValue = goalTrackerItem.goalDirection

  const getTrendColor = () => {
    let closestValue: number | 'n/a'
    switch (goalDirectionValue) {
      case GoalDirectionType.Below:
        if (isNotApplicableValueDiff) return 'default'
        if (valueDiff > 0) return 'error'
        if (valueDiff < 0) return 'success'
        break

      case GoalDirectionType.Above:
        if (isNotApplicableValueDiff) return 'default'
        if (valueDiff < 0) return 'error'
        if (valueDiff > 0) return 'success'
        break

      case GoalDirectionType.Exact:
        closestValue = closestToTarget(goalValue, previousPeriodStatisticValue ?? 0, latestPeriodStatisticValue ?? 0)
        if (isNotApplicableValueDiff) return 'default'
        if (closestValue === previousPeriodStatisticValue) return 'error'
        if (closestValue === latestPeriodStatisticValue) return 'success'
        break
    }

    return 'info'
  }

  const deleteGoalTracker = (id: number) => {
    deleteResource(
      { url: endpoints.deleteGoalTracker(id) },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'schemas.goalTracker.delete.success' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries(FetchKey.GoalTracker)
          closeConfirmationDialog()
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={({ palette }) => ({
          height: '100%',
          border: `1px ${palette.grey[300]} solid`,
        })}
      >
        <Stack spacing={2} px={3} py={2} justifyContent="space-between" height="100%">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">{goalTrackerItem?.title}</Typography>
              <Box>
                <IconButton onClick={openMenu} size="small">
                  <MoreVertIcon />
                </IconButton>
              </Box>

              <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={closeMenu} elevation={8}>
                <MenuItem
                  onClick={() => {
                    openConfirmationDialog()
                    closeMenu()
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlined />
                  </ListItemIcon>
                  <Typography variant="body1">{formatMessage({ id: 'general.delete' })}</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    openCreateGoalTrackerDialog()
                    closeMenu()
                  }}
                >
                  <ListItemIcon>
                    <ModeEditOutlineOutlinedIcon />
                  </ListItemIcon>
                  <Typography variant="body1">{formatMessage({ id: 'general.edit' })}</Typography>
                </MenuItem>
              </Menu>
            </Stack>
            <Typography color="textSecondary">{goalTrackerItem?.description}</Typography>
          </Stack>
          <Stack spacing={1}>
            <Stack
              direction="row"
              spacing={1}
              color="grey.700"
              bgcolor="grey.100"
              py={0.5}
              px={1}
              borderRadius={1}
              mb={1}
            >
              <TrackChangesIcon color="inherit" />
              {Boolean(goalTrackerItem?.goalValue) && (
                <Typography>
                  {Number(goalTrackerItem?.goalValue?.toFixed(2))} {goalTrackerItem?.goalValueUnit?.symbol}
                </Typography>
              )}
            </Stack>
            <Stack>
              <Stack justifyContent="space-between" alignItems="center" direction="row">
                <Typography color="textSecondary">
                  {isLoading ? <Skeleton width={50} /> : formatPeriodName(mostRecentPeriod)}
                </Typography>
              </Stack>
              {isLoading ? (
                <Stack direction="row" alignItems="baseline" spacing={0.5}>
                  <Typography variant="kpi">
                    <Skeleton width={40} variant="rounded" />
                  </Typography>
                  <Typography variant="subtitle1">
                    <Skeleton width={20} variant="rounded" />
                  </Typography>
                </Stack>
              ) : latestPeriodStatistics ? (
                <Stack direction="row" alignItems="baseline" spacing={0.5}>
                  <Typography variant="kpi">{Number(latestPeriodStatisticValue?.toFixed(2))}</Typography>
                  <Typography variant="subtitle1">{goalTrackerItem?.goalValueUnit?.symbol}</Typography>
                </Stack>
              ) : (
                <Tooltip title={formatMessage({ id: 'schemas.goalTracker.noDataYetHelperText' })}>
                  <Box>
                    <Typography variant="kpi">{formatMessage({ id: 'schemas.goalTracker.noDataYet' })}</Typography>
                  </Box>
                </Tooltip>
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Tooltip arrow title={formatMessage({ id: 'schemas.goalTracker.trendHelpText' })}>
                <Box>
                  <Chip
                    disabled={isNotApplicableValueDiff}
                    color={getTrendColor()}
                    label={
                      isNotApplicableValueDiff
                        ? 'N/A'
                        : formatMessage(
                            { id: 'schemas.goalTracker.goalCard.weeklyTrendingPercentage' },
                            {
                              totalTrendingPercentage: Math.ceil(valueDiff),
                            },
                          )
                    }
                    icon={
                      isNotApplicableValueDiff || valueDiff > 0 ? (
                        <TrendingUpIcon sx={{ fontSize: 16 }} />
                      ) : valueDiff < 0 ? (
                        <TrendingDownIcon sx={{ fontSize: 16 }} />
                      ) : (
                        <TrendingFlatIcon sx={{ fontSize: 16 }} />
                      )
                    }
                  />
                </Box>
              </Tooltip>
              <Button startIcon={<BarChartIcon />} onClick={openDialog} disabled={!latestPeriodStatistics}>
                {formatMessage({ id: 'general.showTrend' })}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <Dialog open={isConfirmationDialogOpen} maxWidth="sm">
        <DialogTitle>{formatMessage({ id: 'schemas.goalTracker.delete.title' })}</DialogTitle>
        <DialogContent>{formatMessage({ id: 'schemas.goalTracker.delete.description' }, { br: <br /> })}</DialogContent>

        <DialogActions>
          <Button onClick={closeConfirmationDialog}>{formatMessage({ id: 'general.cancel' })}</Button>
          <Button color="error" disabled={isDeleting} onClick={() => deleteGoalTracker(goalTrackerItem.id)}>
            {formatMessage({ id: 'general.delete' })}
          </Button>
        </DialogActions>
      </Dialog>
      <CreationModalProgressContextProvider>
        <Filters allowedFilters={CATEGORY_FILTER_DRAWER_ALLOWED_FILTERS}>
          {() => (
            <CreateGoalTrackerModal
              open={isCreateGoalTrackerDialogOpen}
              onClose={closeCreateGoalTrackerDialog}
              goalTrackerItem={goalTrackerItem}
            />
          )}
        </Filters>
      </CreationModalProgressContextProvider>
      <GoalTrackerDialog
        isOpen={isDialogOpen}
        goalTrackerItem={goalTrackerItem}
        onClose={handleCloseDialog}
        periods={uniquePeriods}
        questionType={latestPeriodStatistics?.questionType || ''}
        isSimpleNumerical={isSimpleNumerical}
        showValue={showValue}
        setShowValue={setShowValue}
        chartData={getAllPeriodsData()}
        onEdit={() => {
          openCreateGoalTrackerDialog()
          handleCloseDialog()
        }}
      />
    </>
  )
}

export default GoalTrackerCard
