import { useStringifyQueryFilters } from '@app/src/hooks/queryState'
import { convertValueToUnit, useDisplayValueWithUnit } from '@app/src/hooks/useDisplayValueWithUnit'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import {
  DataHubView,
  ResponseItemStatisticsByTemplateModel,
} from '@app/src/pages/ResourceCollection/Collections/DataHubScene'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import paths from '@app/src/wf-constants/paths'
import { Box, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useMemo } from 'react'
import { generatePath, useHistory } from 'react-router'
import LineChart from './LineChart'

interface OverTimeLineChartProps {
  groupedStatistics: ResponseItemStatisticsByTemplateModel[]
  organizationId?: number
}

interface HighlightedValueData {
  periodName: string
  value?: number
  name: string
  symbol: string | null
  id: number
}

enum ShowValueOptions {
  SUM = 'sum',
  AVG = 'avg',
}

const OverTimeLineChart: React.FC<OverTimeLineChartProps> = ({ groupedStatistics, organizationId }) => {
  const history = useHistory()
  const { spacing } = useTheme()
  const { stringifyQueryFilters } = useStringifyQueryFilters()
  const { getOptimalUnitForMultipleValues, isLoadingUnits } = useDisplayValueWithUnit()
  const { formatPeriodName } = usePeriodName()
  const showSum =
    groupedStatistics?.[0]?.unit?.quantityType?.toLowerCase() !== 'fraction' &&
    groupedStatistics?.[0]?.unit?.name?.toLowerCase() !== 'percentage'
  const defaultShowValue = showSum ? ShowValueOptions.SUM : ShowValueOptions.AVG

  const showAverage = defaultShowValue === ShowValueOptions.AVG

  const totalValues =
    groupedStatistics?.map(g => (showAverage ? g.numberResult?.average ?? 0 : g.numberResult?.sum ?? 0)) ?? []
  const unitToUse = getOptimalUnitForMultipleValues(totalValues, groupedStatistics?.[0]?.unit)

  const lineChartData = groupedStatistics?.map(data => {
    const valueToDisplay = showAverage ? data?.numberResult?.average : data?.numberResult?.sum
    const convertedValue = valueToDisplay ? convertValueToUnit(valueToDisplay, data.unit, unitToUse) : undefined

    return {
      periodName: formatPeriodName(data?.periodName),
      value: convertedValue,
      name: '',
      symbol: unitToUse?.symbol ?? null,
      id: data?.id,
    }
  })
  const isTransparencyProviderProgressView = !organizationId
  const events = useMemo(
    () => ({
      click: ({ data }: { data: HighlightedValueData }) => {
        if (isTransparencyProviderProgressView) return
        const filters = [
          {
            name: 'response.request.periodName',
            value: [data?.periodName],
            operator: Operators.In,
          },
          {
            name: 'requestItem.template.id',
            value: [data?.id],
            operator: Operators.In,
          },
        ]

        history.push(
          stringifyQueryFilters({
            url: generatePath(paths.dataHubCollection, {
              view: DataHubView.Answers,
            }),
            queryParams: {
              filters,
            },
          }),
        )
      },
    }),
    [],
  )

  if (isLoadingUnits) return <CircularProgress />

  return (
    <Box width="100%">
      <LineChart
        data={lineChartData}
        events={events}
        xAxisData={groupedStatistics.map(item => ({
          value: item.periodName,
          companiesAnswered:
            item.questionMonitoring.companiesHaveAnswered.number +
            item.questionMonitoring.companiesHaveNotAnswered.number,
        }))}
        seriesLabel={{
          show: true,
          position: 'top',
          distance: 5,
          fontWeight: 700,
          formatter: (params: { value: string | number }) => {
            return `${params.value} ${lineChartData?.[0]?.symbol ?? ''}`
          },
        }}
        showMarkLine={false}
        grid={{
          left: `${spacing(4)}px`,
          right: `${spacing(4)}px`,
          top: `${spacing(3)}px`,
          bottom: `${spacing(8)}px`,
          containLabel: true,
        }}
        symbol={unitToUse?.symbol}
        isProviderProgressView
      />
    </Box>
  )
}

export default OverTimeLineChart
