import ActionButtons from '@app/src/components/ActionButtons'
import LoadingButton from '@app/src/components/LoadingButton'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CreationModalStepProps } from './CreationModalStep'

type CreationModalStepSplitViewProps = {
  isLastStep: boolean
  canGoNext: boolean
  handleBack: () => void
} & Omit<CreationModalStepProps, 'onBack' | 'widthOverride' | 'informationAlert' | 'canGoNext'>

const CreationModalStepSplitView: React.FC<CreationModalStepSplitViewProps> = ({
  title,
  fieldnames,
  description,
  children,
  hasSkipButton,
  disableInitialBack = false,
  overrideButtonTextId,
  isLoading = false,
  hideNextButton,
  extraButtons,
  nextButtonProps,
  splitView,
  canGoNext,
  handleBack,
  isLastStep,
}) => {
  const { formatMessage } = useIntl()
  const { activeStep, totalSteps, setActiveStep, enableEmptyState } = useCreationModalProgress()
  const { setValue } = useFormContext()

  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }} flexGrow={1} width="100%">
      <Stack flexGrow={1} bgcolor="grey.50">
        {splitView?.component}
      </Stack>
      <Box width={{ md: 576 }} px={4} py={6}>
        {!enableEmptyState && (
          <>
            <Typography variant="h1" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="span">
              {description}
            </Typography>
          </>
        )}
        <Box pt={4}>{children}</Box>

        <Stack direction="row-reverse" justifyContent="space-between" gap={2} pt={6}>
          {extraButtons && <ActionButtons buttons={extraButtons} />}

          {isLastStep ? (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              endIcon={!isLastStep && <ArrowForward />}
              type="submit"
              disabled={!canGoNext}
              fullWidth
              {...nextButtonProps}
            >
              {formatMessage({ id: overrideButtonTextId ?? `form.createRequest.submit` })}
            </LoadingButton>
          ) : (
            <Box display="flex" gap={1}>
              {hasSkipButton && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    fieldnames.forEach(fieldName => {
                      setValue(fieldName, undefined)
                    })
                    setActiveStep(currentValue => currentValue + 1)
                  }}
                  fullWidth
                >
                  {formatMessage({ id: 'general.skip' })}
                </Button>
              )}

              {!enableEmptyState && !hideNextButton && (
                <Button
                  variant="contained"
                  endIcon={!isLastStep && <ArrowForward />}
                  disabled={!canGoNext}
                  onClick={() => setActiveStep(currentValue => currentValue + 1)}
                  {...nextButtonProps}
                >
                  {formatMessage({
                    id: overrideButtonTextId ?? (activeStep === totalSteps ? 'general.reviewAndSend' : 'general.next'),
                  })}
                </Button>
              )}
            </Box>
          )}

          {disableInitialBack && activeStep === 1 ? (
            <Box />
          ) : (
            <Box>
              <Button onClick={handleBack} startIcon={<ArrowBack />}>
                {formatMessage({ id: 'general.back' })}
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default CreationModalStepSplitView
