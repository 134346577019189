import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets, useInfiniteFetchCollection } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import useErrorNotification from '@app/src/hooks/errorNotification'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ActivationType } from '@app/src/types/flags'
import { Organization } from '@app/src/types/organizations'
import { Product, ProductMappingRequest } from '@app/src/types/product'
import {
  InquiryStatus,
  QuestionnaireTemplate,
  QuestionnaireTypeEnum,
  Request,
  RequestItem,
  UploadReference,
} from '@app/src/types/resourceExplorer'
import { Box } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import useDeepCompareEffect from 'use-deep-compare-effect'
import NotificationList from './NotificationsList'
import { refineNotification } from './util'

export type NotificationItem = {
  id?: number
  service: string
  sourceEventType: string
  objectTitle: string
  seen: boolean
  createdAt: string
  requestId: number
  responseId?: string
  days?: number
  role?: string
  solutionType?: string
  url?: string
  originalFileName?: string
  providerId?: number
  providerName?: string
  providerType?: string
  creatorOrganizationName?: string
  templateId?: number
  periodStartsAt?: string
  periodEndsAt?: string
  targetObjectId?: number
  activationType?: ActivationType
  creatorOrganizationId?: number
  productName?: string
  productId?: number
  requestName?: string
  successCount?: number
  errorCount?: number
  oldTotalLevel?: number
  newTotalLevel?: number
  oldPercentage?: number
  percentage?: number
  oldIntervalType?: string
  intervalType?: string
  assessmentTemplateName?: string
  inquiryStatus?: InquiryStatus
  questionnaireTemplateType?: QuestionnaireTypeEnum
  templateIsStandard?: boolean
  periodName?: number
  assessmentTemplateId?: number
}

export type RawNotificationItem = {
  createdAt: string
  creatorUserId: number
  details: {
    type?: string
    title: string
    id: string
    dueAt: string
    deadline: string
    request?: Request
    targetRequestId: string
    name: string
    role: string
    solutionType: string
    originalFileName: string
    url: string
    questionnaireTemplateId?: number
    template: QuestionnaireTemplate
    requestTemplateItem: RequestItem
    periodStartsAt?: string
    periodEndsAt?: string
    requestId: number
    activationType?: string
    product: Product
    productMappingRequest: ProductMappingRequest
    requestName?: string
    providerName?: string
    successCount?: number
    errorCount?: number
    outputFile?: UploadReference
    providerId?: number
    oldTotalLevel?: number
    totalLevel?: number
    oldPercentage?: number
    percentage?: number
    oldIntervalType?: string
    intervalType?: string
    assessmentTemplateName?: string
    periodName?: number
    assessmentTemplateId?: number
    status: InquiryStatus
  }
  id: number
  seen: boolean
  seenAt: string
  serviceType: string
  sourceEventType: string
  targetObjectId: number
  targetObjectType: string
  type: string
  creatorOrganization: Organization
  creatorOrganizationId: number
}

const NotificationsScene = (): JSX.Element => {
  const { showErrorNotification } = useErrorNotification()

  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const { sorting } = useSort()
  const [page, pageSize, setPage] = usePagination({ page: 1, pageSize: 10 })

  const [notifications, setNotifications] = useState<NotificationItem[]>([])
  const { mutate, isLoading: isMutateLoading } = useUpdateResource()

  const markNotificationsAsSeen = async (notificationItems: NotificationItem[]): Promise<void> => {
    const unseenNotificationIds = notificationItems
      .filter(notification => !notification.seen)
      ?.map(notification => notification.id)

    if (unseenNotificationIds.length) {
      await mutate(
        {
          url: endpoints.markNotificationsAsSeen,
          body: {
            filter: [
              {
                name: 'id',
                filters: [
                  {
                    value: unseenNotificationIds,
                    operator: Operators.In,
                  },
                ],
              },
            ],
            pagination: {
              pageNumber: 0,
              itemsPerPage: 0,
            },
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([FetchKey.Notification, FetchKey.NotificationFacets])
            await queryClient.invalidateQueries(FetchKey.AccessibleOrganizations)
          },
        },
      )
    }
  }

  const markAllNotificationsAsSeen = async (): Promise<void> => {
    await mutate(
      {
        url: endpoints.markNotificationsAsSeen,
        body: {
          filter: [{ name: 'seen', filters: [{ value: false, operator: Operators.EqualTo }] }],
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([FetchKey.Notification, FetchKey.NotificationFacets])
          await queryClient.invalidateQueries(FetchKey.AccessibleOrganizations)
          await queryClient.invalidateQueries(FetchKey.Notification)
        },
      },
    )
  }

  const { data, fetchNextPage, hasNextPage, isFetching, error, isLoading, remove } =
    useInfiniteFetchCollection<RawNotificationItem>({
      key: FetchKey.Notification,
      endpoint: endpoints.notifications,
      payload: {
        filter: [],
        sort: sorting,
        include: ['creatorOrganization'],
        pagination: {
          pageNumber: page,
          itemsPerPage: pageSize,
        },
      },
    })

  const {
    facets: [unseenNotifications],
  } = useFetchFacets({
    endpoint: endpoints.notificationsWithFacets,
    filter: [{ name: 'seen', filters: [{ operator: Operators.EqualTo, value: false }] }],
    facetsParam: [{ name: 'seen', isEnum: true }],
    key: [FetchKey.Notification, FetchKey.NotificationFacets],
  })

  const userHasUnseenNotifications = unseenNotifications?.[0]?.count > 0

  const items: RawNotificationItem[] = useMemo(
    () => data?.pages.flatMap(page => page.items).filter(item => item) ?? [],
    [data],
  )

  useEffect(() => {
    if (page === 1 && (data?.pages?.length ?? 0) > 1) remove()
  }, [page, data?.pages?.length])

  const loadMore = (): void => {
    fetchNextPage({ pageParam: page + 1 })
    setPage(page + 1)
  }

  const setNotificationsAndMarkAsSeen = (): void => {
    if (!error) {
      const notificationItems = items?.map((rawNotification: RawNotificationItem) =>
        refineNotification(rawNotification),
      )
      setNotifications(notificationItems)
      markNotificationsAsSeen(notificationItems)
    } else {
      showErrorNotification({ requestError: error })
    }
  }

  useDeepCompareEffect(() => {
    setNotificationsAndMarkAsSeen()
  }, [items])

  return (
    <ResourceCollectionScene
      title={formatMessage({ id: 'resourceTypes.notification' })}
      actionButtons={[
        {
          label: formatMessage({ id: 'schemas.notification.markAllAsSeen' }),
          variant: 'contained',
          onClick: () => markAllNotificationsAsSeen(),
          disabled: isLoading || isFetching || !userHasUnseenNotifications,
          loading: isMutateLoading,
        },
      ]}
      enableScroll
    >
      <Box pr={5} pt={2}>
        <NotificationList
          loading={isFetching}
          notifications={notifications}
          canLoadMore={Boolean(hasNextPage)}
          loadMore={loadMore}
          showLoadMore={Boolean(hasNextPage)}
        />
      </Box>
    </ResourceCollectionScene>
  )
}

export default NotificationsScene
