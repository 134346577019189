import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import RiskIndexInfoDrawerView from '@app/src/components/Drawer/Views/RiskIndexInfoDrawerView'
import { SortableHeaderProps } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { AssessmentTemplate, RiskType } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type AssessmentDialogHeaderProps = {
  assessmentTemplate: AssessmentTemplate
  riskType?: RiskType
} & SortableHeaderProps

const AssessmentDialogHeader: React.FC<AssessmentDialogHeaderProps> = ({
  assessmentTemplate,
  toggleSorting,
  activeSorting,
  riskType,
}): JSX.Element => {
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  if (riskType)
    return (
      <TableRow>
        <TableHeaderCell
          label={formatMessage({ id: 'resourceCollections.general.company' })}
          sorting={{ name: 'provider.name', toggleSorting, activeSorting }}
        />
        <TableHeaderCell label={assessmentTemplate.name + ' assessment'} />
        <TableHeaderCell label={formatMessage({ id: 'resourceCollections.general.country' })} />
        <TableHeaderCell
          label={riskType.name}
          onInfoClick={() => {
            openDrawer(<RiskIndexInfoDrawerView riskType={riskType} />, { showAboveDialogs: true })
          }}
        />
      </TableRow>
    )

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.company' })}
        sorting={{ name: 'provider.name', toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.period' })} />
      <TableHeaderCell label={assessmentTemplate.name} />
      <TableHeaderCell label={formatMessage({ id: 'assessments.evaluated' })} />
    </TableRow>
  )
}

export default AssessmentDialogHeader
