import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import FileUploadField from '@app/src/components/FileUploadField'
import LoadingButton from '@app/src/components/LoadingButton'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import RequestError from '@app/src/errors/RequestError'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { ImportTemplates } from '@app/src/wf-constants'
import { FileDownloadOutlined } from '@mui/icons-material'
import { Alert, Box, Button, Dialog, DialogContent, Divider, LinearProgress, Stack, Typography } from '@mui/material'
import { capitalize } from 'lodash'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import ImportProviderSummary from './ImportProviderSummary'

type ImportProvidersModalProps = {
  open: boolean
  onClose: () => void
}

export type UploadImportFileResoponse = {
  successCount: number
  errorCount: number
}

const ImportProvidersModal: React.FC<ImportProvidersModalProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl()
  const resourceType = useCurrentProviderType()
  const { solution } = useAuthentication().scope
  const { showErrorNotification } = useErrorNotification()
  const { closeDrawer } = useDrawer()
  const queryClient = useQueryClient()
  const { mutate: uploadImportFile, isLoading } = useCreateResource<UploadImportFileResoponse, FormData>()
  const [importResult, setimportResult] = useState<UploadImportFileResoponse | undefined>()
  const [importFile, setImportFile] = useState<string | Blob>('')

  const handleTemplateDownload = (): Window | null => window.open(ImportTemplates[resourceType], '_blank')

  const handleImport = async (): Promise<void> => {
    const data = new FormData()
    data.append('file', importFile)

    uploadImportFile(
      { url: endpoints.importData(solution, resourceType), body: data },
      {
        onSuccess: ({ successCount = 0, errorCount = 0 }) => {
          setimportResult({ successCount, errorCount })
          queryClient.invalidateQueries(FetchKey.ProviderCollection)
          closeDrawer()
        },
        onError: e => {
          showErrorNotification({ requestError: e as RequestError })
        },
      },
    )
  }

  const handleCloseImportDialog = (): void => {
    setimportResult(undefined)
    setImportFile('')
    onClose()
  }

  const isResultNoNewEntries = importResult?.successCount === 0 && importResult?.errorCount === 0

  return (
    <Dialog open={open} onClose={handleCloseImportDialog} fullScreen closeAfterTransition>
      <DialogContent sx={{ padding: '0px 0px' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" px={4} pt={2} mb={2}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              {formatMessage(
                { id: importResult ? 'import.summary.title' : 'import.provider' },
                {
                  provider: formatMessage(
                    {
                      id: `general.${capitalize(resourceType)}`,
                    },
                    { count: 2 },
                  ).toLowerCase(),
                },
              )}
            </Typography>
          </Box>

          <Button variant="text" onClick={handleCloseImportDialog}>
            {formatMessage({ id: importResult ? 'general.close' : 'general.cancel' })}
          </Button>
        </Box>

        {importResult ? <Divider /> : <LinearProgress variant="determinate" value={10} />}

        <Stack flexGrow={1} alignItems="center">
          <Box display="flex" flexDirection="column" justifyContent="center" width={680} py={6}>
            <Stack spacing={2} pb={4}>
              <Typography variant="h1" gutterBottom>
                {importResult
                  ? isResultNoNewEntries
                    ? formatMessage({ id: 'import.summary.noNewEntriesTitle' })
                    : formatMessage({ id: 'import.summary.title' })
                  : formatMessage(
                      { id: 'import.provider' },
                      {
                        provider: formatMessage(
                          {
                            id: `general.${capitalize(resourceType)}`,
                          },
                          { count: 2 },
                        ).toLowerCase(),
                      },
                    )}
              </Typography>
            </Stack>
            {importResult ? (
              <ImportProviderSummary importResult={importResult} onClose={handleCloseImportDialog} />
            ) : (
              <Stack p={2} spacing={5}>
                <Stack>
                  <Typography variant="subtitle1">{formatMessage({ id: 'import.stepOne' })}</Typography>
                  <Typography gutterBottom variant="body1" color="textSecondary" component="span">
                    {formatMessage({ id: 'import.stepOneDescription' })}
                  </Typography>
                  <Box mt={2}>
                    <Button
                      startIcon={<FileDownloadOutlined />}
                      variant="outlined"
                      onClick={handleTemplateDownload}
                      disabled={isLoading}
                    >
                      {formatMessage({ id: 'import.templateDownload' })}
                    </Button>
                  </Box>
                </Stack>
                <Divider sx={{ my: 4 }} />
                <Stack>
                  <Typography variant="subtitle1">{formatMessage({ id: 'import.stepTwo' })}</Typography>
                  <Typography gutterBottom variant="body1" color="textSecondary" component="span">
                    {formatMessage({ id: 'import.stepTwoDescription' })}
                  </Typography>
                  <Box mt={2}>
                    <Stack width={488}>
                      <Alert
                        variant="filled"
                        severity="info"
                        sx={{ backgroundColor: 'common.white', alignItems: 'flex-start' }}
                      >
                        {formatMessage({ id: 'import.stepTwoInfo' })}
                      </Alert>
                    </Stack>
                  </Box>
                </Stack>
                <Divider sx={{ my: 4 }} />
                <Stack>
                  <Typography variant="subtitle1">{formatMessage({ id: 'import.stepThree' })}</Typography>
                  <Typography color="textSecondary" variant="body1">
                    {formatMessage({ id: 'import.stepThreeDescription' })}
                  </Typography>
                  <Box mt={2}>
                    <FileUploadField setFile={setImportFile} />
                  </Box>
                </Stack>
                <Box display="flex" justifyContent="flex-end" pt={4}>
                  <LoadingButton loading={isLoading} variant="contained" onClick={handleImport} disabled={!importFile}>
                    {formatMessage({ id: 'import.import' })}
                  </LoadingButton>
                </Box>
              </Stack>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ImportProvidersModal
