import { Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { getMonthsLastDay } from '@app/src/utils'

type StartAndEndDateProps = {
  dateType: string
  dateYear?: string | null
  datePeriod?: string | number | null
}

export const calculateStartsAndEndsAtDate = ({
  dateType,
  datePeriod,
  dateYear,
}: StartAndEndDateProps): { periodStartsAt: string | null; periodEndsAt: string | null } => {
  if (dateType === Periods.NoPeriod) return { periodStartsAt: null, periodEndsAt: null }
  if (dateType === Periods.Yearly) {
    return {
      periodStartsAt: dateYear ? `${dateYear}-01-01 00:00:00` : '',
      periodEndsAt: dateYear ? `${dateYear}-12-31 23:59:59` : '',
    }
  } else if (dateType === Periods.Quarterly || dateType === Periods.HalfYearly) {
    const length = dateType === Periods.Quarterly ? 3 : 6
    let startMonth = String(Number(datePeriod) * length + 1)
    let endMonth = String(Number(datePeriod) * length + length)

    const endDay = getMonthsLastDay(dateYear, endMonth)

    if (startMonth?.length < 2) startMonth = '0' + startMonth
    if (endMonth?.length < 2) endMonth = '0' + endMonth

    return {
      periodStartsAt: `${dateYear}-${startMonth}-01 00:00:00`,
      periodEndsAt: `${dateYear}-${endMonth}-${endDay} 23:59:59`,
    }
  } else if (dateType === Periods.Monthly) {
    const endDay = getMonthsLastDay(dateYear, datePeriod)
    const month = String(datePeriod).length < 2 ? `0${datePeriod}` : datePeriod

    return {
      periodStartsAt: `${dateYear}-${month}-01 00:00:00`,
      periodEndsAt: `${dateYear}-${month}-${endDay} 23:59:59`,
    }
  }

  return {
    periodStartsAt: '',
    periodEndsAt: '',
  }
}
