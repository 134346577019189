import {
  AssessmentRule,
  AssessmentRuleType,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
} from '@app/src/types/resourceExplorer'
import { numberIsSet, sortAlphabetically } from '@app/src/utils/helpers'
import { Box, Chip, Palette, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface ClassificationRowProps {
  item?: QuestionAnswerClassificationRule
  translationKey: string
  questionId?: number
  rules?: AssessmentRule[]
  showChip?: boolean
}

export const getSxByRank = (palette: Palette, rank?: QuestionAnswerRank) => {
  switch (rank) {
    case QuestionAnswerRank.Positive:
      return {
        color: 'common.white',
        bgcolor: palette.brandDecorative.emerald,
      }
    case QuestionAnswerRank.Negative:
      return {
        color: 'primary',
        bgcolor: palette.error.main,
      }
    case QuestionAnswerRank.NegativeAdverse:
      return {
        color: 'common.white',
        bgcolor: palette.semantic.error,
      }
    case QuestionAnswerRank.Neutral:
      return {
        color: 'primary',
        bgcolor: palette?.grey[200],
      }
    default:
      return {}
  }
}

export const sortClassificationBasedOnRuleType = (
  questionAnswerClassifications: QuestionAnswerClassificationRule[] | undefined = [],
) => {
  const definedQuestionAnswerClassifications = questionAnswerClassifications.filter(
    item => item?.rank !== QuestionAnswerRank.NotSet,
  )

  const optionClassifications = sortAlphabetically(
    definedQuestionAnswerClassifications.filter(
      answerClassification => answerClassification?.ruleType === AssessmentRuleType.SelectedValue,
    ),
    'value',
  ) as QuestionAnswerClassificationRule[]

  const numberClassifications = sortAlphabetically(
    definedQuestionAnswerClassifications.filter(
      answerClassification =>
        answerClassification?.ruleType === AssessmentRuleType.NumberEqualTo ||
        answerClassification?.ruleType === AssessmentRuleType.NumberGreaterThan ||
        answerClassification?.ruleType === AssessmentRuleType.NumberLowerThan,
    ),
    'ruleType',
  ) as QuestionAnswerClassificationRule[]

  let answerClassifications = sortAlphabetically(
    definedQuestionAnswerClassifications.filter(
      answerClassification => answerClassification?.ruleType === AssessmentRuleType.AnswerStatus,
    ),
    'value',
  ) as QuestionAnswerClassificationRule[]

  const hasOtherClassifications = optionClassifications.length > 0 || numberClassifications.length > 0
  if (hasOtherClassifications) {
    answerClassifications = answerClassifications.filter(
      answerClassification => answerClassification.value !== 'Answered',
    )
  }

  return { optionClassifications, numberClassifications, answerClassifications }
}

const ClassificationRow: React.FC<ClassificationRowProps> = ({
  item,
  translationKey,
  questionId,
  rules,
  showChip = true,
}) => {
  const { formatMessage } = useIntl()

  const showPoints = Boolean(rules) && numberIsSet(questionId)

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      key={item?.rank}
      borderTop="1px solid"
      borderColor="divider"
      py={1.5}
      px={2}
    >
      <Typography sx={{ flex: 3, mr: 2 }}>
        {formatMessage({ id: translationKey }, { ruleType: item?.ruleType?.toString(), value: item?.value })}
      </Typography>
      <Box
        display="flex"
        justifyContent={showPoints ? 'left' : 'flex-end'}
        flex={showPoints ? 1 : 'none'}
        minWidth={showPoints ? 'auto' : '120px'}
      >
        {showChip && (
          <Chip
            label={formatMessage({
              id: `templateBuilder.answerClassifications.classificationsRanks.${item?.rank}`,
            })}
            size="small"
            sx={({ palette }) => ({ ...getSxByRank(palette, item?.rank) })}
          />
        )}
      </Box>
      {showPoints && (
        <Typography sx={{ flex: 1, textAlign: 'right', minWidth: '120px' }}>
          {formatMessage(
            { id: 'baseAssessment.providerPage.points' },
            {
              points: rules?.find(p => p.value === item?.rank && p.questionId === questionId)?.points || 0,
            },
          )}
        </Typography>
      )}
    </Stack>
  )
}

export default ClassificationRow
