import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { RiskStatus } from '@app/src/types/resourceExplorer'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts-for-react'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { groupBy } from 'lodash'
import { IntlFormatters, useIntl } from 'react-intl'

const getTranslationKeysForLevels = (
  isBaselineAssessment: boolean,
  has5Levels: boolean,
  formatMessage: IntlFormatters['formatMessage'],
) => {
  if (isBaselineAssessment) {
    return Array.from(Array(5).keys()).map(i => formatMessage({ id: 'baseAssessment.totalLevel' }, { value: i + 1 }))
  }

  if (has5Levels) {
    return Array.from(Array(5).keys()).map(i =>
      formatMessage({ id: `assessments.levelsWithPercentageSpan.5.level${i + 1}` }),
    )
  }

  return Array.from(Array(3).keys()).map(i =>
    formatMessage({ id: `assessments.levelsWithPercentageSpan.3.level${i + 1}` }),
  )
}
const getAxisForSelectedChartType = (
  selectedChartType: ChartType,
  groups?: Array<string>,
): { xAxis?: XAXisOption; yAxis?: YAXisOption } => {
  if (selectedChartType === ChartType.Horizontal) {
    return {
      xAxis: {
        type: 'value',
        name: 'Number of companies',
        position: 'bottom',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: 10,
        },
        minInterval: 1,
      },
      yAxis: {
        type: 'category',
        show: false,
        data: groups,
        inverse: true,
      },
    }
  }
  if (selectedChartType === ChartType.Vertical)
    return {
      xAxis: {
        type: 'category',
        show: Boolean(groups?.length),
        data: groups,
        axisLabel: {
          interval: 0,
          overflow: 'truncate',
        },
      },
      yAxis: {
        type: 'value',
        name: 'Number of companies',
        position: 'bottom',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: 10,
        },
        minInterval: 1,
      },
    }

  return {
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
  }
}

export interface AssessmentOverviewGraphDataPoint {
  value: number
  level: number
  groupName?: string
  riskStatus?: RiskStatus
  providerIds?: Array<number>
  periodName?: string
}

const useAssessmentOverviewChartOptions = (
  levels: Array<AssessmentOverviewGraphDataPoint>,
  selectedChartType: ChartType,
  has5Levels: boolean,
  isBaselineAssessment: boolean,
  groups?: Array<string>,
): EChartsOption => {
  const { formatMessage } = useIntl()
  const { palette, typography } = useTheme()
  const isHorizontalChart = selectedChartType === ChartType.Horizontal

  const COLORS = has5Levels
    ? [
        palette.brandDecorative.emerald,
        palette.success.dark,
        palette.warning.dark,
        palette.error.dark,
        palette.semantic.error,
      ]
    : [palette.success.dark, palette.warning.dark, palette.error.dark]

  const labelSeriesTranslations = getTranslationKeysForLevels(isBaselineAssessment, has5Levels, formatMessage)

  const dataPerAssessmentLevel = groupBy(levels, 'level')
  const dataSeries = Object.keys(dataPerAssessmentLevel)
    .map(key => ({
      data: [...dataPerAssessmentLevel[key]],
      label: labelSeriesTranslations[Number(key) - 1],
      level: Number(key),
    }))
    .sort((a, b) => b.level - a.level)

  switch (selectedChartType) {
    case ChartType.Pie:
      return {
        grid: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 50,
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: !groups
            ? undefined
            : (params: { marker: string; data: { name: string; value: number; periodName: string } }) => {
                const data = params.data
                return `${data.periodName}<br />${params.marker} ${data.name}&nbsp;&nbsp;&nbsp;&nbsp;<strong>${data.value}</strong>`
              },
        },
        legend: {
          icon: 'circle',
          bottom: 0,
          left: 0,
          selectedMode: false,
          textStyle: {
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
        },
        color: COLORS,
        series: [
          {
            type: 'pie',
            bottom: 50,
            data: dataSeries.map(series => ({
              value: series.data[series.data.length - 1].value,
              name: series.label,
              level: series.level,
              periodName: series.data[series.data.length - 1].periodName,
            })),
          },
        ],
      }
    case ChartType.Horizontal:
    case ChartType.Vertical:
      return {
        ...getAxisForSelectedChartType(selectedChartType, groups),
        grid: {
          left: isHorizontalChart ? 10 : 50,
          right: 41,
          top: isHorizontalChart ? 20 : 50,
          bottom: isHorizontalChart ? 80 : 75,
        },
        tooltip: {
          trigger: groups ? 'axis' : 'item',
          axisPointer: { type: 'none' },
          formatter: groups
            ? undefined
            : (params: { marker: string; data: { name: string; value: number } }) => {
                const data = params.data
                return `${params.marker} ${data.name}&nbsp;&nbsp;&nbsp;&nbsp;<strong>${data.value}</strong>`
              },
        },
        legend: {
          data: dataSeries.map(series => ({ name: series?.label, icon: 'circle' })),
          bottom: 0,
          left: 0,
          selectedMode: false,
          textStyle: {
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
        },
        color: COLORS,
        series: dataSeries.map((series, seriesIndex) => ({
          type: 'bar',
          barCategoryGap: groups ? '50%' : undefined,
          showBackground: true,
          backgroundStyle: {
            opacity: 0.5,
          },
          name: series.label,
          data: series.data.map(d => ({
            value: d.value,
            name: series.label,
            level: d.level,
            riskStatus: d.riskStatus,
            groupName: d.groupName,
            providerIds: d.providerIds,
            periodName: d.periodName,
          })),
          bottom: 50,
          label: groups
            ? isHorizontalChart
              ? seriesIndex === 0
                ? {
                    show: true,
                    position: [0, -16],
                    align: 'left',
                    fontFamily: typography.fontFamily,
                    fontSize: typography.body2.fontSize,
                    textBorderColor: 'white',
                    textBorderWidth: 3,
                    formatter: (params: { data: { groupName: string } }) => params.data.groupName,
                  }
                : undefined
              : undefined
            : {
                show: true,
                position: isHorizontalChart ? 'right' : 'top',
                distance: 10,
                fontFamily: typography.fontFamily,
                fontSize: typography.body2.fontSize,
                formatter: (params: { value: number }) => params.value || '',
              },
        })),
      }
  }
}

export default useAssessmentOverviewChartOptions
