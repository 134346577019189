import { COMPANY_REPORT_QUESTION_KEYS } from '@app/src/components/CreateNewRequestModal/CompanyReport'
import { Periods } from '@app/src/components/EvaluationsWizard/PeriodField'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useQuestionnairePeriodOptions } from '@app/src/hooks/questionnairePeriodOptions'
import { getHalfYears, getMonths, getQuarters } from '@app/src/utils'
import { Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface PeriodFieldsProps {
  defaultValues?: { dateYear?: number; dateType: string; datePeriod?: number }
}

export const PeriodFields: React.FC<PeriodFieldsProps> = ({ defaultValues }) => {
  const { setValue, control, watch } = useFormContext()
  const { questionnaireYearOptions } = useQuestionnairePeriodOptions()
  const { formatMessage } = useIntl()

  const watchDateType: string = watch(COMPANY_REPORT_QUESTION_KEYS.DATE_TYPE)
  const watchDateYear: string = watch(COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR)

  const DATE_PERIOD_OPTIONS = useMemo((): Option<number>[] => {
    switch (watchDateType) {
      case Periods.HalfYearly:
        return getHalfYears(watchDateYear)
      case Periods.Quarterly:
        return getQuarters(watchDateYear)
      default:
        return getMonths()
    }
  }, [watchDateType, watchDateYear])

  const PERIOD_FIELD_LABEL = useMemo((): string => {
    switch (watchDateType) {
      case Periods.HalfYearly:
        return formatMessage({ id: 'form.createRequest.selectPeriodIntervalLabel' })
      case Periods.Quarterly:
        return formatMessage({ id: 'form.createRequest.selectPeriodQuarterLabel' })
      default:
        return formatMessage({ id: 'form.createRequest.selectPeriodMonthLabel' })
    }
  }, [watchDateType, watchDateYear])

  return (
    <>
      {Boolean(watchDateType) && (
        <Grid item xs={watchDateType === Periods.Yearly ? 12 : 6}>
          <Select
            options={questionnaireYearOptions}
            fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
            onInputChange={(e, value) => {
              const changedByUserInput = Boolean(e)
              if (!changedByUserInput) return
              if (!value) setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR, undefined)
              setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD, undefined)
            }}
            defaultValue={defaultValues?.dateYear ? defaultValues.dateYear : -1}
            required
            enableAutoSelect
            name={COMPANY_REPORT_QUESTION_KEYS.DATE_YEAR}
            control={control}
            findSelectedValue={(value, option) => option.value?.toString() === value?.toString()}
          />
        </Grid>
      )}

      {Boolean(watchDateType && watchDateType !== Periods.Yearly) && (
        <Grid item xs={6}>
          <Select<number>
            disabled={!watchDateYear}
            options={DATE_PERIOD_OPTIONS}
            fieldLabel={PERIOD_FIELD_LABEL}
            onInputChange={(e, value) => {
              const changedByUserInput = Boolean(e)
              if (!changedByUserInput) return
              if (!value) setValue(COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD, undefined)
            }}
            defaultValue={defaultValues?.datePeriod}
            required
            enableAutoSelect
            name={COMPANY_REPORT_QUESTION_KEYS.DATE_PERIOD}
            control={control}
          />
        </Grid>
      )}
    </>
  )
}
