import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import Table from '@app/src/components/Table'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { useSelectableRows } from '@app/src/hooks/useSelectableRows'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization } from '@app/src/types/organizations'
import { ProductMappingRequest } from '@app/src/types/product'
import { MappingRequestStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ADMIN_ROLES, NotificationSeverity } from '@app/src/wf-constants'
import { DeleteOutlineOutlined, NotificationsActiveOutlined } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import SentMappingRequestFilters from '../Filters/SentMappingRequestFilters'
import ResourceCollectionScene from '../ResourceCollectionScene'
import RequestsReminderModal, { REQUEST_TYPES } from './ManageRequests/RequestsReminder/RequestsReminderModal'
import SentMappingRequestHeader from './MappingRequest/SentMappingRequestHeader'
import SentMappingRequestRow from './MappingRequest/SentMappingRequestRow'

export interface GroupedRequest {
  responder: GenericOrganization
  requests: ProductMappingRequest[]
}

function groupRequestsByResponder(requests: ProductMappingRequest[]): GroupedRequest[] {
  const grouped = Object.groupBy(requests, request => request.responderOrganizationId)

  return Object.entries(grouped).map(([organizationId, groupedRequests]) => ({
    responder: groupedRequests?.[0].responderOrganization as GenericOrganization,
    requests: groupedRequests || [],
  }))
}

const allowedFilters: string[] = ['product.id', 'product.name', 'status', 'periodName']

const SentMappingRequestCollection: React.FC = () => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const [isRequestsReminderDialogOpen, openRequestsReminderDialog, closeRequestsReminderDialog] = useDialogState(false)
  const { role } = useAuthentication().scope
  const isAdmin = ADMIN_ROLES.includes(role?.toLowerCase() ?? '')
  const confirm = useConfirm()
  const { mutateAsync: deleteMappingRequests, isLoading: isDeletingMappingRequests } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const filters = useGetApiQueryFilters(allowedFilters)
  const implicitFilters: FilterGroup[] = [
    {
      name: 'creatorOrganizationId',
      filters: [{ value: account?.organization?.id, operator: Operators.EqualTo }],
    },
  ]

  const {
    items: pageMappingRequests,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<ProductMappingRequest>({
    key: FetchKey.MappingRequests,
    endpoint: endpoints.mappingRequestCollection,
    payload: {
      filter: [...filters, ...implicitFilters],
      sort: sorting,
      include: ['product', 'responderOrganization'],
      pagination: {
        pageNumber: page,
        itemsPerPage: pageSize,
      },
    },
  })

  const {
    allRows,
    selectedRowsIds,
    setSelectedRowsIds,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    handleCheckboxChange,
    handleHeaderCheckboxChange,
    isHeaderChecked,
  } = useSelectableRows<ProductMappingRequest>({
    rowsInPage: pageMappingRequests,
    allRowsElements: {
      basePayload: {
        filter: [...filters, ...implicitFilters],
        sort: sorting,
        include: ['product', 'responderOrganization'],
      },
      fetchKey: FetchKey.AllMappingRequests,
      endpoint: endpoints.mappingRequestCollection,
      count,
    },
  })

  const mappingRequests = allRows.length ? allRows : pageMappingRequests

  const unhandledRequests = useMemo(
    () =>
      mappingRequests
        .filter(request => selectedRowsIds.includes(request.id))
        .filter(request => request.status !== MappingRequestStatus.Submitted)
        .filter(request => !request.responderOrganization.deletedAt),
    [selectedRowsIds, mappingRequests],
  )

  const unhandledRequestsByResponder = groupRequestsByResponder(unhandledRequests)

  const handleCloseRequestReminder = () => {
    setSelectedRowsIds([])
    closeRequestsReminderDialog()
  }

  const selectedAllowedDeleteIds = useMemo(
    () =>
      mappingRequests
        .filter(request => selectedRowsIds.includes(request.id))
        .filter(request => request.status === MappingRequestStatus.Requested)
        .map(request => request.id),
    [selectedRowsIds, mappingRequests],
  )

  const confirmDeleteSelected = () => {
    confirm({
      description: formatMessage(
        { id: 'resourceCollections.delete.requests.dialogDescriptionMappingRequest' },
        { count: selectedAllowedDeleteIds.length },
      ),
      confirmationText: formatMessage({ id: 'general.delete' }),
      cancellationText: formatMessage({ id: 'general.close' }),
    }).then(async () => {
      await deleteMappingRequests(
        { url: endpoints.productMappingRequestByIds(selectedAllowedDeleteIds) },
        {
          onSuccess: () => {
            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
              severity: NotificationSeverity.success,
            })
            setSelectedRowsIds([])
            queryClient.invalidateQueries(FetchKey.MappingRequests)
          },
        },
      )
    })
  }

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.sentMappingRequests' })}
        filter={<SentMappingRequestFilters allowedFilters={allowedFilters} implicitFilters={implicitFilters} />}
        buttonRow={
          selectedRowsIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.sendRequestReminder' },
                    { count: unhandledRequests.length },
                  ),
                  startIcon: <NotificationsActiveOutlined />,
                  onClick: openRequestsReminderDialog,
                  disabled: !unhandledRequests.length || isLoading || isFetching || isLoadingAllRows,
                },
                ...insertIf(isAdmin, {
                  label: formatMessage(
                    { id: 'resourceCollections.delete.requests.deleteCount' },
                    { count: selectedAllowedDeleteIds.length },
                  ),
                  startIcon: <DeleteOutlineOutlined />,
                  onClick: confirmDeleteSelected,
                  disabled: isLoading || isFetching || isLoadingAllRows || isDeletingMappingRequests,
                }),
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAllRequests' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,
                  disabled: isLoading || isFetching || isAllSelected(),
                  loading: isLoadingAllRows,
                }),
              ]
            : undefined
        }
      >
        <Table<ProductMappingRequest>
          RowComponent={({ row }) => (
            <SentMappingRequestRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedRequestsIds={selectedRowsIds}
            />
          )}
          HeaderComponent={() => (
            <SentMappingRequestHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              isHeaderChecked={isHeaderChecked}
              handleHeaderCheckboxChange={handleHeaderCheckboxChange}
            />
          )}
          data={pageMappingRequests}
          count={count}
          page={page}
          isError={isError}
          isLoading={isLoading || isFetching}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>
      <CreationModalProgressContextProvider>
        <RequestsReminderModal
          onClose={handleCloseRequestReminder}
          open={isRequestsReminderDialogOpen}
          unhandledRequests={unhandledRequests}
          unhandledRequestsByResponder={unhandledRequestsByResponder}
          requestType={REQUEST_TYPES.MappingRequest}
        />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default SentMappingRequestCollection
