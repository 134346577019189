import Assessment from '@app/src/types/assessment'
import { CheckboxProps } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { FetchKey, useFetchCollectionWithPost } from '../api/fetchHooks'
import { FilterGroup } from '../pages/ResourceCollection/Filters/useFilters'
import { Sort } from '../types/filter'

interface BasePayload {
  filter: FilterGroup[]
  pagination?: {
    pageNumber: number
    itemsPerPage: number
  }
  sort: Sort
  include: string[]
}

interface UseSelectableRowsArgs<T> {
  rowsInPage: T[]
  initialState?: number[]
  allRowsElements?: {
    basePayload?: BasePayload
    fetchKey?: FetchKey
    endpoint?: string
    count?: number
  }
}

const EMPTY_PAYLOAD = { filter: [], include: [] }

export const useSelectableRows = <T extends { id: number }>({
  rowsInPage,
  initialState = [],
  allRowsElements,
}: UseSelectableRowsArgs<T>) => {
  const [selectedRowsIds, setSelectedRowsIds] = useState<Array<number>>(initialState)

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, row: T) => {
    if (e.target.checked) {
      setSelectedRowsIds(prev => [...prev, row.id])
    } else {
      setSelectedRowsIds(prev => prev.filter(providerId => providerId !== row.id))
    }
  }

  const {
    items: allRows,
    isLoading: isLoadingAllRows,
    refetch: fetchAllRows,
  } = useFetchCollectionWithPost<T>({
    key: allRowsElements?.fetchKey ?? FetchKey.None,
    endpoint: allRowsElements?.endpoint ?? '',
    payload: {
      ...(allRowsElements?.basePayload ?? EMPTY_PAYLOAD),
      pagination: {
        itemsPerPage: allRowsElements?.count ?? 0,
        pageNumber: 1,
      },
    },
    options: {
      enabled: false,
    },
  })

  const isAssessment = useCallback((item: unknown): item is Assessment => {
    return item !== null && typeof item === 'object' && 'provider' in item
  }, [])

  const isAssessmentType = useMemo(() => {
    if (!allRows.length) return false
    const item = allRows[0]
    return item !== null && typeof item === 'object' && 'provider' in item
  }, [allRows])

  const isAllSelected = () => {
    if (!allRowsElements) return false
    if (!allRows.length) return false

    return isAssessmentType
      ? allRows.every(row => selectedRowsIds.includes((row as unknown as Assessment).provider.id))
      : allRows.every(row => selectedRowsIds.includes(row.id))
  }

  const handleSelectAll = async () => {
    if (!allRowsElements) return
    if (!allRows.length) {
      const { data } = await fetchAllRows()
      if (data?.items) {
        const providerIds = isAssessment(data.items[0])
          ? (data.items as unknown as Assessment[]).map(item => item.provider.id)
          : data.items.map(item => (item as T).id)

        setSelectedRowsIds(prev => Array.from(new Set([...prev, ...providerIds])))
      }
    } else {
      const allIds = allRows.map(row => row.id)
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...allIds])))
    }
  }

  const isHeaderChecked = rowsInPage?.every(row => selectedRowsIds?.includes(row.id))

  const handleHeaderCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedRowsIds(prev => Array.from(new Set([...prev, ...rowsInPage.map(row => row.id)])))
    } else {
      setSelectedRowsIds([])
    }
  }

  return {
    selectedRowsIds,
    setSelectedRowsIds,
    handleCheckboxChange,
    allRows,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    isHeaderChecked,
    handleHeaderCheckboxChange,
  }
}
