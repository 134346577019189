import React from 'react'
import { Control, Controller } from 'react-hook-form'
import TextFieldWithFormatting, { TextFieldWithFormattingProps } from './TextFieldWithFormatting'

type ControlledTextFieldWithFormattingProps = {
  control: Control
  name: string
  multiline?: boolean
  defaultValue?: string
  rules?: { [key: string]: string }
} & TextFieldWithFormattingProps

function ControlledTextFieldWithFormatting({
  control,
  name,
  defaultValue,
  rules,
  ...rest
}: ControlledTextFieldWithFormattingProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ value, onChange }) => <TextFieldWithFormatting value={value} onChange={onChange} {...rest} />}
    />
  )
}

export default ControlledTextFieldWithFormatting
