import { NO_PERIOD_PERIOD_NAME, Periods } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'
import { getPeriodType, getQuarters } from '@app/src/utils'
import { getDateWithoutTimeFromString } from '@app/src/utils/helpersTs'
import { useIntl } from 'react-intl'

export const getPeriodsToShowInStatisticsView = (periods: string[]): string[] => {
  if (periods.length === 1 && periods[0] === NO_PERIOD_PERIOD_NAME) return periods
  return periods.filter(p => p !== NO_PERIOD_PERIOD_NAME)
}

export const usePeriodName = () => {
  const { formatMessage } = useIntl()

  const getPeriodDisplayName = (startDate?: string | null, endDate?: string | null) => {
    const periodType = getPeriodType(startDate, endDate)
    if (periodType === Periods.NoPeriod || !startDate)
      return formatMessage({ id: 'form.createRequest.periodTypes.noPeriod' })

    const date1 = getDateWithoutTimeFromString(startDate)

    const startMonth = date1.getMonth()
    const year = date1.getFullYear()
    switch (periodType) {
      case Periods.Monthly:
        return `${date1.toLocaleDateString('en', { month: 'long' })} ${year}`
      case Periods.Quarterly:
        return getQuarters(year)[Math.floor((startMonth + 1) / 3)]?.label
      case Periods.HalfYearly:
        return `H${startMonth === 0 ? '1' : '2'} ${year}`
      case Periods.Yearly:
      default:
        return year
    }
  }

  const formatPeriodName = (periodName: string): string => {
    if (!periodName) return ''
    return periodName === NO_PERIOD_PERIOD_NAME
      ? formatMessage({ id: 'form.createRequest.periodTypes.noPeriod' })
      : periodName
  }

  return {
    formatPeriodName,
    getPeriodDisplayName,
    getPeriodsToShowInStatisticsView,
  }
}
