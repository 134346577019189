import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import PeriodField from '@app/src/components/EvaluationsWizard/PeriodField'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import InfoBox from '@app/src/components/InfoBox'
import LoadingButton from '@app/src/components/LoadingButton'
import TextField from '@app/src/components/Ui/TextField'
import Wizard, { ReviewItem, StepContainer, useStepper } from '@app/src/components/Wizard'
import { Question } from '@app/src/components/Wizard/StepContainer'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import EvaluationStatus from '@app/src/pages/ResourceCollection/Collections/Evaluation/EvaluationStatus'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { GenericOrganization, Provider } from '@app/src/types/organizations'
import { DataPoint, Framework, FrameworkSection, Indicator, Unit } from '@app/src/types/resourceExplorer'
import { Navigation } from '@app/src/types/schemas'
import { NotificationSeverity, ResourceTypes, Solutions } from '@app/src/wf-constants'
import { Alert, Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import IndicatorValueInput from './IndicatorResultInput'

const useStyles = makeStyles(({ spacing, palette }) => ({
  backButton: {
    marginRight: spacing(1),
  },
  buttonsContainer: {
    paddingTop: spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  reviewContainer: {
    borderWidth: 1,
    borderColor: palette.common.black,
    borderStyle: 'solid',
    borderRadius: 12,
    padding: spacing(2),
    marginBottom: spacing(4),
  },
  reviewTitle: {
    textAlign: 'center',
    marginBottom: spacing(4),
  },
}))

interface EvaluationFormType {
  evidence: string
  framework: { name: string; id: number }
  indicator: { name: string; id: number }
  periodEndsAt: string
  periodStartsAt: string
  periodType: string
  result: string
  section: { name: string; id: number }
  targetObject: { name: string; id: number; organizationId: number }
  targetObjectType: string
}

interface ReviewProps {
  currentValues: EvaluationFormType
  activeIndicatorUnit?: Unit
}

const ReviewStep: React.FC<ReviewProps> = ({ currentValues, activeIndicatorUnit }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { getPeriodDisplayName } = usePeriodName()

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.reviewTitle}>
          {formatMessage({ id: 'form.createEvaluation.reviewTitle' })}
        </Typography>
      </Grid>
      <Grid container spacing={3} className={classes.reviewContainer}>
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.targetType' })}
          value={currentValues.targetObjectType}
        />
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.framework' })}
          value={currentValues.framework?.name}
        />
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.result' })}
          value={
            currentValues.result && activeIndicatorUnit ? (
              `${currentValues.result} ${activeIndicatorUnit?.symbol}`
            ) : (
              <EvaluationStatus value={currentValues.result} />
            )
          }
        />
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.target' })}
          value={currentValues.targetObject?.name}
        />
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.frameworkSection' })}
          value={currentValues.section?.name}
        />
        <ReviewItem label={formatMessage({ id: 'schemas.evaluation.evidence' })} value={currentValues.evidence} />
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.period' })}
          value={getPeriodDisplayName(currentValues.periodStartsAt, currentValues.periodEndsAt)}
        />
        <ReviewItem
          label={formatMessage({ id: 'schemas.evaluation.frameworkIndicator' })}
          value={currentValues.indicator?.name}
        />
      </Grid>
    </>
  )
}

interface Props {
  onSubmit?: () => void
  onClose: () => void
  isOpen: boolean
}

interface FormValues {
  periodStartsAt: string
  periodEndsAt: string
  evidence: string
  result: string | number
  indicator: Indicator
  targetObject: GenericOrganization
  targetObjectType: string
}

const EvaluationsWizard: React.FC<Props> = ({ onSubmit: onSubmitCallback, onClose, isOpen }) => {
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const classes = useStyles()
  const { getWithPermission } = usePermissions()
  const { activeStep, resetStep, handleBack, handleNext } = useStepper()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useUpdateResource<Partial<DataPoint>>()

  const { handleSubmit, control, formState, setError, register, getValues, watch, setValue, reset } =
    useForm<EvaluationFormType>()

  const currentValues = getValues()
  const watchTargetObjectType = watch(
    'targetObjectType',
    getWithPermission({
      [Permissions.SOURCING_USER]: ResourceTypes.Supplier,
      [Permissions.INVESTMENT_USER]: ResourceTypes.Investee,
      [Permissions.FINANCE_USER]: ResourceTypes.Company,
      fallback: '',
    }),
  )
  const watchFramework = watch('framework')
  const watchSection = watch('section')
  const watchIndicator = watch('indicator')

  const { items: frameworkItems } = useFetchCollectionWithPost<Framework>({
    key: FetchKey.Framework,
    endpoint: endpoints.frameworks,
    payload: {
      include: ['sections', 'sections.items'],
      filter: [],
    },
  })

  const sections = frameworkItems.find((framework: Framework) => framework.id === watchFramework?.id)?.sections

  const { items: indicatorItems } = useFetchCollectionWithPost<Indicator>({
    key: FetchKey.Indicator,
    endpoint: endpoints.frameworkIndicators,
    payload: {
      include: ['section', 'section.framework', 'unit', 'frameworkOptionCollection'],
      filter: [
        {
          name: 'section.id',
          filters: [
            {
              value: watchSection?.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
    },
    options: {
      enabled: isOpen && Boolean(watchSection?.id),
    },
  })

  const errors = formState.errors

  const steps = [
    formatMessage({ id: 'form.createEvaluationSteps.target' }),
    formatMessage({ id: 'form.createEvaluationSteps.evaluation' }),
    formatMessage({ id: 'form.createEvaluationSteps.review' }),
  ]

  const TARGET_OBJECT_NAVIGATION: Navigation = {
    url: endpoints.providersCollection,
    type: 'post',
    postObject: {
      include: [],
      filter: [
        {
          name: 'linked',
          filters: [
            {
              value: 'true',
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
    },
  }

  const frameworkOptions = useMemo(
    () =>
      frameworkItems?.map((framework: Framework) => ({
        label: framework.title,
        value: {
          id: framework.id,
          name: framework.title,
        },
      })),
    [frameworkItems],
  )

  const sectionsOptions = useMemo(
    () =>
      sections?.map((section: FrameworkSection) => ({
        label: section.title,
        value: {
          id: section.id,
          name: section.title,
        },
      })),
    [sections],
  )

  const indicatorsOptions = useMemo(
    () =>
      indicatorItems?.map((indicator: Indicator) => ({
        label: indicator.description,
        value: {
          id: indicator.id,
          name: indicator.description,
        },
      })),
    [indicatorItems],
  )

  const activeIndicator: Indicator | undefined = useMemo(
    () => indicatorItems.find((indicator: Indicator) => indicator.id === watchIndicator?.id),
    [watchIndicator, indicatorItems],
  )

  const activeIndicatorUnit = activeIndicator?.unit

  const onSubmit: SubmitHandler<Partial<FormValues>> = async (values): Promise<void> => {
    const evaluation: Partial<DataPoint> = {
      periodStartsAt: values.periodStartsAt,
      periodEndsAt: values.periodEndsAt,
      evidence: values.evidence,
      value: values.result,
      indicatorId: values.indicator?.id,
      targetObjectType: values.targetObjectType,
      targetObjectId: values.targetObject?.id,
    }
    mutate(
      {
        url: endpoints.saveResource(Solutions.Resources, ResourceTypes.DataPoint),
        body: evaluation,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulEvaluationCreated' }),
            severity: NotificationSeverity.success,
            disableAutoClose: true,
          })
          queryClient.invalidateQueries()
          onSubmitCallback && onSubmitCallback()
          onClose()
          resetStep()
        },
        onError: error => {
          if (error.isValidationError) {
            error.setFormValidationErrors(setError)
            return
          }
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  useEffect(() => {
    if (isOpen) {
      reset()
      resetStep()
    }
  }, [isOpen])

  return (
    <Wizard onClose={onClose} isOpen={isOpen} activeStep={activeStep} steps={steps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {Object.keys(formState.errors)?.length > 0 && (
            <Grid item xs={12}>
              <Alert elevation={1} variant="filled" severity="error">
                {formatMessage({ id: 'form.validation.error' })}
              </Alert>
            </Grid>
          )}
          {/* --------- STEP 1 ---------- */}
          <StepContainer step={0} activeStep={activeStep}>
            <Question xs={12} title={formatMessage({ id: 'form.createEvaluation.targetType' })}>
              <Select
                name="targetObjectType"
                fieldLabel={formatMessage({ id: 'form.createEvaluation.targetTypeLabel' })}
                control={control}
                required
                enableAutoSelect
                options={getWithPermission({
                  [Permissions.SOURCING_USER]: [
                    { value: ResourceTypes.Supplier, label: formatMessage({ id: 'general.Supplier' }, { count: 1 }) },
                  ],
                  [Permissions.INVESTMENT_USER]: [
                    { value: ResourceTypes.Investee, label: formatMessage({ id: 'general.Investee' }, { count: 1 }) },
                  ],
                  [Permissions.FINANCE_USER]: [
                    { value: ResourceTypes.Company, label: formatMessage({ id: 'general.Company' }, { count: 1 }) },
                  ],
                  fallback: [],
                })}
                error={errors.targetObjectType}
              />
            </Question>
            <Question
              disabled={!watchTargetObjectType}
              xs={12}
              title={
                watchTargetObjectType
                  ? formatMessage(
                      {
                        id: 'form.createEvaluation.target',
                      },
                      { resource: watchTargetObjectType },
                    )
                  : formatMessage({ id: 'form.createEvaluation.noTargetType' })
              }
            >
              <Select<Partial<Provider>, Provider>
                name="targetObject"
                fieldLabel={formatMessage({ id: 'form.createEvaluation.targetLabel' })}
                control={control}
                disabled={!watchTargetObjectType}
                required
                enableAutoSelect
                navigation={TARGET_OBJECT_NAVIGATION}
                error={errors.targetObject}
                objectToOption={(target: Provider): Option<Partial<Provider>> => ({
                  label: target.name,
                  value: {
                    name: target.name,
                    id: target.id,
                    organizationId: target.organizationId,
                  },
                })}
              />
            </Question>
            <Question title={formatMessage({ id: 'form.createEvaluation.period' })} xs={12}>
              <PeriodField required register={register} error={errors.periodStartsAt} />
            </Question>
          </StepContainer>

          {/* --------- STEP 2 ---------- */}
          <StepContainer step={1} activeStep={activeStep}>
            <Question xs={6} title={formatMessage({ id: 'form.createEvaluation.framework' })}>
              <Select<Partial<Framework>, Framework>
                name="framework"
                fieldLabel={formatMessage({ id: 'form.createEvaluation.frameworkLabel' })}
                control={control}
                onClick={(): void => {
                  setValue('section', '')
                  setValue('indicator', '')
                  setValue('result', '')
                }}
                required
                enableAutoSelect
                options={frameworkOptions}
                error={errors.framework}
              />
            </Question>
            <Question
              title={
                watchFramework
                  ? formatMessage({ id: 'form.createEvaluation.frameworkSection' })
                  : formatMessage({ id: 'form.createEvaluation.frameworkSectionDisabled' })
              }
              xs={6}
              disabled={!watchFramework}
            >
              <Select<Partial<FrameworkSection>, FrameworkSection>
                name="section"
                onClick={(): void => {
                  setValue('indicator', '')
                  setValue('result', '')
                }}
                fieldLabel={formatMessage({ id: 'form.createEvaluation.frameworkSectionLabel' })}
                control={control}
                required
                enableAutoSelect
                disabled={!watchFramework}
                options={sectionsOptions}
                error={errors.section}
              />
            </Question>
            <Question
              title={
                watchSection
                  ? formatMessage({ id: 'form.createEvaluation.frameworkIndicator' })
                  : formatMessage({ id: 'form.createEvaluation.frameworkIndicatorDisabled' })
              }
              xs={6}
              disabled={!watchSection}
            >
              <Select<Partial<Indicator>, Indicator>
                name="indicator"
                fieldLabel={formatMessage({ id: 'form.createEvaluation.frameworkIndicationLabel' })}
                control={control}
                onClick={(): void => {
                  setValue('result', '')
                }}
                required
                enableAutoSelect
                disabled={!watchSection}
                error={errors.indicator}
                options={indicatorsOptions}
              />
            </Question>
            <Question
              title={
                watchIndicator
                  ? formatMessage({ id: 'form.createEvaluation.resultLabel' })
                  : formatMessage({ id: 'form.createEvaluation.resultDisabled' })
              }
              xs={6}
              disabled={!watchIndicator}
            >
              <IndicatorValueInput
                activeIndicator={activeIndicator}
                control={control}
                setValue={setValue}
                register={register}
                errors={errors}
              />
            </Question>
            <Question title={formatMessage({ id: 'form.createEvaluation.evidence' })} xs={12}>
              <TextField
                inputRef={register}
                fullWidth
                name="evidence"
                label={formatMessage({ id: 'form.createEvaluation.evidenceLabel' })}
                error={Boolean(errors.evidence)}
                type="text"
                helperText={errors?.evidence?.message}
                onClear={(): void => setValue('evidence', '')}
              />
            </Question>
          </StepContainer>
          {/* --------- STEP 3 ---------- */}
          <StepContainer step={2} activeStep={activeStep}>
            <ReviewStep currentValues={currentValues} activeIndicatorUnit={activeIndicatorUnit} />
            <Grid item xs={12}>
              <InfoBox title={formatMessage({ id: 'form.createEvaluation.reviewInfoTitle' })}>
                {formatMessage({ id: 'form.createEvaluation.reviewInfoDescription' })}
              </InfoBox>
            </Grid>
          </StepContainer>
        </Grid>
        <div className={classes.buttonsContainer}>
          <Button onClick={onClose} className={classes.backButton}>
            {formatMessage({ id: 'general.close' })}
          </Button>

          <Box>
            {activeStep !== 0 ? (
              <Button onClick={handleBack} className={classes.backButton}>
                {formatMessage({ id: 'general.back' })}
              </Button>
            ) : (
              <div />
            )}
            {activeStep === steps?.length - 1 ? (
              <LoadingButton variant="contained" color="primary" type="submit" loading={isLoading}>
                {formatMessage({ id: 'general.submit' })}
              </LoadingButton>
            ) : (
              <Button variant="contained" onClick={handleNext}>
                {formatMessage({ id: 'general.next' })}
              </Button>
            )}
          </Box>
        </div>
      </form>
    </Wizard>
  )
}

export default EvaluationsWizard
