import { WarningAmberOutlined } from '@mui/icons-material'
import { Alert, AlertTitle, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

export const NoPeriodWarning: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <Stack ml={2} mt={4}>
      <Typography>{formatMessage({ id: 'form.createRequest.noPeriodGuidance' })}</Typography>
      <Alert severity="warning" icon={<WarningAmberOutlined />} sx={{ mt: 4, alignItems: 'flex-start', pr: 4, py: 2 }}>
        <AlertTitle sx={{ m: 0 }}>
          <Typography variant="subtitle1">
            {formatMessage({ id: 'form.createRequest.noPeriodWarningTitle' })}
          </Typography>
        </AlertTitle>
        <Typography>{formatMessage({ id: 'form.createRequest.noPeriodWarningText' })}</Typography>
      </Alert>
    </Stack>
  )
}
