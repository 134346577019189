import { NO_PERIOD_PERIOD_NAME } from '@app/src/components/CreateNewRequestModal/PeriodSelector/PeriodSelector'

type PeriodType = 'Y' | 'H' | 'Q' | 'M'

interface PeriodInfo {
  year: number
  type: PeriodType
  value: number
}

const parsePeriod = (s: string): PeriodInfo => {
  let type: PeriodType = 'Y'
  let value = 0
  let year: number

  if (s.includes(' ')) {
    const parts = s.split(' ')
    year = parseInt(parts[1], 10)
    type = parts[0][0] as PeriodType
    value = parseInt(parts[0].substring(1), 10)
  } else {
    year = parseInt(s, 10)
  }

  return { year, type, value }
}

const periodTypeWeights: Record<PeriodType, number> = {
  Y: 0, // Year
  H: 1, // Half-Year
  Q: 2, // Quarter
  M: 3, // Month
}

export const comparePeriods = (a: string | number, b: string | number): number => {
  if (a === NO_PERIOD_PERIOD_NAME || b === NO_PERIOD_PERIOD_NAME) return a === NO_PERIOD_PERIOD_NAME ? -1 : 1
  const aInfo = parsePeriod(String(a))
  const bInfo = parsePeriod(String(b))

  // Compare by year
  if (aInfo.year !== bInfo.year) {
    return aInfo.year - bInfo.year
  }

  // If the same year, compare by period type weight
  if (periodTypeWeights[aInfo.type] !== periodTypeWeights[bInfo.type]) {
    return periodTypeWeights[aInfo.type] - periodTypeWeights[bInfo.type]
  }

  // If same year and same type weight, compare by value
  return aInfo.value - bInfo.value
}
