import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface AssessmentCompareHeaderProps extends SortableHeaderProps {
  assessmentTemplateAndPeriod: string[]
  isHeaderChecked: boolean
  handleHeaderCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

const AssessmentCompareHeader: React.FC<AssessmentCompareHeaderProps> = ({
  toggleSorting,
  activeSorting,
  assessmentTemplateAndPeriod,
  isHeaderChecked,
  handleHeaderCheckboxChange,
}) => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isHeaderChecked, onChange: handleHeaderCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'baseAssessment.companyName' })}
        sorting={{ name: 'provider.name', toggleSorting, activeSorting }}
        minWidth={TableCellWidth.LARGE}
      />

      {assessmentTemplateAndPeriod.map(label => (
        <TableHeaderCell key={label} label={label} minWidth={TableCellWidth.MEDIUM} />
      ))}
    </TableRow>
  )
}

export default AssessmentCompareHeader
