import { OptionIcon } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { Option } from '@app/src/components/Form/Select/SimpleSelect'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import useFetchAiSuggestions from '@app/src/pages/Questionnaire/hooks/useFetchAiSuggestions'
import { PreviewMode } from '@app/src/pages/Questionnaire/Provider/Report/EditResponse'
import SuggestAnswerButton from '@app/src/pages/Questionnaire/Provider/Report/SuggestAnswerButton'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import SkipQuestionButton from './SkipQuestionButton'

type OptionSelectWithSuggestionProps = {
  requestItem: RequestItem
  periodName: string | undefined
  disabled?: boolean
  hasError?: boolean
  error?: string
  previewMode?: PreviewMode
}

const OptionSelectWithSuggestion: React.FC<OptionSelectWithSuggestionProps> = ({
  requestItem,
  periodName,
  disabled,
  hasError,
  error,
  previewMode,
}) => {
  const { control, clearErrors, setValue, trigger } = useFormContext()
  const { fetchedData, isLoadingAiSuggestions } = useFetchAiSuggestions(
    requestItem,
    'options',
    Boolean(previewMode),
    periodName,
  )
  const { formatMessage } = useIntl()
  const { hideInputField, setResponseItemView, previousAnswer, setQuestionSkippedFields } = useResponseItemContext()
  const { reuseAllQuestionsState } = useReport()
  const [appliedValue, setAppliedValue] = useState<string[]>()

  const [options, setOptions] = useState<Option[]>(() =>
    requestItem.requestCollection?.items?.map(option => ({
      value: option.value,
      label: option.displayText,
    })),
  )

  const transformedOptions =
    previewMode === PreviewMode.SelectionDisabled ? options.map(op => ({ ...op, disabled: true })) : options

  const name = `${requestItem.id}.selectedOptions`
  const selectedValue: string[] | string | undefined = useWatch({ name })

  useEffect(() => {
    if (!appliedValue || !selectedValue) return
    const selectedValueArray = Array.isArray(selectedValue) ? selectedValue : [selectedValue]
    const sameAsAppliedValue =
      selectedValueArray.length === appliedValue.length && selectedValueArray.every(s => appliedValue.includes(s))
    if (sameAsAppliedValue) return

    setAppliedValue(undefined)
    setResponseItemView(prevState =>
      prevState === ResponseItemView.SuggestedAnswerAccepted ? ResponseItemView.ShowSuggestedAnswer : prevState,
    )
  }, [Array.isArray(selectedValue) ? selectedValue.join(',') : selectedValue])

  const applySuggestion = (optionValues: string[]) => {
    setValue(name, optionValues)
    setAppliedValue(optionValues)
  }

  const onApplyAiSuggestions = () => {
    setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
    const optionValues = transformedOptions
      .filter(option => option.additionalText)
      .map(option => option.value.toString())
    applySuggestion(optionValues)
  }

  const suggestedOptions = useMemo(() => {
    return new Set(fetchedData?.selectedOptions?.map(op => op.toLowerCase()) ?? [])
  }, [fetchedData?.selectedOptions])

  useEffect(() => {
    if (isLoadingAiSuggestions) return
    const mappedOptions: Option[] = requestItem.requestCollection?.items?.map(option => ({
      value: option.value,
      label: option.displayText,
    }))

    const optionsWithSuggestions = mappedOptions
      ?.map(option => {
        if (suggestedOptions.has(option.value.toString().toLowerCase())) {
          return {
            ...option,
            additionalText: formatMessage({ id: 'suggestedAnswers.suggestedByAi' }),
          }
        }
        return option
      })
      ?.sort((a, b) => {
        if (a.additionalText && !b.additionalText) return -1
        if (!a.additionalText && b.additionalText) return 1
        return 0
      })

    setOptions(optionsWithSuggestions)
  }, [requestItem, fetchedData, isLoadingAiSuggestions])

  const applyPreviousAnswer = async () => {
    if (!previousAnswer) return
    if (previousAnswer.cannotAnswer) {
      setQuestionSkippedFields()
    } else {
      applySuggestion(previousAnswer?.selectedOptions)
    }

    setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
    await trigger()
  }

  useEffect(() => {
    switch (reuseAllQuestionsState) {
      case ReuseAllQuestionsStates.ApplyAll:
        applyPreviousAnswer()
        break
      default:
        setResponseItemView(ResponseItemView.ShowSuggestedAnswer)
    }
  }, [reuseAllQuestionsState])

  return (
    <>
      <Stack spacing={3} display={hideInputField ? 'none' : undefined} px={4} pb={previewMode ? 0 : 2}>
        <Select<string | number>
          multiple={requestItem.allowMultiChoice}
          required
          enableAutoSelect
          disabled={disabled}
          name={name}
          fieldLabel="Options"
          control={control}
          error={error}
          size="small"
          renderOption={(props, option, { selected }): JSX.Element => (
            <li {...props}>
              <OptionIcon selected={selected} multiple={requestItem.allowMultiChoice} />
              <Stack>
                <Typography>{option.label}</Typography>
                <Typography variant="caption" color="communication.dark">
                  {option.additionalText}
                </Typography>
              </Stack>
            </li>
          )}
          findSelectedValue={(value, option): boolean => {
            const flatValue = [value].flat()

            return requestItem.allowMultiChoice ? flatValue.includes(option.value) : option.value === flatValue[0]
          }}
          options={transformedOptions}
          onClick={(): void => {
            if (hasError) clearErrors(`${requestItem.id}`)
          }}
        />
      </Stack>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box flexGrow={1}>
          <SkipQuestionButton requestItem={requestItem} />
        </Box>
        <SuggestAnswerButton
          requestItem={requestItem}
          questionType="options"
          onApplyPreviousAnswer={applyPreviousAnswer}
          onApplyAiSuggestion={onApplyAiSuggestions}
          isPreview={Boolean(previewMode)}
        />
      </Box>
    </>
  )
}

export default OptionSelectWithSuggestion
