import AssessmentPieChartCell from '@app/src/components/Table/Cells/AssessmentPieChartCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import Assessment from '@app/src/types/assessment'
import { ResponseItem } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import AnswerWithClassificationCell from '@app/src/components/Table/Cells/AnswerWithClassificationCell'
import { Provider } from '@app/src/types/organizations'
import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'

type AssessmentsDetailsRowProps = {
  responseItems: Array<ResponseItem | undefined>
  assessment: Assessment
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => void
  selectedProviders: Array<number>
}

const AssessmentsDetailsRow: React.FC<AssessmentsDetailsRowProps> = ({
  responseItems,
  assessment,
  onCheckboxChange,
  selectedProviders,
}) => {
  if (!assessment) return null
  const isChecked = Boolean(
    selectedProviders?.some(selectedProvidersId => selectedProvidersId === assessment.providerId),
  )
  return (
    <TableRow>
      <CheckboxCell checked={isChecked} onChange={e => onCheckboxChange(e, assessment.provider)} />
      <CompanyCell company={assessment.provider} drawer maxRows={2} />
      <AssessmentPieChartCell assessment={assessment} disableCell={false} />
      {...responseItems.map((item, index) =>
        item ? (
          <AnswerWithClassificationCell item={item} key={`${index}-${item?.id}`} />
        ) : (
          <ValueCell value="-" key={`${index}`}></ValueCell>
        ),
      )}
    </TableRow>
  )
}

export default AssessmentsDetailsRow
