import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import CreationModalProgressContextProvider from '@app/src/context/CreationModalProgressContextProvider'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import { InquiryExportColumnsAccessor } from '@app/src/export-columns/inquiry'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { useSelectableRows } from '@app/src/hooks/useSelectableRows'
import AccessorInquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/AccessorInquiryHeader'
import RequestsFilters from '@app/src/pages/ResourceCollection/Filters/RequestsFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { GenericOrganization } from '@app/src/types/organizations'
import { Inquiry, InquiryStatus } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { br } from '@app/src/utils/translationMarkup'
import { ADMIN_ROLES, NotificationSeverity, ResourceTypes } from '@app/src/wf-constants'
import {
  ArrowForwardOutlined,
  DeleteOutlineOutlined,
  ListAltOutlined,
  NotificationsActiveOutlined,
} from '@mui/icons-material'
import { Button } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { AccessorInquiryRow } from '../Request/AccessorInquiryRow'
import { ViewTypeName } from './ManageRequestsScene'
import RequestsReminderModal, { REQUEST_TYPES } from './RequestsReminder/RequestsReminderModal'

interface AccessorRequestsSceneProps {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  setCreateDialogOpen?: () => void
}

const allowedFilters = [
  'template.id',
  'status',
  'periodType',
  'provider.websiteAddress',
  'provider.country.id',
  'provider.vatNumber',
  'provider.organization.id',
  'request.responseExternalStatus',
  'request.responseInternalStatus',
  'isActivated',
  'deadline',
  'createdAt',
  'activatedAt',
  'periodName',
  'provider.providerApprovalStatus',
  'provider.priority',
  'provider.finalRiskRating',
  'provider.activityStatus',
  'provider.ownerUserId',
  'provider.supplierUsage',
  'provider.tier',
  'provider.categoryOptions.id',
  'template.tags.tag',
  'provider.spends.amount',
  'provider.spends.periodName',
  'provider.assessments.totalLevel',
  'provider.assessments.intervalType',
  'provider.assessments.section1Level',
  'provider.assessments.section2Level',
  'provider.assessments.section3Level',
  'provider.assessments.section4Level',
  'provider.assessments.section5Level',
  'provider.assessments.assessmentTemplate.name',
]

export interface GroupedInquiries {
  responder: GenericOrganization
  requests: Inquiry[]
}

function groupInquiriesByResponder(requests: Inquiry[]): GroupedInquiries[] {
  const grouped = Object.groupBy(requests, request => request.provider.organizationId)

  return Object.entries(grouped).map(([organizationId, groupedRequests]) => ({
    responder: groupedRequests?.[0].provider.organization as GenericOrganization,
    requests: groupedRequests || [],
  }))
}

const AccessorRequestsScene: React.FC<AccessorRequestsSceneProps> = ({
  embedded,
  tabs,
  actionButtons = [],
  relatedContentFilter = [],
  setCreateDialogOpen,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()
  const [isRequestsReminderDialogOpen, openRequestsReminderDialog, closeRequestsReminderDialog] = useDialogState(false)

  const confirm = useConfirm()
  const { mutateAsync: deleteInquiries, isLoading: isDeletingInquiries } = useDeleteResource()
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { role } = useAuthentication().scope
  const isAdmin = ADMIN_ROLES.includes(role?.toLowerCase() ?? '')

  const basePayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: [
      'creatorOrganization',
      'request.responses',
      'request.responses.verifications',
      'template',
      'template.image',
      'provider.organization.contacts.user',
      'provider.ownerUser',
    ],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: pageInquiries,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Request,
    endpoint: endpoints.inquiriesCollection,
    payload,
  })

  const {
    allRows,
    selectedRowsIds,
    setSelectedRowsIds,
    isLoadingAllRows,
    isAllSelected,
    handleSelectAll,
    handleCheckboxChange,
    isHeaderChecked,
  } = useSelectableRows<Inquiry>({
    rowsInPage: pageInquiries,
    allRowsElements: {
      basePayload: payload,
      fetchKey: FetchKey.AllInquiries,
      endpoint: endpoints.inquiriesCollection,
      count,
    },
  })

  const inquiries = allRows.length ? allRows : pageInquiries

  const unhandledRequests = useMemo(
    () =>
      inquiries
        .filter(inquiry => selectedRowsIds.includes(inquiry.id))
        .filter(inquiry => inquiry.status !== InquiryStatus.Submitted && inquiry.status !== InquiryStatus.Approved)
        .filter(inquiry => !inquiry.provider.deletedAt),
    [selectedRowsIds, inquiries],
  )

  const unhandledRequestsByResponder = groupInquiriesByResponder(unhandledRequests)

  const handleCloseRequestReminder = () => {
    setSelectedRowsIds([])
    closeRequestsReminderDialog()
  }

  const confirmDeleteSelected = () => {
    confirm({
      description: formatMessage(
        { id: 'resourceCollections.delete.requests.dialogDescription' },
        { count: selectedRowsIds.length },
      ),
      buttonOrder: ['confirm', 'cancel'],
      confirmationText: formatMessage({ id: 'general.delete' }),
      cancellationText: formatMessage({ id: 'general.close' }),
    }).then(async () => {
      await deleteInquiries(
        { url: endpoints.inquiriesByIds(selectedRowsIds) },
        {
          onSuccess: () => {
            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
              severity: NotificationSeverity.success,
            })
            setSelectedRowsIds([])
            queryClient.invalidateQueries(FetchKey.Inquiry)
            queryClient.invalidateQueries(FetchKey.Response)
            queryClient.invalidateQueries(FetchKey.Request)
            queryClient.invalidateQueries(FetchKey.Answer)
            queryClient.invalidateQueries(FetchKey.ResponseItemCount)
            queryClient.invalidateQueries(FetchKey.Notification)
          },
        },
      )
    })
  }

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.manageRequests' })}
        tabs={tabs}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Inquiry}
                  count={count}
                  userFilter={apiFilters}
                  exportColumns={InquiryExportColumnsAccessor}
                  rawExportPayload={{
                    ...basePayload,
                    include: [...basePayload.include, 'target', 'request.responses.history.creatorUser'],
                  }}
                />,
              ),
            disabled: tabs?.activeTabParam === ViewTypeName.Overview || isLoading || isDeletingInquiries || count === 0,
          },
          ...actionButtons,
        ]}
        filter={
          <RequestsFilters
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
        buttonRow={
          selectedRowsIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.sendRequestReminder' },
                    { count: unhandledRequests.length },
                  ),
                  startIcon: <NotificationsActiveOutlined />,
                  onClick: openRequestsReminderDialog,

                  disabled:
                    !unhandledRequests.length || isLoading || isFetching || isLoadingAllRows || isDeletingInquiries,
                },
                ...insertIf(isAdmin, {
                  label: formatMessage(
                    { id: 'resourceCollections.delete.requests.deleteCount' },
                    { count: selectedRowsIds?.length ?? 0 },
                  ),
                  startIcon: <DeleteOutlineOutlined />,
                  onClick: confirmDeleteSelected,
                  disabled: isLoading || isFetching || isLoadingAllRows || isDeletingInquiries,
                }),
                ...insertIf<ActionButton>(count > pageSize, {
                  label: formatMessage({ id: 'resourceCollections.general.selectAllRequests' }, { count }),
                  variant: 'text',
                  onClick: handleSelectAll,

                  disabled: isLoading || isFetching || isAllSelected() || isDeletingInquiries,
                  loading: isLoadingAllRows,
                }),
              ]
            : undefined
        }
      >
        <Table<Inquiry>
          RowComponent={({ row }) => (
            <AccessorInquiryRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedInquiriesIds={selectedRowsIds}
            />
          )}
          HeaderComponent={() => (
            <AccessorInquiryHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              isHeaderChecked={isHeaderChecked}
              inquiriesInPage={pageInquiries}
              setSelectedInquiriesIds={setSelectedRowsIds}
            />
          )}
          data={pageInquiries}
          isLoading={isLoading || isFetching || isDeletingInquiries}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          emptyState={
            <EmptyState
              iconComponent={ListAltOutlined}
              title={formatMessage({ id: 'table.manageRequests.requestsEmptyState.title' })}
              description={formatMessage({ id: 'table.manageRequests.requestsEmptyState.description' }, { br })}
              sx={{ my: 2 }}
            >
              {setCreateDialogOpen && (
                <Button
                  variant="outlined"
                  endIcon={<ArrowForwardOutlined fontSize="small" />}
                  onClick={setCreateDialogOpen}
                >
                  {formatMessage({ id: 'table.manageRequests.requestsEmptyState.action' })}
                </Button>
              )}
            </EmptyState>
          }
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>
      <CreationModalProgressContextProvider>
        <RequestsReminderModal
          onClose={handleCloseRequestReminder}
          open={isRequestsReminderDialogOpen}
          unhandledRequests={unhandledRequests}
          unhandledRequestsByResponder={unhandledRequestsByResponder}
          requestType={REQUEST_TYPES.Questionnaire}
        />
      </CreationModalProgressContextProvider>
    </>
  )
}

export default AccessorRequestsScene
