import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import LoadingButton from '@app/src/components/LoadingButton'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import GrantAccessToSolutionDrawer from '@app/src/pages/Configurations/ConfigurationsPages/Organization/GrantAccessToSolutionDrawer'
import TransferDataToOtherOrganizationDrawer from '@app/src/pages/Configurations/ConfigurationsPages/Organization/TransferDataToOtherOrganizationDrawer'
import { Organization } from '@app/src/types/organizations'
import { Solutions } from '@app/src/wf-constants'
import { Delete, SwapHoriz } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Chip, CircularProgress, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { Auth0User } from '../Users/UsersConfig'
import OrganizationDeleteDialog from './OrganizationDeleteDialog'
import UserCard from './UserCard'

type Props = {
  id: string
}

const OrganizationDetails: React.FC<Props> = ({ id }) => {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { openDrawer, closeDrawer } = useDrawer()

  const { mutateAsync: transferAsync, isLoading: isTransferring } = useCreateResource<{
    sourceOrganizationId: number
    destinationOrganizationId: number
  }>()

  const copyToClipBoard = async (text: string) => {
    await navigator.clipboard.writeText(text)
  }

  const transferData = async (sourceOrganizationId: number, destinationOrganizationId: number) => {
    await transferAsync(
      {
        url: endpoints.transferOrganization,
        body: { sourceOrganizationId, destinationOrganizationId },
      },
      {
        onSuccess: () => showSnackbar({ message: 'Transfer successful', severity: 'success' }),
        onError: error => {
          if (error.statusCode === 403 && error.body?.message) {
            showSnackbar({ message: error.body.message, severity: 'error' })
          } else {
            showErrorNotification({ requestError: error })
          }
        },
      },
    )
  }

  const {
    data: organization,
    isLoading,
    isError,
  } = useFetchResource<Organization>({
    key: [FetchKey.Organization, id],
    endpoint: endpoints.organization(id),
  })

  const { data: usersWithAccessToOrganization } = useFetchResource<Auth0User[]>({
    key: [FetchKey.Users, id],
    endpoint: `${endpoints.userManagement}/byOrganizationId/${id}`,
  })

  if (isLoading)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    )

  if (!organization || isError) return <Box>organization Not Found</Box>

  const organizationHasOnlyTransparency =
    organization.assignedSolutions?.length === 1 &&
    organization.assignedSolutions[0].accountType.toLowerCase() === Solutions.Transparency.toLowerCase()

  return (
    <>
      <Box width="100%" px={5} py={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack marginBottom={3} paddingLeft={1} display="flex" alignItems="center">
            <Typography variant="h1">{organization.name}</Typography>
          </Stack>
          <Stack direction="row" alignItems="start" spacing={2}>
            <Button variant="contained" color="error" startIcon={<Delete />} onClick={openDeleteDialog}>
              Delete Organization
            </Button>
            <Tooltip
              title={
                !organizationHasOnlyTransparency &&
                'Cannot transfer when the organization has other solutions than Transparency'
              }
              disableHoverListener={organizationHasOnlyTransparency}
            >
              <span>
                <LoadingButton
                  variant="contained"
                  color="warning"
                  disabled={!organizationHasOnlyTransparency}
                  loading={isTransferring}
                  startIcon={<SwapHoriz />}
                  onClick={() =>
                    openDrawer(
                      <TransferDataToOtherOrganizationDrawer
                        closeDrawer={closeDrawer}
                        sourceOrganization={organization}
                        transferData={transferData}
                      />,
                    )
                  }
                >
                  Transfer organization data
                </LoadingButton>
              </span>
            </Tooltip>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() =>
                openDrawer(<GrantAccessToSolutionDrawer closeDrawer={closeDrawer} organization={organization} />)
              }
            >
              Add access to a solution
            </Button>
          </Stack>
        </Stack>
        <Box marginBottom={4} paddingLeft={1} display="flex">
          <Box marginRight={1}>
            <Tooltip title="Worldfavor Id">
              <Chip onClick={() => copyToClipBoard(organization.id.toString())} label={organization.id} />
            </Tooltip>
          </Box>
          {organization.assignedSolutions?.map(assignedSolution => (
            <Box key={assignedSolution.id} marginRight={1}>
              <Chip label={assignedSolution.accountType} />
            </Box>
          ))}
        </Box>
        <Stack>
          {usersWithAccessToOrganization?.map(user => (
            <UserCard key={user.auth0Id} user={user} organizationId={parseInt(id)} />
          ))}
        </Stack>
      </Box>
      <OrganizationDeleteDialog
        isOpen={isDeleteDialogOpen}
        closeDialog={closeDeleteDialog}
        organization={organization}
      />
    </>
  )
}

export default OrganizationDetails
