import { calculateStartsAndEndsAtDate } from '@app/src/components/CreateNewRequestModal/utils'
import Table from '@app/src/components/Table'
import { usePeriodName } from '@app/src/hooks/usePeriodName'
import React from 'react'
import { FormData } from '..'
import ReviewTableHeader from './ReviewTableHeader'
import { ReviewTableRow } from './ReviewTableRow'

export type ReviewTableData = {
  id: string
  period: string | number
  company: string
  deadline: string
}

type ReviewTableProps = {
  data: FormData
}

const ReviewTable: React.FC<ReviewTableProps> = ({ data }) => {
  const { periodEndsAt, periodStartsAt } = calculateStartsAndEndsAtDate({
    dateType: data.dateType,
    dateYear: data.dateYear,
  })
  const { getPeriodDisplayName } = usePeriodName()

  const reviewData = [
    {
      id: `${data.periodName}`,
      period: getPeriodDisplayName(periodStartsAt, periodEndsAt),
      company: data.responders.name,
      deadline: data.deadline,
    },
  ]

  return (
    <Table<ReviewTableData>
      RowComponent={ReviewTableRow}
      HeaderComponent={ReviewTableHeader}
      data={reviewData}
      count={reviewData?.length}
      page={1}
      pageSize={reviewData.length}
      isLoading={false}
      isError={false}
    />
  )
}

export default ReviewTable
