import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { useDeleteResource, useUpdateResource } from '@app/src/api/updateHooks'
import DrawerView, { DrawerViewProps } from '@app/src/components/Drawer/DrawerView'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import SwitchCell from '@app/src/components/Table/Cells/SwitchCell'
import { useAccount } from '@app/src/context/AccountContext'
import { useAmplitude } from '@app/src/context/AmplitudeContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import SetPublicContactDialog from '@app/src/pages/Configurations/ConfigurationsPages/Colleague/SetPublicContactDialog'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Contact, User } from '@app/src/types/resourceExplorer'
import { AmplitudeTrackingEvents, NotificationSeverity, Roles, WF_EMAIL_DOMAIN } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { PublicOffOutlined, PublicOutlined } from '@mui/icons-material'
import { ListItem, ListItemAvatar, ListItemText, Stack, SwitchProps } from '@mui/material'
import { capitalize } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router-dom'
import { useDrawer } from '../DrawerContext'
import { isCurrentUser } from './DrawerViewUser'

export type DrawerViewEditUserTransparencyProps = {
  user: User
} & Omit<DrawerViewProps, 'title'>

type UpdateUserBody = {
  organizationId?: number
  solutionType?: string
} & Pick<User, 'email' | 'role'>

const DrawerViewEditUserTransparency: React.FC<DrawerViewEditUserTransparencyProps> = ({ user, ...props }) => {
  const { closeDrawer } = useDrawer()
  const { account } = useAccount()
  const { trackEvent } = useAmplitude()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { solution } = useAuthentication().scope
  const { mutateAsync: mutateRole, isLoading: isMutatingRole } = useUpdateResource<UpdateUserBody>()
  const { mutate: mutateAddPublicContact, isLoading: isAddingPublicContact } =
    useUpdateResource<Pick<Contact, 'userId'>>()
  const { mutate: mutateDeletePublicContact, isLoading: isDeletingPublicContact } = useDeleteResource()
  const confirm = useConfirm()
  const { handleSubmit, control, reset } = useForm<Pick<User, 'role'>>({
    defaultValues: {
      role: user.role,
    },
  })
  const history = useHistory()
  const loggedInAsWorldfavorUser = account?.user?.email.endsWith(WF_EMAIL_DOMAIN)

  const [isPublic, setIsPublic] = useState(user.isPublicContact)
  const [isAdminRole, setIsAdminRole] = useState(user.role.toLowerCase() === Roles.Admin)
  const [isSetPublicContactDialogOpen, openSetPublicContactDialog, closeSetPublicContactDialog] = useDialogState()

  const { count: publicContactCount } = useFetchFacets({
    endpoint: endpoints.contactsWithFacets,
    key: FetchKey.ContactFacets,
    facetsParam: [{ name: 'id' }],
    filter: [
      {
        name: 'creatorOrganizationId',
        filters: [
          {
            value: account?.organization?.id,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
  })

  const updateRole = async (role: User['role']) => {
    const colleague: UpdateUserBody = {
      email: user.email,
      role,
      organizationId: account?.organization?.id,
      solutionType: capitalize(solution),
    }

    await mutateRole(
      { url: endpoints.saveSolutionMembership, body: colleague },
      {
        onSuccess: () => {
          //The colleagues doesn't update directly so adding a timeout solves this.
          setTimeout(() => {
            showSnackbar({
              message: formatMessage({ id: 'notifications.successfulUpdateRole' }),
              severity: NotificationSeverity.success,
            })

            queryClient.invalidateQueries(FetchKey.Colleague)
            reset({ role: capitalize(Roles.Admin) })
            setIsAdminRole(true)
          }, 1000)
        },
        onError: error => {
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const deletePublicContact = async () => {
    setIsPublic(false)

    await mutateDeletePublicContact(
      {
        url: endpoints.deleteContact(user.id),
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulUpdatePublicContact' }),
            severity: NotificationSeverity.success,
          })

          trackEvent({
            name: AmplitudeTrackingEvents.Organization.UpdatePublicContact.ContactDeleted,
          })

          queryClient.invalidateQueries(FetchKey.Colleague)
          queryClient.invalidateQueries(FetchKey.Contact)
        },
        onError: error => {
          setIsPublic(user.isPublicContact)
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const addPublicContact = async () => {
    setIsPublic(true)

    await mutateAddPublicContact(
      {
        url: endpoints.saveContact,
        body: {
          userId: user.id,
        },
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulUpdatePublicContact' }),
            severity: NotificationSeverity.success,
          })

          trackEvent({
            name: AmplitudeTrackingEvents.Organization.UpdatePublicContact.ContactUpdated,
          })

          queryClient.invalidateQueries(FetchKey.Colleague)
          queryClient.invalidateQueries(FetchKey.Contact)
        },
        onError: error => {
          setIsPublic(user.isPublicContact)
          showErrorNotification({ requestError: error, disableAutoClose: true })
        },
      },
    )
  }

  const goToPersonalSettings = () => {
    closeDrawer()
    history.push(generatePath(paths.userSettings, { page: 'settings' }))
  }

  const handleChangePublic: SwitchProps['onChange'] = async (_event, checked) => {
    if (!isAdminRole) {
      return confirm({
        title: formatMessage({ id: 'schemas.editUser.changePermission' }),
        content: formatMessage({ id: 'schemas.editUser.onlyAdminPublicContact' }),
        confirmationText: formatMessage({ id: 'schemas.editUser.setAsAdmin' }),
        cancellationButtonProps: { variant: 'text' },
        confirmationButtonProps: { variant: 'text' },
      }).then(() => {
        updateRole(Roles.Admin)
      })
    }

    const isOwnUser = user.email.toLowerCase() === account?.user?.email?.toLowerCase()
    if (checked && !isOwnUser && !loggedInAsWorldfavorUser)
      return confirm({
        title: formatMessage({ id: 'schemas.editUser.settingPublicContact' }),
        content: formatMessage({ id: 'schemas.editUser.colleagueMustSetThemselves' }),
        confirmationText: formatMessage({ id: 'general.close' }),
        confirmationButtonProps: { variant: 'text' },
        hideCancelButton: true,
      })

    if (!checked && publicContactCount < 2 && !loggedInAsWorldfavorUser) {
      return confirm({
        title: formatMessage({ id: 'schemas.editUser.anotherContactPerson' }),
        content: formatMessage({ id: 'schemas.editUser.assignAnotherContent' }),
        confirmationText: formatMessage({ id: 'general.close' }),
        confirmationButtonProps: { variant: 'text' },
        hideCancelButton: true,
      })
    }

    if (!checked && publicContactCount > 1) {
      return confirm({
        title: formatMessage({ id: 'general.areYouSure' }),
        content: formatMessage({ id: 'schemas.editUser.removePublicContact' }),
        confirmationText: formatMessage({ id: 'general.confirm' }),
        cancellationButtonProps: { variant: 'text' },
        confirmationButtonProps: { variant: 'text' },
      }).then(deletePublicContact)
    }

    if (checked) {
      openSetPublicContactDialog()
    }
  }

  const onSubmit = async (values: User): Promise<void> => {
    await updateRole(values.role)
    closeDrawer()
  }

  return (
    <>
      <DrawerView
        title={formatMessage({ id: 'general.edit' })}
        subTitle={user.name}
        buttons={[
          {
            label: formatMessage({ id: 'reporting.submit' }),
            variant: 'contained',
            loading: isMutatingRole,
            disabled:
              isCurrentUser(user.type.toLowerCase(), account?.user.id, user.id) ||
              user.isPublicContact ||
              isPublic ||
              isDeletingPublicContact ||
              isAddingPublicContact,
            type: 'submit',
          },
        ]}
        {...props}
        onFormSubmit={handleSubmit(onSubmit)}
      >
        <Stack p={2} gap={2}>
          <Select
            name="role"
            fieldLabel={formatMessage({ id: 'schemas.user.role' })}
            rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
            disabled={
              isCurrentUser(user.type.toLowerCase(), account?.user.id, user.id) ||
              isDeletingPublicContact ||
              isAddingPublicContact ||
              isMutatingRole
            }
            control={control}
            required
            enableAutoSelect
            options={[
              { value: capitalize(Roles.Admin), label: formatMessage({ id: 'roles.admin' }) },
              { value: capitalize(Roles.Contributor), label: formatMessage({ id: 'roles.contributor' }) },
            ]}
            defaultValue={user?.role.toLowerCase()}
          />

          <ListItem disablePadding>
            <ListItemAvatar>
              {isPublic ? <PublicOutlined color="action" /> : <PublicOffOutlined color="action" />}
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage({ id: 'schemas.user.publicContact' })}
              secondary={formatMessage({ id: 'schemas.user.publicWfNetwork' }, { isPublic })}
            />
            <SwitchCell
              disabled={isDeletingPublicContact || isAddingPublicContact || isMutatingRole}
              checked={isPublic}
              onChange={handleChangePublic}
              data-testid="switch-cell"
              disableCell
            />
          </ListItem>
        </Stack>
      </DrawerView>
      <SetPublicContactDialog
        isSetPublicContactDialogOpen={isSetPublicContactDialogOpen}
        closeSetPublicContactDialog={closeSetPublicContactDialog}
        addUserAsPublicContact={addPublicContact}
        goToPersonalSettings={goToPersonalSettings}
      />
    </>
  )
}

export default DrawerViewEditUserTransparency
