import { ActionButton } from '@app/src/components/ActionButtons'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { ConfigurationsParams } from '@app/src/pages/Configurations/ConfigurationsScene'
import CustomQuestionCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/CustomQuestionCollection'
import StandardQuestionCollection from '@app/src/pages/Configurations/SustainabilityLibraryPages/Questions/StandardQuestionCollection'
import { insertIf } from '@app/src/utils/helpersTs'
import { ADMIN_ROLES } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useParams, useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import SustainabilityLibraryCollection from '../SustainabilityLibraryCollection'

export enum QuestionsConfigTabs {
  Standard = 'standard',
  Custom = 'custom',
}

const QuestionsConfig: React.FC = () => {
  const { formatMessage } = useIntl()
  const { path } = useRouteMatch()
  const { role } = useAuthentication().scope
  const { configurationsPage, configurationsSubPage } = useParams<ConfigurationsParams>()
  const { questionBuilder } = useFlags()

  const canEditQuestions = questionBuilder && ADMIN_ROLES.includes(role?.toLowerCase() ?? '')

  return (
    <SustainabilityLibraryCollection
      actionTabs={[
        {
          type: QuestionsConfigTabs.Standard,
          url: QuestionsConfigTabs.Standard,
          label: formatMessage({ id: 'questionnaireConfig.standardTab' }),
          skipQueryParams: true,
        },
        {
          type: QuestionsConfigTabs.Custom,
          url: QuestionsConfigTabs.Custom,
          label: formatMessage({ id: 'questionnaireConfig.customTab' }),
          skipQueryParams: true,
        },
      ]}
      actionButtons={[
        ...insertIf<ActionButton>(canEditQuestions, {
          label: formatMessage({ id: 'resourceCollections.general.create' }),
          variant: 'contained',
          to: generatePath(paths.questionnaireBuilderCustomQuestion),
        }),
      ]}
      enablePadding={{
        top: false,
        bottom: false,
        left: false,
        right: false,
      }}
    >
      <Switch>
        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage,
            activeTabParam: QuestionsConfigTabs.Standard,
          })}
        >
          <StandardQuestionCollection />
        </Route>

        <Route
          path={generatePath(path, {
            configurationsPage,
            configurationsSubPage,
            activeTabParam: QuestionsConfigTabs.Custom,
          })}
        >
          <CustomQuestionCollection />
        </Route>
      </Switch>
    </SustainabilityLibraryCollection>
  )
}

export default QuestionsConfig
