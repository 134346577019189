import CheckboxCell from '@app/src/components/Table/Cells/CheckboxCell'
import CountryCell from '@app/src/components/Table/Cells/CountryCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import LinkCell from '@app/src/components/Table/Cells/LinkCell'
import ValueCell from '@app/src/components/Table/Cells/ValueCell'
import { Claim } from '@app/src/types/organizations'
import { TableRow } from '@mui/material'
import getCountryISO2 from 'country-iso-3-to-2'
import React from 'react'

interface ClaimsReviewRowProps {
  row: Claim
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>, claim: Claim) => void
  selectedClaimIds: Array<number>
}

export const ClaimsReviewRow: React.FC<ClaimsReviewRowProps> = ({ row: claim, onCheckboxChange, selectedClaimIds }) => {
  const isChecked = Boolean(selectedClaimIds?.find(selectedClaimId => selectedClaimId === claim.id))

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (claim?.id) onCheckboxChange(e, claim)
  }

  return (
    <TableRow selected={isChecked}>
      <CheckboxCell checked={isChecked} onChange={handleCheckboxChange} data-testid={`claim-${claim?.details?.name}`} />
      <ValueCell value={claim?.id} />
      <ValueCell value={claim?.details?.name} />
      <ValueCell value={claim?.reviewReason} />
      <CountryCell countryCode={getCountryISO2(claim?.details?.location?.country)} />
      <LinkCell href={claim.details.websiteAddress} />
      <ValueCell value={claim?.details?.vatNumber} />
      <ValueCell value={claim?.details?.referralCompanyName} />
      <ValueCell value={claim?.creatorUser?.name} />
      <ValueCell value={claim?.creatorUser?.email} />
      <DateCell value={claim?.createdAt} />
    </TableRow>
  )
}

export default ClaimsReviewRow
