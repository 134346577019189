import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewImportSpend from '@app/src/components/Drawer/Views/DrawerViewImportSpend'
import useCurrentProviderType from '@app/src/hooks/currentProviderType'
import { ArrowForwardIosOutlined, EuroOutlined, UploadFileOutlined } from '@mui/icons-material'
import { Box, Divider, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import { capitalize } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import DrawerView, { DrawerViewProps } from '../DrawerView'

type DrawerViewImportInitialStepProps = {
  solution?: string
  providerType: string
  allowedFilters: string[]
  openImportProviderModal: () => void
} & Omit<DrawerViewProps, 'title'>

const AVATAR_STYLING = {
  minWidth: 'unset',
  mr: 2,
  bgcolor: 'secondary.main',
  width: 40,
  height: 40,
  color: 'common.white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const DrawerViewImportInitialStep: React.FC<DrawerViewImportInitialStepProps> = ({
  solution,
  providerType,
  allowedFilters,
  openImportProviderModal,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const resourceType = useCurrentProviderType()
  const { openDrawer, closeDrawer } = useDrawer()

  return (
    <DrawerView title={formatMessage({ id: 'import.import' })} {...props}>
      <Box px={2} mt={2}>
        <Box>
          <ListItemButton
            onClick={() => {
              closeDrawer()
              openImportProviderModal()
            }}
          >
            <ListItemAvatar sx={{ ...AVATAR_STYLING }}>
              <UploadFileOutlined color="inherit" />
            </ListItemAvatar>
            <ListItemText
              primary={formatMessage(
                { id: 'import.providerExcel' },
                {
                  provider: formatMessage(
                    {
                      id: `general.${capitalize(resourceType)}`,
                    },
                    { count: 2 },
                  ).toLowerCase(),
                },
              )}
              secondary={formatMessage(
                { id: 'import.providerDescription' },
                {
                  provider: formatMessage(
                    {
                      id: `general.${capitalize(resourceType)}`,
                    },
                    { count: 2 },
                  ).toLowerCase(),
                },
              )}
            />
            <ArrowForwardIosOutlined />
          </ListItemButton>
        </Box>
        <Divider />
        <Box>
          <ListItemButton
            onClick={() =>
              openDrawer(<DrawerViewImportSpend providerType={providerType} allowedFilters={allowedFilters} />)
            }
          >
            <ListItemAvatar sx={{ ...AVATAR_STYLING }}>
              <EuroOutlined />
            </ListItemAvatar>
            <ListItemText primary="Import spend (Excel)" secondary="Add spend in bulk for multiple periods" />
            <ArrowForwardIosOutlined />
          </ListItemButton>
        </Box>
      </Box>
    </DrawerView>
  )
}

export default DrawerViewImportInitialStep
