import { usePeriodName } from '@app/src/hooks/usePeriodName'
import { Typography } from '@mui/material'
import React from 'react'
import { NO_PERIOD_PERIOD_NAME } from '../../CreateNewRequestModal/PeriodSelector/PeriodSelector'
import ParentCellOrFragment from './ParentCellOrFragment'

type PeriodNameCellProps = {
  periodName: string
  disableCell?: boolean
}

const PeriodNameCell: React.FC<PeriodNameCellProps> = ({ periodName, disableCell }) => {
  const { formatPeriodName } = usePeriodName()

  return (
    <ParentCellOrFragment disableCell={disableCell}>
      <Typography variant="body1" color={periodName === NO_PERIOD_PERIOD_NAME ? 'textSecondary' : undefined}>
        {formatPeriodName(periodName)}
      </Typography>
    </ParentCellOrFragment>
  )
}

export default PeriodNameCell
