import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo } from 'react'

import { Alert } from '@mui/material'
import endpoints from '@app/src/api/endpoints'
import ProviderSelectorWithFilters from '@app/src/components/CreateNewRequestModal/ProviderSelectorWithFilters'
import CreationModalContainer from '@app/src/components/CreationModal/CreationModalContainer'
import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import { Option } from '@app/src/components/Form/Select'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import { useCreationModalProgress } from '@app/src/context/CreationModalProgressContext'
import { useQuestionnairePeriodOptions } from '@app/src/hooks/questionnairePeriodOptions'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { SortOrder } from '@app/src/types/filter'
import { QuestionnaireTemplate, Request } from '@app/src/types/resourceExplorer'
import { BaseFormData } from '..'
import RequestQuestionStep from '../RequestQuestionStep'
import ReviewTable from '../ReviewTable'
import { Periods } from '../PeriodSelector/PeriodSelector'

const useStyles = makeStyles(({ spacing }) => ({
  infoAlert: {
    marginTop: spacing(2),
  },
}))

type WfReportProps = {
  onClose: () => void
  onBack: () => void
  disableInitialBack?: boolean
  selectedQuestionnaireTemplates?: QuestionnaireTemplate[] | undefined
}

export type QuestionStepData = {
  [key: string]: string
}

export enum WF_REPORT_QUESTION_KEYS {
  TEMPLATE_IDS = 'templateIds',
  PERIOD_NAME = 'dateYear',
  RESPONDERS = 'responders',
  DEADLINE = 'deadline',
  REVIEW = 'review',
  ACTIVATE_AT = 'activateAt',
}

export type FormData = BaseFormData

const WfReport: React.FC<WfReportProps> = ({ onClose, onBack, disableInitialBack, selectedQuestionnaireTemplates }) => {
  const { formatMessage } = useIntl()
  const { activeStep, setTotalSteps } = useCreationModalProgress()
  const { questionnaireYearOptions } = useQuestionnairePeriodOptions()
  const classes = useStyles()

  const formMethods = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: {
      templateIds: selectedQuestionnaireTemplates?.map(x => ({ id: x.id, title: x.title })) ?? [],
      responders: [],
      dateType: Periods.Yearly,
      deadline: '',
    },
  })
  const { control, getValues, setValue } = formMethods

  const WF_REPORT_QUESTIONS = [
    {
      title: formatMessage({ id: 'form.createRequest.selectTemplateTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectTemplateBody' }),
      fieldnames: [WF_REPORT_QUESTION_KEYS.TEMPLATE_IDS],
      children: (
        <Select<{ title: string; id: number }, Request>
          name={WF_REPORT_QUESTION_KEYS.TEMPLATE_IDS}
          fieldLabel={formatMessage({ id: 'form.createRequest.selectTemplateLabel' })}
          control={control}
          required
          enableAutoSelect
          multiple
          forceFetch
          navigation={{
            url: endpoints.worldfavorTemplates,
            type: 'post',
            postObject: {
              include: [],
              filter: [],
              sort: { target: 'title', order: SortOrder.ASCENDING },
            },
          }}
          findSelectedValue={(value, option): boolean => value?.id === option?.value?.id}
          objectToOption={({ title, id }: Request): Option<{ title: string; id: number }> => ({
            label: title,
            value: { title, id },
          })}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectPeriodTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectPeriodBody' }),
      fieldnames: [WF_REPORT_QUESTION_KEYS.PERIOD_NAME],
      children: (
        <Select
          options={questionnaireYearOptions}
          fieldLabel={formatMessage({ id: 'form.createRequest.selectPeriodLabel' })}
          onInputChange={(e, value) => {
            if (!value) setValue(WF_REPORT_QUESTION_KEYS.PERIOD_NAME, undefined)
          }}
          defaultValue={-1}
          required
          enableAutoSelect
          name={WF_REPORT_QUESTION_KEYS.PERIOD_NAME}
          control={control}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectCompaniesTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectCompaniesBody' }),
      fieldnames: [WF_REPORT_QUESTION_KEYS.RESPONDERS],
      children: <ProviderSelectorWithFilters control={control} setValue={setValue} />,
    },
    {
      title: formatMessage({ id: 'form.createRequest.selectDeadlineTitle' }),
      description: formatMessage({ id: 'form.createRequest.selectDeadlineBody' }),
      fieldnames: [WF_REPORT_QUESTION_KEYS.DEADLINE],
      hasSkipButton: true,
      children: (
        <ControlledDateField
          disablePast
          fieldLabel={formatMessage({ id: 'form.createRequest.selectDeadlineLabel' })}
          control={control}
          name={WF_REPORT_QUESTION_KEYS.DEADLINE}
          slotProps={{ textField: { fullWidth: true } }}
        />
      ),
    },
    {
      title: formatMessage({ id: 'form.createRequest.reviewTitle' }),
      description: (
        <Alert severity="info" className={classes.infoAlert}>
          {formatMessage({ id: 'form.createRequest.reviewInfo' })}
        </Alert>
      ),
      fieldnames: [],
      children: <ReviewTable data={getValues()} />,
    },
  ]

  const activeQuestion = useMemo(() => WF_REPORT_QUESTIONS[activeStep - 1], [activeStep])

  useEffect(() => {
    setTotalSteps(WF_REPORT_QUESTIONS.length)
  }, [])

  return (
    <CreationModalContainer title={formatMessage({ id: 'form.createRequest.wfReportTitle' })} onClose={onClose}>
      <FormProvider {...formMethods}>
        {activeQuestion && (
          <RequestQuestionStep
            fieldnames={activeQuestion.fieldnames}
            title={activeQuestion.title}
            description={activeQuestion.description}
            key={activeQuestion.title}
            hasSkipButton={activeQuestion.hasSkipButton}
            onClose={onClose}
            onBack={onBack}
            disableInitialBack={disableInitialBack}
          >
            {activeQuestion.children}
          </RequestQuestionStep>
        )}
      </FormProvider>
    </CreationModalContainer>
  )
}

export default WfReport
