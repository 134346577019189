import { NotificationItem } from '@app/src/pages/Notifications/NotificationsScene'
import { ViewTypeName } from '@app/src/pages/ResourceCollection/Collections/ManageRequests/ManageRequestsScene'
import { InquiryStatus, QuestionnaireTypeEnum } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { MenuBookOutlined } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router'

export const useInquiryDeletedNotification = () => {
  const { formatMessage } = useIntl()
  const confirm = useConfirm()
  const history = useHistory()

  const getActionAndTitleRespondedShared = (notification: NotificationItem) => {
    const notificationTitle = formatMessage(
      { id: 'schemas.notification.inquiryDeleted.sharedReported' },
      {
        companyName: <strong>{notification.creatorOrganizationName}</strong>,
        templateName: <strong>{notification.objectTitle}</strong>,
      },
    )
    const confirmTitle = formatMessage(
      { id: 'schemas.notification.inquiryDeleted.sharedReportedDialogTitle' },
      { companyName: notification.creatorOrganizationName },
    )
    if (notification.templateIsStandard) {
      return {
        title: notificationTitle,
        action: () =>
          confirm({
            title: confirmTitle,
            description: formatMessage(
              { id: 'schemas.notification.inquiryDeleted.wfStandardReportedDialogDescription' },
              {
                companyName: notification.creatorOrganizationName,
                templateName: notification.objectTitle,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              },
            ),
            confirmationText: formatMessage({ id: 'schemas.notification.inquiryDeleted.viewSustainabilityProfile' }),
            cancellationText: formatMessage({ id: 'general.close' }),
            confirmationButtonProps: { variant: 'text', startIcon: <MenuBookOutlined /> },
            cancellationButtonProps: { variant: 'text' },
            buttonOrder: ['confirm', 'cancel'],
          }).then(() => history.push(generatePath(paths.sustainabilityProfile))),
      }
    }

    return {
      title: notificationTitle,
      action: () =>
        confirm({
          title: confirmTitle,
          description: formatMessage(
            { id: 'schemas.notification.inquiryDeleted.sharedReportedDialogDescription' },
            {
              companyName: notification.creatorOrganizationName,
              templateName: notification.objectTitle,
              b: (chunks: React.ReactNode) => <b>{chunks}</b>,
            },
          ),
          confirmationText: formatMessage({ id: 'schemas.notification.inquiryDeleted.viewAllResponses' }),
          cancellationText: formatMessage({ id: 'general.close' }),
          confirmationButtonProps: { variant: 'text' },
          cancellationButtonProps: { variant: 'text' },
          buttonOrder: ['confirm', 'cancel'],
        }).then(() => history.push(generatePath(paths.manageRequest, { view: ViewTypeName.Responses }))),
    }
  }

  const getInquiryDeletedNotification = (notification: NotificationItem) => {
    const hasBeenRespondedTo = notification.inquiryStatus !== InquiryStatus.Requested
    if (hasBeenRespondedTo && notification.questionnaireTemplateType === QuestionnaireTypeEnum.Shared)
      return getActionAndTitleRespondedShared(notification)

    const dialogDescriptionId = hasBeenRespondedTo
      ? 'schemas.notification.inquiryDeleted.regularReportedDialogDescription'
      : 'schemas.notification.inquiryDeleted.nonReportedDialogDescription'

    return {
      title: formatMessage(
        { id: 'schemas.notification.inquiryDeleted.deleted' },
        { templateName: <strong>{notification.objectTitle}</strong> },
      ),
      action: () =>
        confirm({
          title: formatMessage({ id: 'schemas.notification.inquiryDeleted.deletedDialogTitle' }),
          description: formatMessage(
            { id: dialogDescriptionId },
            { companyName: notification.creatorOrganizationName, templateName: notification.objectTitle },
          ),
          confirmationButtonProps: { variant: 'text' },
          confirmationText: formatMessage({ id: 'general.close' }),
          hideCancelButton: true,
        }),
    }
  }

  return { getInquiryDeletedNotification }
}
