import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import HoverDialog from '@app/src/components/HoverDialog'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { ProviderPriorityValues } from '@app/src/wf-constants'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import GraphLegend from '../../GraphLegend'
import { companiesListAllowedFilters } from './CompaniesList'
import CompaniesStatisticGraph from './CompaniesStatisticGraph'
import { handleFilterValue } from './CompaniesStatistics'

const PriorityGraph = () => {
  const { palette } = useTheme()
  const { formatMessage } = useIntl()
  const [highlightedPriority, setHighlightedPriority] = useState<string | number>()
  const filters = useGetApiQueryFilters(companiesListAllowedFilters)
  const {
    facets: [providerPriority],
  } = useFetchFacets({
    key: FetchKey.ProviderFacets,
    endpoint: endpoints.providersWithFacets,
    facetsParam: [{ name: 'priority' }],
    filter: filters,
  })

  const notSelectedCount =
    providerPriority?.find(company => company.label === ProviderPriorityValues.NotSelected)?.count ?? 0
  const notAPriorityCount =
    providerPriority?.find(company => company.label === ProviderPriorityValues.NotAPriority)?.count ?? 0
  const lowPriorityCount = providerPriority?.find(company => company.label === ProviderPriorityValues.Low)?.count ?? 0
  const mediumPriorityCount =
    providerPriority?.find(company => company.label === ProviderPriorityValues.Medium)?.count ?? 0
  const highPriorityCount = providerPriority?.find(company => company.label === ProviderPriorityValues.High)?.count ?? 0
  const veryHighPriorityCount =
    providerPriority?.find(company => company.label === ProviderPriorityValues.VeryHigh)?.count ?? 0

  const totalCompanies =
    notSelectedCount +
    notAPriorityCount +
    lowPriorityCount +
    mediumPriorityCount +
    highPriorityCount +
    veryHighPriorityCount

  return (
    <HoverDialog
      content={
        <Box p={2} onMouseEnter={() => setHighlightedPriority(undefined)}>
          <Stack spacing={1}>
            <Typography variant="overline" color="textSecondary" noWrap>
              {formatMessage({ id: 'dashboard.sourcing.companyStatistics.companiesByPriority' })}
            </Typography>
            <Filter operator={Operators.In} name="priority">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedPriority === ProviderPriorityValues.VeryHigh ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedPriority(ProviderPriorityValues.VeryHigh)}
                  onMouseLeave={() => setHighlightedPriority(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.VeryHigh))}
                >
                  <GraphLegend
                    color={palette.visualization[1]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.priorityValues.VeryHighWithCount' },
                      { count: veryHighPriorityCount },
                    )}
                  />
                  {highlightedPriority === ProviderPriorityValues.VeryHigh && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.VeryHigh))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="priority">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedPriority === ProviderPriorityValues.High ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedPriority(ProviderPriorityValues.High)}
                  onMouseLeave={() => setHighlightedPriority(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.High))}
                >
                  <GraphLegend
                    color={palette.visualization[2]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.priorityValues.HighWithCount' },
                      { count: highPriorityCount },
                    )}
                  />
                  {highlightedPriority === ProviderPriorityValues.High && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.High))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="priority">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedPriority === ProviderPriorityValues.Medium ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedPriority(ProviderPriorityValues.Medium)}
                  onMouseLeave={() => setHighlightedPriority(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.Medium))}
                >
                  <GraphLegend
                    color={palette.visualization[3]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.priorityValues.MediumWithCount' },
                      { count: mediumPriorityCount },
                    )}
                  />
                  {highlightedPriority === ProviderPriorityValues.Medium && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.Medium))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="priority">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedPriority === ProviderPriorityValues.Low ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedPriority(ProviderPriorityValues.Low)}
                  onMouseLeave={() => setHighlightedPriority(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.Low))}
                >
                  <GraphLegend
                    color={palette.visualization[7]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.priorityValues.LowWithCount' },
                      { count: lowPriorityCount },
                    )}
                  />
                  {highlightedPriority === ProviderPriorityValues.Low && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.Low))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>

            <Filter operator={Operators.In} name="priority">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedPriority === ProviderPriorityValues.NotAPriority ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedPriority(ProviderPriorityValues.NotAPriority)}
                  onMouseLeave={() => setHighlightedPriority(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.NotAPriority))}
                >
                  <GraphLegend
                    color={palette.visualization[10]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.priorityValues.NotAPriorityWithCount' },
                      { count: notAPriorityCount },
                    )}
                  />
                  {highlightedPriority === ProviderPriorityValues.NotAPriority && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.NotAPriority))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
            <Filter operator={Operators.In} name="priority">
              {({ onChange, value }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius={2}
                  bgcolor={highlightedPriority === ProviderPriorityValues.NotSelected ? 'grey.100' : 'white'}
                  sx={{ '&:hover': { bgcolor: 'grey.100', borderRadius: 2, cursor: 'pointer' } }}
                  onMouseEnter={() => setHighlightedPriority(ProviderPriorityValues.NotSelected)}
                  onMouseLeave={() => setHighlightedPriority(undefined)}
                  onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.NotSelected))}
                >
                  <GraphLegend
                    color={palette.grey[300]}
                    variant="body1"
                    legend={formatMessage(
                      { id: 'schemas.provider.priorityValues.NotSelectedWithCount' },
                      { count: notSelectedCount },
                    )}
                  />
                  {highlightedPriority === ProviderPriorityValues.NotSelected && (
                    <Button onClick={() => onChange(handleFilterValue(value, ProviderPriorityValues.NotSelected))}>
                      {formatMessage({ id: 'general.filter' })}
                    </Button>
                  )}
                </Stack>
              )}
            </Filter>
          </Stack>
        </Box>
      }
    >
      <Box>
        <CompaniesStatisticGraph
          highlightSetter={setHighlightedPriority}
          title={formatMessage({ id: `dashboard.sourcing.companyStatistics.priority` })}
          data={[
            { value: veryHighPriorityCount, name: ProviderPriorityValues.VeryHigh },
            { value: highPriorityCount, name: ProviderPriorityValues.High },
            { value: mediumPriorityCount, name: ProviderPriorityValues.Medium },
            { value: lowPriorityCount, name: ProviderPriorityValues.Low },
            { value: notAPriorityCount, name: ProviderPriorityValues.NotAPriority },
            { value: notSelectedCount, name: ProviderPriorityValues.NotSelected },
          ]}
          colors={[
            palette.visualization[1],
            palette.visualization[2],
            palette.visualization[3],
            palette.visualization[7],
            palette.visualization[10],
            palette.grey[300],
          ]}
          value={Math.ceil(100 - (notSelectedCount * 100) / totalCompanies)}
        />
      </Box>
    </HoverDialog>
  )
}

export default PriorityGraph
