import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResourceWithPost } from '@app/src/api/fetchHooks'
import { useDeleteResource } from '@app/src/api/updateHooks'
import { ActionButton } from '@app/src/components/ActionButtons'
import Markdown from '@app/src/components/Markdown'
import TagsCell from '@app/src/components/Table/Cells/TagsCell'
import { useAccount } from '@app/src/context/AccountContext'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import AnswerClassificationPreviewCard from '@app/src/pages/QuestionEditor/AnswerClassification/AnswerClassificationPreviewCard'
import QuestionPreview from '@app/src/pages/Questionnaire/Provider/Report/QuestionPreview'
import { Question, QuestionnaireTemplate } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import { ADMIN_ROLES } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { DeleteOutline, ModeEditOutline } from '@mui/icons-material'
import { alpha, Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useConfirm } from 'material-ui-confirm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import { SUSTAINABILITY_LIBRARY_PAGE_IDS } from '../../SustainabilityLibraryScene'
import { QuestionnairesConfigTabs } from '../Questionnaires/QuestionnairesConfig'
import SustainabilityLibraryCollection from '../SustainabilityLibraryCollection'

type QuestionnaireConfigProps = {
  id: string
}

const QuestionConfig: React.FC<QuestionnaireConfigProps> = ({ id }) => {
  const { formatMessage } = useIntl()
  const { account } = useAccount()
  const confirm = useConfirm()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: isDeleting } = useDeleteResource<QuestionnaireTemplate>()
  const { showSnackbar } = useSnackbar()
  const { showErrorNotification } = useErrorNotification()
  const { role } = useAuthentication().scope
  const { questionBuilder } = useFlags()
  const {
    data: question,
    isLoading,
    isError,
  } = useFetchResourceWithPost<Question>({
    endpoint: endpoints.questionById(id),
    body: ['options.category', 'questionType', 'unit', 'requestCollection', 'questionAnswerClassificationRules'],
    key: [FetchKey.Questions, id],
  })

  const handleDelete = async () => {
    await mutateAsync(
      {
        url: endpoints.deleteQuestion(id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(FetchKey.Questions)
          showSnackbar({
            message: formatMessage({ id: 'notifications.successfulResourceDelete' }),
            severity: 'success',
          })
          history.push(
            generatePath(paths.sustainabilityLibrary, {
              configurationsPage: SUSTAINABILITY_LIBRARY_PAGE_IDS.Questions,
              configurationsSubPage: QuestionnairesConfigTabs.Custom,
            }),
          )
        },
        onError: error => {
          showErrorNotification({ requestError: error })
        },
      },
    )
  }

  const isQuestionOwner = account?.organization?.id === question?.creatorOrganizationId
  const canEditQuestion = questionBuilder && isQuestionOwner && ADMIN_ROLES.includes(role?.toLowerCase() ?? '')

  if (isLoading || !question || isError)
    return (
      <SustainabilityLibraryCollection enablePadding={{ right: true }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h3" gutterBottom>
                {formatMessage({ id: 'table.emptyStateTitle' })}
              </Typography>
              <Typography>{formatMessage({ id: 'table.errorTitle' })}</Typography>
            </>
          )}
        </Box>
      </SustainabilityLibraryCollection>
    )

  return (
    <SustainabilityLibraryCollection
      title={question.questionText || formatMessage({ id: 'general.noTitle' })}
      actionButtons={[
        ...insertIf<ActionButton>(canEditQuestion, {
          label: formatMessage({ id: 'general.delete' }),
          variant: 'outlined',
          startIcon: <DeleteOutline />,
          loading: isDeleting,
          onClick: () =>
            confirm({
              title: formatMessage({ id: 'general.areYouSure' }),
              content: formatMessage({ id: 'templateBuilder.deleteQuestion' }, { br: <br /> }),
              confirmationText: formatMessage({ id: 'general.delete' }),
              confirmationButtonProps: { variant: 'text' },
              cancellationButtonProps: { variant: 'text' },
              buttonOrder: ['confirm', 'cancel'],
            }).then(handleDelete),
        }),
        ...insertIf<ActionButton>(canEditQuestion, {
          label: formatMessage({ id: 'general.edit' }),
          variant: 'contained',
          startIcon: <ModeEditOutline />,
          disabled: isDeleting,
          to: generatePath(paths.questionnaireBuilderCustomQuestion, {
            questionId: question.id,
          }),
        }),
      ]}
      enablePadding={{ right: false, bottom: false, left: false }}
    >
      <Grid container py={2} justifyContent="center" rowGap={4}>
        <Grid item bgcolor="common.white" spacing={3} xs={8} px={4} pb={3} borderRadius={2}>
          <QuestionPreview question={question} />
        </Grid>
        {Boolean(question.guidance) && (
          <Grid item xs={8} py={4} px={4} border={`1px solid ${alpha('#000', 0.12)}`} borderRadius={2}>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              {formatMessage({ id: 'questionsConfig.questionSupplierGuidance' })}
            </Typography>
            <Typography variant="body1">
              <Markdown>{question.guidance}</Markdown>
            </Typography>
          </Grid>
        )}
        {Boolean(question.accessorGuidance) && (
          <Grid item xs={8} py={4} px={4} border={`1px solid ${alpha('#000', 0.12)}`} borderRadius={2}>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              {formatMessage({ id: 'questionsConfig.questionAccessorGuidance' })}
            </Typography>
            <Typography variant="body1">
              <Markdown>{question.accessorGuidance}</Markdown>
            </Typography>
          </Grid>
        )}
        {canEditQuestion && Boolean(question.questionAnswerClassificationRules?.length) && (
          <Grid
            item
            bgcolor="common.white"
            xs={8}
            py={4}
            px={4}
            border={`1px solid ${alpha('#000', 0.12)}`}
            borderRadius={2}
          >
            <AnswerClassificationPreviewCard
              questionAnswerClassifications={question.questionAnswerClassificationRules}
            />
          </Grid>
        )}
        {question.isStandard && Boolean(question.categorizations.length) && (
          <Grid item xs={8} py={4} px={4} border={`1px solid ${alpha('#000', 0.12)}`} borderRadius={2}>
            <Typography variant="subtitle1" sx={{ mb: 3 }}>
              {formatMessage({ id: 'templateBuilder.questionTags' })}
            </Typography>
            <TagsCell
              amountOfTagsShow={30}
              tags={question.categorizations.flatMap(c => c.values.map(v => v.name))}
              disableCell
            />
          </Grid>
        )}
      </Grid>
    </SustainabilityLibraryCollection>
  )
}

export default QuestionConfig
