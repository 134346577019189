import endpoints from '@app/src/api/endpoints'
import { FetchFacetsOptions, FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import { AssessmentTemplate, AssessmentType, RiskStatus, RiskType } from '@app/src/types/resourceExplorer'
import { Box, CircularProgress, Stack } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import useAssessmentOverviewChartOptions, {
  AssessmentOverviewGraphDataPoint,
} from '@app/src/pages/Assessments/useAssessmentOverviewChartOptions'
import { FacetItem } from '@app/src/pages/ResourceCollection'
import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { FilterGroup, Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { RiskTableView } from '@app/src/types/organizations'

type AssessmentRiskIndexBarChartProps = {
  assessmentTemplate: AssessmentTemplate
  userFilters?: FilterGroup[]
  riskType: RiskType
  riskScreeningItems: RiskTableView[]
  getEChartsRef?: (eChartsRef: React.MutableRefObject<null | ReactEChartsCore>) => void
  onClick?: (
    riskStatus: RiskStatus | undefined,
    assessmentLevel: number | undefined,
    providerIds: Array<number> | undefined,
  ) => void
}

const AssessmentRiskIndexBarChart: React.FC<AssessmentRiskIndexBarChartProps> = ({
  assessmentTemplate,
  userFilters = [],
  riskType,
  riskScreeningItems,
  getEChartsRef,
  onClick,
}) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const { formatMessage } = useIntl()
  const isBaselineAssessment = assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment
  const maxLevel = assessmentTemplate.levels ?? 5
  const has5Levels = maxLevel === 5

  if (eChartsRef && getEChartsRef) getEChartsRef(eChartsRef)

  const filters: FilterGroup[] = [
    { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
  ]

  const userFiltersWithoutProviderFilterGroup = useMemo(
    () => userFilters.filter(x => x.name !== 'provider.id'),
    [userFilters],
  )
  const dataPerRiskStatus: {
    [index: string]: { providerIds: Array<number>; filters: Array<FilterGroup>; groupName: string }
  } = useMemo(
    () =>
      Object.values(RiskStatus).reduce((acc, riskStatus) => {
        const providerIdsWithRiskStatus = riskScreeningItems
          .filter(x => {
            const countryRisks = x.parentObject.country?.risks

            return (
              countryRisks?.some(
                riskSource => riskSource.riskTypeId === riskType.id && riskSource.status === riskStatus,
              ) ?? false
            )
          })
          .map(x => x.parentObject.id)

        return {
          ...acc,
          [riskStatus]: {
            providerIds: providerIdsWithRiskStatus,
            groupName: formatMessage({ id: `schemas.risk.${riskStatus}` }),
            filters: [
              ...filters,
              ...userFiltersWithoutProviderFilterGroup,
              { name: 'provider.id', filters: [{ value: providerIdsWithRiskStatus, operator: Operators.In }] },
            ],
          },
        }
      }, {}),
    [riskScreeningItems, riskType, filters, userFiltersWithoutProviderFilterGroup],
  )

  const toLevelChartArray = useCallback(
    (levelFacets: Array<FacetItem>, riskStatus: RiskStatus): Array<AssessmentOverviewGraphDataPoint> =>
      Array.from(Array(maxLevel).keys()).map(i => {
        const level = i + 1
        const levelFaceItem = levelFacets.find(f => f.value === level)
        return {
          value: Number(levelFaceItem?.count ?? 0),
          level: level,
          riskStatus: riskStatus,
          groupName: dataPerRiskStatus[riskStatus].groupName,
          providerIds: dataPerRiskStatus[riskStatus].providerIds,
        }
      }),
    [dataPerRiskStatus, maxLevel],
  )

  const basePropsForUseFetchFacets: FetchFacetsOptions = {
    key: [FetchKey.AssessmentFacets, 'levels'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'totalLevel', isEnum: true }],
    options: {
      enabled: Boolean(riskScreeningItems.length),
      staleTime: 60000,
    },
  }

  const {
    facets: [levelFacets_lowRisk = []],
    isFetched: isFetched_lowRisk,
  } = useFetchFacets({
    ...basePropsForUseFetchFacets,
    filter: [...dataPerRiskStatus[RiskStatus.Low].filters],
  })

  const {
    facets: [levelFacets_mediumRisk = []],
    isFetched: isFetched_mediumRisk,
  } = useFetchFacets({
    ...basePropsForUseFetchFacets,
    filter: [...dataPerRiskStatus[RiskStatus.Medium].filters],
  })

  const {
    facets: [levelFacets_highRisk = []],
    isFetched: isFetched_highRisk,
  } = useFetchFacets({
    ...basePropsForUseFetchFacets,
    filter: [...dataPerRiskStatus[RiskStatus.High].filters],
  })

  const {
    facets: [levelFacets_extremeRisk = []],
    isFetched: isFetched_extremeRisk,
  } = useFetchFacets({
    ...basePropsForUseFetchFacets,
    filter: [...dataPerRiskStatus[RiskStatus.Extreme].filters],
  })

  const isLoadingLevels = !isFetched_lowRisk || !isFetched_mediumRisk || !isFetched_highRisk || !isFetched_extremeRisk

  const levelChartObj = [
    ...toLevelChartArray(levelFacets_lowRisk, RiskStatus.Low),
    ...toLevelChartArray(levelFacets_mediumRisk, RiskStatus.Medium),
    ...toLevelChartArray(levelFacets_highRisk, RiskStatus.High),
    ...toLevelChartArray(levelFacets_extremeRisk, RiskStatus.Extreme),
  ]

  const options = useAssessmentOverviewChartOptions(
    levelChartObj,
    ChartType.Horizontal,
    has5Levels,
    isBaselineAssessment,
    [
      formatMessage({ id: 'schemas.risk.CLow' }),
      formatMessage({ id: 'schemas.risk.DMedium' }),
      formatMessage({ id: 'schemas.risk.EHigh' }),
      formatMessage({ id: 'schemas.risk.FExtreme' }),
    ],
  )

  const events = useMemo(
    () => ({
      click: ({
        data,
        event,
      }: {
        data: AssessmentOverviewGraphDataPoint
        event: { target: { parent: { type: string } } }
      }) => {
        const clickedRiskStatusLabel = event.target.parent.type === 'text'

        if (!clickedRiskStatusLabel && !data.value) {
          return
        }

        if (!data.riskStatus || !data.providerIds?.length) return

        if (onClick) {
          onClick(data.riskStatus, clickedRiskStatusLabel ? undefined : data.level, data.providerIds)
        }
      },
    }),
    [],
  )

  if (isLoadingLevels) {
    return (
      <Stack height="90%" flexGrow={1}>
        <Stack flexGrow={1} alignItems="center" justifyContent="center" width="100%">
          <CircularProgress />
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <Box height={384} display="flex" flexDirection="column">
        <ReactEChartsCore
          onEvents={events}
          option={options}
          echarts={echarts}
          style={{ minWidth: '100%', flexGrow: 1 }}
          ref={eChartsRef}
          notMerge
        />
      </Box>
    </Stack>
  )
}

export default AssessmentRiskIndexBarChart
