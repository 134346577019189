import DateDisplay from '@app/src/components/DateDisplay'
import Assessment from '@app/src/types/assessment'
import { Response } from '@app/src/types/resourceExplorer'
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import AssessmentSectionComponent from '../../ResourceCollection/Collections/BaseAssessment/AssessmentSection'

interface BaselineAssessmentDetailedReviewProps {
  latestResponse: Response
  assessment: Assessment
}

const BaselineAssessmentDetailedReview = ({ latestResponse, assessment }: BaselineAssessmentDetailedReviewProps) => {
  const { formatMessage } = useIntl()

  return (
    <Paper elevation={0} sx={{ px: 3, py: 2, mt: 2, mr: 5 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">{formatMessage({ id: 'baseAssessment.providerPage.answers' })}</Typography>
        <Typography variant="caption" color="text.secondary">
          {formatMessage({ id: 'baseAssessment.providerPage.resultsAssessedOn' })}{' '}
          <DateDisplay value={assessment?.updatedAt ?? assessment.createdAt} />
        </Typography>
      </Box>
      {assessment.assessmentTemplate.sections.map((section, i) => (
        <AssessmentSectionComponent
          key={section.id}
          section={section}
          assessment={assessment}
          response={latestResponse}
        />
      ))}
    </Paper>
  )
}

export default BaselineAssessmentDetailedReview
