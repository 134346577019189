import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import ExternalVerificationStatusCell from '@app/src/components/Table/Cells/ExternalVerificationStatusCell'
import PeriodNameCell from '@app/src/components/Table/Cells/PeriodNameCell'
import RequestCell from '@app/src/components/Table/Cells/RequestCell'
import { useInquiryNavigator } from '@app/src/hooks/inquiryNavigator'
import { ExternalVerificationStatus, Inquiry } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'

interface ProviderInquiryRowProps {
  row: Inquiry
}

export const ProviderInquiryRow: React.FC<ProviderInquiryRowProps> = ({ row: inquiry }) => {
  const { handleInquiryClick, isCreateReportLoading } = useInquiryNavigator()
  const latestResponse = inquiry.request?.responses?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )[0]
  const verification = latestResponse?.verifications?.find(
    ver => ver.creatorOrganizationId === inquiry.creatorOrganizationId,
  )

  return (
    <TableRow>
      <ExternalVerificationStatusCell
        responseStatus={verification?.externalVerificationStatus ?? ExternalVerificationStatus.NotSet}
        draftStatus={latestResponse?.draftStatus}
      />
      <RequestCell
        title={inquiry.template?.title}
        imageUrl={inquiry.template?.image?.url}
        isLoading={isCreateReportLoading}
        onClick={() =>
          handleInquiryClick(
            inquiry.questionnaireTemplateId,
            inquiry.periodStartsAt,
            inquiry.periodEndsAt,
            inquiry.id,
            inquiry.request?.id,
          )
        }
      />
      <PeriodNameCell periodName={inquiry.periodName} />
      <CompanyCell company={inquiry.creatorOrganization} />
      <DateCell value={inquiry?.deadline} />
      <DateCell value={inquiry?.activatedAt} />
    </TableRow>
  )
}

export default ProviderInquiryRow
