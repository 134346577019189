import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCount } from '@app/src/api/fetchHooks'
import useAssessmentQuestionnaireLink from '@app/src/hooks/useAssessmentQuestionnaireLink'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { AssessmentTemplate, QuestionnaireTemplate } from '@app/src/types/resourceExplorer'

export const useAssessmentQuestionnaireInquiriesCount = (
  assessmentTemplate: AssessmentTemplate | undefined,
  enabled = true,
  providerId?: number,
  periodName?: string,
) => {
  const { assessmentQuestionnaireLinks, isLoadingAssessmentQuestionnaireLink } = useAssessmentQuestionnaireLink({
    assessmentTemplateId: assessmentTemplate?.id,
    enabled: Boolean(enabled && assessmentTemplate),
    includeAssessment: false,
    includeQuestionnaire: true,
  })
  const questionnaireTemplates = assessmentQuestionnaireLinks.map(
    x => x.questionnaireTemplate,
  ) as QuestionnaireTemplate[]
  const linkedQuestionnaireTemplateIds = questionnaireTemplates.map(x => x.id)

  const filters =
    providerId != null && periodName != null
      ? [
          {
            name: 'providerId',
            filters: [
              {
                operator: Operators.EqualTo,
                value: providerId,
              },
            ],
          },
          {
            name: 'periodName',
            filters: [
              {
                value: periodName,
                operator: Operators.EqualTo,
              },
            ],
          },
        ]
      : []

  const { count: inquiriesCount, isLoading: isLoadingInquiryCountEndpoint } = useFetchCount({
    key: FetchKey.InquiryCount,
    endpoint: endpoints.inquiryCount,
    payload: [
      {
        name: 'questionnaireTemplateId',
        filters: [
          {
            value: linkedQuestionnaireTemplateIds,
            operator: Operators.In,
          },
        ],
      },
      ...filters,
    ],
    options: { enabled: enabled && Boolean(linkedQuestionnaireTemplateIds.length), staleTime: 60000 },
  })

  return {
    inquiriesCount,
    isLoadingInquiriesCount: isLoadingAssessmentQuestionnaireLink || isLoadingInquiryCountEndpoint,
    questionnaireTemplates,
  }
}

export default useAssessmentQuestionnaireInquiriesCount
